import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AgentAd from './components/AgentAd/AgentAd';
import PaymentRoutes from './components/PaymentRoutes/PaymentRoutes';
import PrivateRoutes from './components/PrivateRoutes';
import PublicRoute from './components/PublicRoute';
import SEO from './components/SEO';
import AboutUs from './pages/AboutUs/AboutUs';
import AccountSettings from './pages/AccountSettings/AccountSettings';
import AddNewListing from './pages/AddNewListing/AddNewListing';
import AddonsPayment from './pages/AddonsPayment/AddonsPayment';
import Advertise from './pages/Advertise/Advertise';
import AdvertiseDeal from './pages/Advertise/AdvertiseDeal';
import AdvertiseFarm from './pages/Advertise/AdvertiseFarm';
import AdvertisePremier from './pages/Advertise/AdvertisePremier';
import AdvertiseReport from './pages/Advertise/AdvertiseReport';
import AgentFarmGeneral from './pages/AgentFarmGeneral';
import AgentFinder from './pages/AgentFinderPage/AgentFinder';
import AgentInvestorLeads from './pages/AgentInvestorLeads/AgentInvestorLeads';
import AgentPage from './pages/AgentPage/AgentsPage';
import AllocatedTerritories from './pages/AllocatedTerritories/AllocatedTerritories';
import BpoReport from './pages/BPOReport/BpoReport';
import BPOSub from './pages/BPOSub/BPOSub';
import BPOSub1 from './pages/BPOSub/BPOSub1';
import PremPay from './pages/BPOSub/PremPay';
import Blog from './pages/Blog/Blog';
import FinConsiderations from './pages/Blog/FinConsiderations/FinConsiderations';
import LifeStyle from './pages/Blog/Lifestyle/Lifestyle';
import Relocation from './pages/Blog/Relocation/Relocation';
import Upgrading from './pages/Blog/Upgrading/Upgrading';
import BpoAgents from './pages/BpoAgents/BpoAgents';
import Brochure from './pages/Brochure/Brochure';
import ChoosePackage from './pages/ChoosePackage/ChoosePackage';
import ClientDashboard from './pages/ClientDashboard/ClientDashboard';
import ClientOrderedReport from './pages/ClientOrderedReport/ClientOrderedReport';
import ClientProfile from './pages/ClientProfile/ClientProfile';
import CreateReport from './pages/CreateReport/CreateReport';
import CustomerReqRep from './pages/CustomerReqRep/CustomerReqRep';
import AnalyzerPropertySearch from './pages/DealAnalyzer/AnalyzerPropertySearch/AnalyzerPropertySearch';
import BuyAndRent from './pages/DealAnalyzer/BuyAndRent/BuyAndRent';
import DealAnalyzerMainMenu from './pages/DealAnalyzer/DealAnalyzerMainMenu/DealAnalyzerMainMenu';
import ClientLandingPage from './pages/DealAnalyzer/LandingPage/ClientLandingPage';
import PaymentConfirmation from './pages/DealAnalyzer/LandingPage/PaymentConfirmation/PaymentConfirmation';
import NewConstruction from './pages/DealAnalyzer/NewConstruction/NewConstruction';
import QuestionnaireBuyAndRent from './pages/DealAnalyzer/Questionnaires/QuestionnaireBuyAndSell.js/QuestionnaireBuyAndSell';
import QuestionnaireContainer from './pages/DealAnalyzer/Questionnaires/QuestionnaireContainer';
import QuestionnaireNewConstruction from './pages/DealAnalyzer/Questionnaires/QuestionnaireNewConstruction/QuestionnaireNewConstruction';
import QuestionnaireRemodelAndAddAddition from './pages/DealAnalyzer/Questionnaires/QuestionnaireRemodelAndAddAddition/QuestionnaireRemodelAndAddAddition';
import QuestionnaireRemodelDownToStuds from './pages/DealAnalyzer/Questionnaires/QuestionnaireRemodelDownToStuds/QuestionnaireRemodelDownToStuds';
import QuestionnaireRemodelDownToStudsAddAddition from './pages/DealAnalyzer/Questionnaires/QuestionnaireRemodelDownToStudsAddAddition/QuestionnaireRemodelDownToStudsAddAddition';
import QuestionnaireStraightAestheticRemodel from './pages/DealAnalyzer/Questionnaires/QuestionnaireStraightAestheticRemodel/QuestionnaireStraightAestheticRemodel';
import QuestionnairesMainMenu from './pages/DealAnalyzer/Questionnaires/QuestionnairesMainMenu/QuestionnairesMainMenu';
import RemodelDownToStuds from './pages/DealAnalyzer/RemodelDownToStuds/RemodelDownToStuds';
import RemodelDownToStudsAddAddition from './pages/DealAnalyzer/RemodelDownToStudsAddAddition/RemodelDownToStudsAddAddition';
import StraightAestheticRemodel from './pages/DealAnalyzer/StraightAestheticRemodel/StraightAestheticRemodel';
import StraightRemodelAndAdditionalSqft from './pages/DealAnalyzer/StraightRemodelAndAdditionalSqFt/StraightRemodelAndAdditionalSqft';
import ViewCompletedReports from './pages/DealAnalyzer/ViewDealAnalyzerReports/ViewCompletedReports';
import ViewStraightAestheticRemodelReport from './pages/DealAnalyzer/ViewDealAnalyzerReports/ViewStraightAestheticRemodelReport';
import ViewUncompletedReports from './pages/DealAnalyzer/ViewDealAnalyzerReports/ViewUncompletedReports';
import DigitalCardPreview from './pages/DigitalCardPreview/DigitalCardPreview';
import Disclaimer from './pages/Disclaimer/Disclaimer';
import Errorpage from './pages/Errorpage/Errorpage';
import Followups from './pages/Followups';
import ForgotPassword from './pages/ForgotPassword/ForgotPassword';
import GenerateQuotes from './pages/GenerateQuotes';
import HeaderImage from './pages/HeaderImage/HeaderImage';
import Homepager from './pages/Homepager';
import IndPayment from './pages/IndPayment';
import IndProfProfile from './pages/IndProfProfile';
import IndProfTemplate from './pages/IndProfTemplate';
import IndProfWebHomePage from './pages/IndProfWebHomePage';
import IndSubscriptionPayment from './pages/IndSubscriptionPayment';
import IndustryDashboard from './pages/IndustryDashboard';
import LeadMagnet from './pages/LeadMagnet/LeadMagnet';
import LeadsNotification from './pages/LeadsNotification/LeadsNotification';
import Description from './pages/ListingDetails/Description/Description';
import Images from './pages/ListingDetails/Images/Images';
import Profile from './pages/ListingDetails/Profile/Profile';
import PropertyInfo from './pages/ListingDetails/PropertyInfo/PropertyInfo';
import Tour from './pages/ListingDetails/Tour/Tour';
import Videos from './pages/ListingDetails/Videos/Videos';
import ListingsOverview from './pages/ListingsOverview/ListingsOverview';
import LiveLeads from './pages/LiveLeads/LiveLeads';
import PremierLeads from './pages/LiveLeads/Premier/PremierLeads';
import ManageListings from './pages/ManageListings/ManageListings';
import MyListing from './pages/MyListing';
import MyListings from './pages/MyListings';
import MyOrderingPage from './pages/MyOrderingPage/MyOrderingPage';
import MySubscriptions from './pages/MySubscriptions/MySubscriptions';
import MyTeam from './pages/MyTeam/MyTeam';
import NewDashboard from './pages/NewDashboard';
import PaymentDetails from './pages/PaymentDetails/PaymentDetails';
import PaymentSuccess from './pages/PaymentSuccess/PaymentSuccess';
import PremHomePage from './pages/PremHomePage/PremHomePage';
import PremierAgentsDashboard from './pages/PremierAgentsDashboard/PremierAgentsDashboard';
import Bio from './pages/PremierWebsite/Bio/Bio';
import Contact from './pages/PremierWebsite/Contact/Contact';
import Listings from './pages/PremierWebsite/Listings/Listings';
import { default as ProfileWeb } from './pages/PremierWebsite/Profile/Profile';
import WebsiteOverview from './pages/PremierWebsite/WebsiteOverview/WebsiteOverview';
import PreviewReport from './pages/PreviewReport/PreviewReport';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import ProfileEdit from './pages/ProfileEdit/ProfileEdit';
import ProfileOverview from './pages/ProfileOverview/ProfileOverview';
import ReportAgentDisclaimer from './pages/ReportAgentDisclaimer';
import ReportPay from './pages/ReportPay/ReportPay';
import RequestedQuotes from './pages/RequestedQuotes';
import BPOGuide from './pages/Resources/BPOGuide';
import BuyerChecklist from './pages/Resources/BuyerChecklist';
import BuyerFirstTime from './pages/Resources/BuyerFirstTime';
import BuyerScore from './pages/Resources/BuyerScore';
import Explore from './pages/Resources/Explore';
import HomeImprovement from './pages/Resources/HomeImprovement';
import SellerGuide from './pages/Resources/SellerGuide';
import SubscriptionHistory from './pages/SubscriptionHistory/SubscriptionHistory';
import SubscriptionOverview from './pages/SubscriptionOverview/SubscriptionOverview';
import TemplateScreen from './pages/TemplateScreen/TemplateScreen';
import ListingTemplate from './pages/Templates/ListingTemplate/ListingTemplate';
import Temp1 from './pages/Templates/Temp1/Temp1';
import TermsOfService from './pages/TermsOfService/TermsOfService';
import Territory from './pages/Territory/Territory';
import TrainingCenter from './pages/TrainingCenter';
import TrainingCenterDocs from './pages/TrainingCenterDocs';
import TrainingCenterItemLibrary from './pages/TrainingCenterItemLibrary';
import TrainingCenterZoom from './pages/TrainingCenterZoom';
import { theme } from './theme';

function App() {
	const role = useSelector((state) => state.auth.role);
	return (
		<>
			<ToastContainer position='top-right' autoClose={3000} hideProgressBar closeOnClick={true} />
			<SEO>
				<meta
					name='description'
					data-react-helmet='true'
					content="Don't Underlist. Don't Overpay. Get A BPO Report Today. Your Local Expert Agents Ready To Give You A BPO Report Now!"
				/>
				<title>BPO TECH</title>
			</SEO>
			<ThemeProvider theme={theme}>
				<Router>
					<Routes>
						{/* Payment routes goes here (require authentication) */}
						<Route element={<PaymentRoutes />}>
							<Route path='bpo-sub/*' element={<BPOSub />} />
							<Route path='bpo-sub1' element={<BPOSub1 />} />
							<Route path='prem-sub' element={<PremPay />} />
							<Route path='/report-pay'>
								<Route path=':agentId' element={<ReportPay />} />
							</Route>
							<Route path='/choose-sub'>
								<Route index path=':packType/:subType' element={<ChoosePackage />} />
							</Route>
							<Route path='/allocated-territories/:id' element={<AddonsPayment />} />
							<Route path='dealanalyzer-confirm-payment' element={<PaymentConfirmation />} />

							<Route path='update-farm' element={<AddonsPayment />} />
							<Route path='industry-prof-subscription' element={<IndSubscriptionPayment />} />
						</Route>

						{/* These routes do not require authentication and show navbar */}
						<Route element={<PublicRoute />}>
							<Route path='/' element={<Homepager />} />
							<Route path='/agent-finder'>
								<Route path='' element={<AgentFinder />} />
								<Route path=':id' element={<AgentPage />} />
							</Route>

							{/* Industry professionals */}

							{/** Main landing page analytics component below */}
							<Route path='/analytics' element={<ClientLandingPage />} />

							<Route path='/advertise'>
								<Route path='' element={<Advertise />} />
								<Route path='advertise-premier' element={<AdvertisePremier />} />
								<Route path='advertise-deal' element={<AdvertiseDeal />} />
								<Route path='advertise-report' element={<AdvertiseReport />} />
								<Route path='advertise-farm' element={<AdvertiseFarm />} />
							</Route>
							<Route path='order-bpo-report'>
								{/* <Route path='' element={<BpoReportPage />} /> */}
								<Route path='' element={<BpoAgents />} />
								<Route path=':agentId' element={<BpoReport />} />
							</Route>
							<Route path='/buyer-checklist' element={<BuyerChecklist />} />
							<Route path='/buyer-firsttimer' element={<BuyerFirstTime />} />
							<Route path='/buyer-score' element={<BuyerScore />} />
							<Route path='/bpo-guide' element={<BPOGuide />} />
							<Route path='/sellers-guide' element={<SellerGuide />} />
							<Route path='/home-improvement' element={<HomeImprovement />} />
							<Route path='/explore' element={<Explore />} />
							<Route path='terms-of-service' element={<TermsOfService />} />
							<Route path='privacy-policy' element={<PrivacyPolicy />} />
							<Route path='disclaimer' element={<Disclaimer />} />
							<Route path='/about' element={<AboutUs />} />
							<Route path='/report-agent-disclaimers' element={<ReportAgentDisclaimer />} />
						</Route>
						<Route path='/reset_password'>
							<Route path='' element={<ForgotPassword />} />
							<Route path=':id/:token' element={<ForgotPassword />} />
						</Route>
						<Route path='/listingtemplate'>
							<Route path=':agentId/:listingId' element={<ListingTemplate />} />
						</Route>

						{/* Routes that require authorization go here */}
						<Route element={<PrivateRoutes />}>
							<Route path='/payment_success' element={<PaymentSuccess />} />
							<Route path='/profile'>
								<Route path='' element={role === 'realty_agent' || role === 'agent' ? <ProfileOverview /> : <ClientProfile />} />
								<Route path='edit' element={<ProfileEdit />} />
							</Route>
							<Route path='/my-subscriptions' element={<SubscriptionOverview />}>
								<Route path='' index element={<MySubscriptions />} />
								<Route path='history' element={<SubscriptionHistory />} />
								<Route path='payment-details' element={<PaymentDetails />} />
								<Route path='account-settings' element={<AccountSettings />} />
							</Route>
							<Route path='live-leads'>
								<Route path='' index element={<LiveLeads />} />
								<Route path='premier' index element={<PremierLeads />} />
							</Route>

							<>
								{/* property search and deal analyzer */}
								<Route path='analyze-deal'>
									<Route path='' element={<AnalyzerPropertySearch />} />
									<Route path='menu'>
										<Route path='' element={<DealAnalyzerMainMenu />} />
										<Route path='straight-aesthetic-remodel' element={<StraightAestheticRemodel />} />
										<Route path='straight-remodel-additional-sqft' element={<StraightRemodelAndAdditionalSqft />} />
										<Route path='remodel-down-to-studs' element={<RemodelDownToStuds />} />
										<Route path='remodel-down-to-studs-add-addition' element={<RemodelDownToStudsAddAddition />} />
										<Route path='buy-and-rent' element={<BuyAndRent />} />
										<Route path='new-construction' element={<NewConstruction />} />
									</Route>
								</Route>
								<Route path='analyzer-questionnaire' element={<QuestionnaireContainer />}>
									<Route path='' element={<AnalyzerPropertySearch />} />
									<Route path='menu'>
										<Route path='' element={<QuestionnairesMainMenu />} />
										<Route path='straight-aesthetic-remodel' element={<QuestionnaireStraightAestheticRemodel />} />
										<Route path='remodel-and-add-addition' element={<QuestionnaireRemodelAndAddAddition />} />
										<Route path='remodel-down-to-studs' element={<QuestionnaireRemodelDownToStuds />} />
										<Route path='remodel-down-to-studs-add-addition' element={<QuestionnaireRemodelDownToStudsAddAddition />} />
										<Route path='new-construction' element={<QuestionnaireNewConstruction />} />
										<Route path='buy-and-rent' element={<QuestionnaireBuyAndRent />} />
									</Route>
								</Route>
								<Route path='dealanalyzer-reports'>
									<Route path='completed-reports' element={<ViewCompletedReports />} />
									<Route path='uncompleted-reports' element={<ViewUncompletedReports />} />
									<Route path='straight-aesthetic-remodel' element={<ViewStraightAestheticRemodelReport type={1} />} />
									<Route path='straight-remodel-add-addition' element={<ViewStraightAestheticRemodelReport type={2} />} />
									<Route path='remodel-down-to-studs' element={<ViewStraightAestheticRemodelReport type={3} />} />
									<Route path='remodel-down-add-addition' element={<ViewStraightAestheticRemodelReport type={4} />} />
									<Route path='new-construction' element={<ViewStraightAestheticRemodelReport type={5} />} />
									<Route path='buy-and-rent' element={<ViewStraightAestheticRemodelReport type={6} />} />
								</Route>
							</>
							<>
								<Route path='/agent-dashboard' element={<PremierAgentsDashboard />} />
								<Route path='/dashboard' element={<NewDashboard />} />

								<Route path='/allocated-territories' element={<AllocatedTerritories />} />
								<Route path='available-territories' element={<AgentFarmGeneral />} />
								<Route path='search-territories' element={<Territory isSearch={true} />} />
								<Route path='/create-bpo-report/:id' element={<CreateReport />} />
								<Route path='/preview-report/:id' element={<PreviewReport />} />
								<Route path='/agent-ordering-page' element={<MyOrderingPage />} />
								<Route path='customer-requested-report' element={<CustomerReqRep />} />
								<Route path='completed-reports' element={<CustomerReqRep status='Complete' />} />
								<Route path='saved-reports' element={<CustomerReqRep status='Pending' />} />
								<Route path='leads-notification' element={<LeadsNotification />} />
								<Route path='investor-leads' element={<AgentInvestorLeads />} />
								<Route path='tech-training-center'>
									<Route path='' element={<TrainingCenter tech={true} />} />
									<Route path='video' element={<TrainingCenterItemLibrary name={'Video'} />} />
									<Route path='zoom' element={<TrainingCenterZoom />} />
									<Route path='document' element={<TrainingCenterDocs />} />
								</Route>
								<Route path='homes-training-center'>
									<Route path='' element={<TrainingCenter />} tech={false} />
									<Route path='video' element={<TrainingCenterItemLibrary name={'Video'} />} />
									<Route path='zoom' element={<TrainingCenterZoom />} />
									<Route path='document' element={<TrainingCenterDocs />} />
								</Route>
							</>

							{/* Routes for industry professionals */}
							<>
								<Route path='industry-dashboard' element={<IndustryDashboard />} />
								<Route path='requested-quotes'>
									<Route path='' element={<RequestedQuotes />} />
									<Route path=':id' element={<GenerateQuotes />} />
								</Route>
								<Route path='new-quote' element={<GenerateQuotes />} />
								<Route path='follow-ups' element={<Followups />} />
								<Route path='ind_prof' element={<IndProfProfile />} />
								<Route path='my-listing'>
									<Route path='all' element={<MyListings />} />
									<Route path='selected' element={<MyListing />} />
								</Route>
								<Route path='ind-payment' element={<IndPayment />} />
								<Route path='industry-website'>
									<Route path='' element={<IndProfWebHomePage />} />
								</Route>
							</>

							{/* Routes for client */}
							<>
								<Route path='/client-dashboard' element={<ClientDashboard />} />
								<Route path='/my-team' element={<MyTeam />} />
								<Route path='/client-ordered-report' element={<ClientOrderedReport />} />
							</>
							{/* Routes for premier agent */}
							{/* <Route path='/premier-agents' element={<PremierAgentsDashboard />} /> */}
							<Route path='premier-agent-website'>
								<Route path='' element={<PremHomePage />}></Route>
								<Route path='view-listings'>
									<Route path='' element={<ListingsOverview />} />
									<Route path=':id' element={<ManageListings />}>
										<Route index path='' element={<PropertyInfo />} />
										<Route path='description' element={<Description />} />
										<Route path='images' element={<Images />} />
										<Route path='videos' element={<Videos />} />
										<Route path='3d-tour' element={<Tour />} />
										<Route path='profile' element={<Profile />} />
										<Route path='brochure' element={<Brochure />} />
									</Route>
									<Route path='add' element={<AddNewListing />} />
								</Route>
								<Route path='website'>
									<Route path='' element={<WebsiteOverview isPrem={true} />}>
										<Route path='' index element={<ProfileWeb />} />
										<Route path='bio' element={<Bio />} />
										<Route path='active-listings' element={<Listings isActive={true} />} />
										<Route path='sold-listings' element={<Listings />} />
										<Route path='testimonials' element={<Contact />} />
										<Route path='image' element={<HeaderImage />} />
									</Route>
									<Route path='template' element={<TemplateScreen />}></Route>
								</Route>

								<Route path='digital-card' element={<WebsiteOverview />}>
									<Route path='' index element={<ProfileWeb />} />
									<Route path='bio' element={<Bio />} />
									<Route path='active-listings' element={<Listings isActive={true} />} />
									<Route path='sold-listings' element={<Listings />} />
									<Route path='testimonials' element={<Contact />} />
									<Route path='image' element={<HeaderImage />} />
								</Route>
							</Route>
						</Route>

						{/* routes for templates */}
						<Route exact path='/agent/:id'>
							<Route path='' element={<Temp1 />} />
							{/* <Route path='/agent/:id/area-search'>
								<Route element={<AgentNavBar />}>
									<Route path='' element={<AreaSearch />} />
									<Route path=':listingId' element={<AreaListing />} />
								</Route>
							</Route> */}
						</Route>
						<Route path='/agent/:id/blogs'>
							<Route path='' element={<Blog />} />
							<Route path='/agent/:id/blogs' element={<AgentAd />}>
								<Route path='/agent/:id/blogs/buyer-score' element={<BuyerScore />} />
								<Route path='/agent/:id/blogs/buyer-firsttimer' element={<BuyerFirstTime />} />
								<Route path='/agent/:id/blogs/buyer-checklist' element={<BuyerChecklist />} />
								<Route path='/agent/:id/blogs/bpo-guide' element={<BPOGuide />} />
								<Route path='/agent/:id/blogs/sellers-guide' element={<SellerGuide />} />
								<Route path='/agent/:id/blogs/home-improvement' element={<HomeImprovement />} />
								<Route path='/agent/:id/blogs/explore' element={<Explore />} />
								<Route path='/agent/:id/blogs/relocation' element={<Relocation />} />
								<Route path='/agent/:id/blogs/upgrading-or-downsizing' element={<Upgrading />} />
								<Route path='/agent/:id/blogs/financial-considerations' element={<FinConsiderations />} />
								<Route path='/agent/:id/blogs/lifestyle' element={<LifeStyle />} />
							</Route>
						</Route>

						<Route path='/view-listing/:agentId/:listingId' element={<ListingTemplate />} />
						<Route path='digital-premier-agent-preview/:id' element={<DigitalCardPreview />} />
						<Route path='industry-professional-website/:id' element={<IndProfTemplate />} />
						{/* routes for lead magnets */}
						<>
							<Route path='/lead-magnet' element={<LeadMagnet />} />
						</>

						<Route path='*' element={<Errorpage />} />
					</Routes>
				</Router>
			</ThemeProvider>
		</>
	);
}

export default App;
