import React from 'react'
import { useTheme } from '@mui/material/styles'
import { Avatar, Box, Divider, Typography} from '@mui/material'
import {generateColorFromString} from '../../utils/generateColorFromString'

const useStyles = (_theme)=>{
    return{
        root:{

        },
        heading:{
          padding:"0rem 0rem 0.625rem 0rem"
        },
        headingText:{
          display:"flex",
          justifyContent:"space-between",
          alignItems:"center"
        },
        name:{
          display:"flex",
          alignItems:"center",
          marginBottom:"1.2rem"
        },
        avatar:{
          width:"12%"
        },
        personalDetails:{
          width:"88%",
          display:"flex"
        },
        set1:{
          width:"70%",
        },
        set2:{
          width:"30%"
        },
        divider:{
          margin:"10px 0",
          backgroundColor:"#c4c4c4"
        },
    }
}

function ProformaLetterhead({agent, dealType, address,color,noImage,print}) {
    const theme = useTheme()
    const styles = useStyles(theme)
  return (
    <>
        <Box sx={styles.heading}>
        <Box sx={styles.headingText}>
          <Typography variant="proformaAddress">
            DealType: {dealType}
          </Typography>
          <Typography variant="proformaReportHeader">
            Pro-Forma Report
          </Typography>
          <Typography variant="proformaAddress" color={"primary"}>
            {address}
          </Typography>
        </Box>
        <Divider sx={styles.divider} middle />
        <Box sx={styles.name}>
          {!noImage && <Box sx={styles.avatar}>
            {agent?.profile_image && (
              <Avatar
                sx={{ 
                  height: "4.25rem", 
                  width: "4.25rem",
                  display:"flex",
                  mr:2,
                  "print-color-adjust": "exact",
                  "-webkit-print-color-adjust": "exact",
                }}
                src={agent?.profile_image}

              />
            )}
            {!agent?.profile_image && (
              <Avatar
                sx={{
                  bgcolor: color || `${generateColorFromString(agent?.first_name + agent?.last_name)}`,
                  height: "4.25rem",
                  width: "4.25rem",
                  "print-color-adjust": "exact",
                  "-webkit-print-color-adjust": "exact",
                }}

              >
                <Typography variant="h4" sx={{py:1}}>
                  {agent?.first_name?.[0] + agent?.last_name[0]}
                </Typography>
              </Avatar>
            )}
          </Box>}
          <Box sx={styles.personalDetails}>
            <Box sx={styles.set1}>
              <Box>
                <Typography variant="h4" sx={{py:1}}>
                  Agent Name: {agent?.first_name + " " + agent?.last_name}
                </Typography>
              </Box>
              <Box>
                <Typography variant="h4" sx={{py:1}}>
                  Email: {agent?.email}
                </Typography>
              </Box>
            </Box>
            <Box sx={styles.set2}>
              <Box>
                <Typography variant="h4" sx={{py:1}}>
                 
                 Agent Company: {agent?.company}
                </Typography>{" "}
              </Box>
              <Box>
                <Typography variant="h4" sx={{py:1}}>
                 License: {agent?.license|| "N/A"}
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box></Box>
        </Box>
      </Box>
    </>
  )
}

export default ProformaLetterhead
