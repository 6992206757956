import { Favorite, KingBed, Shower, SquareFoot } from "@mui/icons-material";
import {
  Box,
  Card,
  CardContent,
  Chip,
  IconButton,
  Typography,
  styled,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import React, { useState } from "react";
import base from "../../apis";
import { toast } from "react-toastify";
// import { addToFavorite, removeFavorite } from '../actions';

const StyledCard = styled(Card)(({ theme, border }) => ({
  boxShadow: "0 1px 10px rgba(0,0,0, 12%)",
  borderRadius: "10px",
  border: border ? `1px solid ${theme.secondaryColor}` : `1px solid #fff`,
  transition: "all .2s ease",
  "&:hover": {
    border: `1px solid ${theme.secondaryColor}`,
    cursor: "pointer",
    "& #lb": {
      background: theme.palette.primary.main,
      color: "#000",
      fontWeight: 500,
    },
  },
}));

const ImageBox = styled(Box)(({ theme, image }) => ({
  height: "12rem",
  backgroundImage: `url(${image})`,
  backgroundPosition: "center",
  backgroundSize: "cover",
  padding: "1.5rem",
}));

const ListingCard = (props) => {
  const {
    price,
    image,
    bed,
    bath,
    area,
    address,
    mlsId,
    border,
    listDate,
    setData,
    id,
  } = props;

  const getDaysOnMarket = (listDate) => {
    if (listDate) {
      const diff =
        new Date(Date.now()).getTime() - new Date(listDate).getTime();
      const dy = Math.round(diff / (1000 * 3600 * 24));
      if (dy === 0 || dy === 1) {
        return "New on BPO Homes";
      } else {
        return `${dy} days on BPO Homes`;
      }
    } else {
      return "0 days";
    }
  };

  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const removeFromFavs = async (e, mlsId, id) => {
    e.stopPropagation();
    setIsRemoveLoading(true);
    try {
      await base.delete(`/favorite/properties/${mlsId}/`);
      setData(id);
    } catch (error) {
      toast.warn("Could not remove from favorites");
    } finally {
      setIsRemoveLoading(false);
    }
  };

  return (
    <>
      <StyledCard
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_BPOHOMESREALTY_URL}listing/${mlsId}/details`
          )
        }
        border={border}
      >
        <ImageBox image={image}>
          {/* <Chip component={'span'} id='lb' sx={{bgcolor: 'paper.main', boxShadow: '0 1px 5px rgba(0,0,0, 20%)', transition: 'all .2s ease'}} size='small' label={`${status}`} /> */}
          <Chip
            component={"span"}
            id="lb"
            sx={{
              marginLeft: "5px",
              bgcolor: "primary.main",
              boxShadow: "0 1px 5px rgba(0,0,0, 20%)",
              transition: "all .2s ease",
            }}
            size="small"
            label={getDaysOnMarket(listDate)}
          />
        </ImageBox>
        <CardContent sx={{ padding: "1rem 2rem" }}>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: 600, fontSize: "1.2rem" }}
            >
              ${price}
            </Typography>
            <IconButton
              disableTouchRipple
              onClick={(e) => removeFromFavs(e, mlsId, id)}
            >
              {isRemoveLoading ? (
                <CircularProgress size="1.33rem" color="secondary" />
              ) : (
                <Favorite color="secondary" fontSize="small" />
              )}
            </IconButton>
          </Box>
          <Box display={"flex"} gap={".8rem"}>
            <Box sx={{ display: "flex", gap: ".2rem" }}>
              <Tooltip title="Bedroom" arrow>
                <KingBed sx={{ fontSize: "1.4rem" }} color="secondary" />
              </Tooltip>
              <Typography variant="body2" color={"textPrimary"}>
                {bed}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: ".2rem" }}>
              <Tooltip title="Bathroom" arrow>
                <Shower sx={{ fontSize: "1.4rem" }} color="secondary" />
              </Tooltip>
              <Typography variant="body2" color={"textPrimary"}>
                {bath}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", gap: "0rem" }}>
              <Tooltip title="Square Ft" arrow>
                <SquareFoot sx={{ fontSize: "1.4rem" }} color="secondary" />
              </Tooltip>
              <Typography variant="body2" color={"textPrimary"}>
                {area} Sqft
              </Typography>
            </Box>
          </Box>
          <Typography
            mt={1}
            sx={{ fontSize: ".9rem" }}
            noWrap
            color={"textSecondary"}
          >
            {address}
          </Typography>
        </CardContent>
      </StyledCard>
    </>
  );
};

export default ListingCard;
