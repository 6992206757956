import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Modal from '../Modal/Modal';
import CloseIcon from '@mui/icons-material/Close';
import './navbar.scss';
import AuthContent from '../AuthContent/AuthContent';
import UserMenuOptions from '../UserMenuOptions/UserMenuOptions';
import { ReactComponent as Bars } from '../../assets/bars.svg';
import { connect, useDispatch, useSelector } from 'react-redux';
import { toggleAuthModal, switchToSignin, switchToSignup, signUpClient, GetToken, signUpAgent, signUpIndustryProf, GetDetails } from '../../actions';
import Logo from '../../images/bpoVerticalLogo.webp';
import { toast } from 'react-toastify';
import Spinner from '../Spinner/Spinner';
import { makeStyles } from '@mui/styles';
import { LoadingButton } from '@mui/lab';
import { Box, Stack, Typography } from '@mui/material';
import base from '../../apis';
import EMAIL from '../../assets/email_verify.png';

const useStyles = makeStyles((theme) => ({
	card: {
		width: '60rem',
		padding: '2.5rem 3rem',
	},
	navItem: {
		cursor: 'pointer',
		margin: '7px 0',
		fontSize: '.95rem',
		lineHeight: 1.4,
		'&:hover': {
			color: theme.secondaryColor,
		},
	},
	activeLink: {
		background: theme.secondaryColor,
		color: '#fff',
		padding: '.6rem 1.2rem',
		borderRadius: '50px',
		marginLeft: '-1rem',
	},
}));

const indProfLink = `https://${process.env.REACT_APP_URL.includes('dev') ? 'testing.' : ''}industryprofessionals.com`;

function Navbar(props) {
	const classes = useStyles();
	const { pathname } = useLocation();
	const isModalOpen = useSelector((state) => state.modals.authModal.isOpen);
	const [menuState, setMenuState] = useState(false);
	const location = useLocation();
	const isVerified = JSON.parse(localStorage.getItem('details'))?.is_email_verified;
	const [accountDetails, setAccountDetails] = useState({
		email: '',
		password: '',
		passwordConfirmation: '',
		isProfessional: false,
		professionType: null,
		first_name: '',
		last_name: '',
		zip: '',
		phone: '',
		subType: '',
		marketer_id: '',
		prof_role: 'agent',
	});
	const [isLoading, setIsLoading] = useState(false);

	React.useLayoutEffect(() => {
		const navbar = document.getElementsByClassName('navbar')[0];
		document.documentElement.style.setProperty('--navheight', navbar.clientHeight + 'px');
	}, []);

	const { first_name, last_name, loading, id, email, token } = useSelector((state) => state.auth);

	const toggleMenu = (menuState) => {
		setMenuState(!menuState);
	};

	const isSignIn = useSelector((state) => state.modals.authModal.isSignin);

	const dispatch = useDispatch();

	const openLogin = () => {
		dispatch(toggleAuthModal());
		dispatch(switchToSignin());
	};
	const openNewAccount = () => {
		dispatch(toggleAuthModal());
		dispatch(switchToSignup());
	};

	const navigate = useNavigate();

	const onSubmit = () => {
		if (isSignIn) {
			if (accountDetails.email === '' || accountDetails.password === '') {
				toast.warn('Please fill in all fields');
				return;
			}
			const { email, password } = accountDetails;
			dispatch({ type: 'START_LOADING' });
			dispatch(GetToken({ email, password }, navigate, location?.state));
		} else {
			if (accountDetails.email === '' || accountDetails.password === '') {
				toast.warn('Please fill in email and password fields');
				return;
			}
			if (accountDetails.password !== accountDetails.passwordConfirmation) {
				toast.warn('Passwords do not match');
				return;
			}

			if (!accountDetails.isProfessional) {
				dispatch(signUpClient(accountDetails, navigate, location?.state));
			} else {
				if (accountDetails.prof_role === 'agent') {
					dispatch(signUpAgent(accountDetails, navigate, location?.state));
				} else {
					dispatch(signUpIndustryProf(accountDetails, navigate, location?.state));
				}
			}
		}
	};

	useEffect(() => {
		let loginTimeout;
		if (location?.state?.from) {
			loginTimeout = setTimeout(() => {
				dispatch(toggleAuthModal());
			}, 1500);
		}
		return () => {
			loginTimeout && clearTimeout(loginTimeout);
		};
		// NOTE: Run effect once on component mount, please
		// recheck dependencies if effect is updated.
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Auto focus Email token
	const autoFocusHandler = (event) => {
		const element = event.target;
		const nextSibling = element.nextElementSibling;
		nextSibling ? nextSibling.focus() : element.blur();
	};

	// Verify Email Refs
	const ref1 = useRef();
	const ref2 = useRef();
	const ref3 = useRef();
	const ref4 = useRef();
	const ref5 = useRef();
	const ref6 = useRef();

	const resendCode = async () => {
		try {
			toast.success('Sending new code. Please wait...');
			const { data: res } = await base.post('core/resend-otp/', { user_id: id });
			if (res?.status === 'success') {
				toast.success('New activation code has been sent to your email');
			}
		} catch (error) {
			toast.warn('Sorry, could not send code');
			return;
		}
	};

	const verifyAccount = async () => {
		const code = ref1.current?.value + ref2.current?.value + ref3.current?.value + ref4.current?.value + ref5.current?.value + ref6.current?.value;
		if (code === '') {
			toast.warn('Invalid. Please provide activation code');
			return;
		}
		if (code?.length < 6) {
			toast.warn('Invalid activation code. Please check your code');
			return;
		}
		try {
			setIsLoading(true);
			const { data: res } = await base.post('core/verify-email/', { user_id: id, otp: +code });
			if (res?.status === 'success') {
				props.GetDetails();
				toast.success(res?.message);
				dispatch(toggleAuthModal());
			}
		} catch (error) {
			if (error?.response?.data) {
				toast.warn(error?.response?.data?.detail);
			} else {
				toast.warn('Sorry, email verification failed. Please try again');
			}
			return;
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<>
			{loading && <Spinner />}

			{/* Authenitication Modal */}
			{id && isVerified ? null : (
				<Modal open={isModalOpen} onClose={() => dispatch(toggleAuthModal())} style={{ minWidth: '24rem' }}>
					{id && !isVerified ? (
						<Box padding={'2rem 1.5rem'} textAlign={'center'}>
							<img src={EMAIL} width={'22%'} alt='email' />
							<Typography variant='h5' fontWeight={500} mt={1} mb={1}>
								Verify Email
							</Typography>
							<Typography variant='body1' mb={2}>
								To start using bpohomes.com, we have sent a verification token to your email (<b>{email}</b>)
							</Typography>
							<Stack direction={'row'} gap={'.6rem'} my={3} width={'100%'} mx={'auto'}>
								{new Array(6).fill(0).map((el, i) => {
									return (
										<input
											key={i}
											autoFocus={i === 0 ? true : false}
											ref={i === 0 ? ref1 : i === 1 ? ref2 : i === 2 ? ref3 : i === 3 ? ref4 : i === 4 ? ref5 : ref6}
											type='text'
											maxLength={1}
											onChange={(e) => autoFocusHandler(e)}
											style={{
												textAlign: 'center',
												height: '4rem',
												fontSize: '2rem',
												width: '100%',
												borderRadius: '3px',
												border: '1px solid lightgrey',
											}}
										/>
									);
								})}
							</Stack>
							<LoadingButton
								loading={isLoading}
								variant='contained'
								disableElevation
								color='secondary'
								fullWidth
								size='large'
								sx={{ textTransform: 'none', marginBottom: '1rem' }}
								onClick={verifyAccount}
							>
								Verify Account
							</LoadingButton>
							<Typography
								variant='body2'
								onClick={resendCode}
								color={'secondary'}
								fontWeight={400}
								sx={{ cursor: 'pointer', textDecoration: 'underline' }}
							>
								Didn't receive email. Resend
							</Typography>
						</Box>
					) : (
						<AuthContent
							closeModal={() => dispatch(toggleAuthModal())}
							accountDetails={accountDetails}
							onSubmit={onSubmit}
							setAccountDetails={(name, value) =>
								setAccountDetails({
									...accountDetails,
									[name]: value,
								})
							}
							redirect={location?.state}
						/>
					)}
				</Modal>
			)}

			<nav className='navbar'>
				<NavLink className='mob_nav_element' to='' style={{ marginLeft: '5px' }}>
					{menuState ? <CloseIcon onClick={() => toggleMenu(menuState)} /> : <Bars onClick={() => toggleMenu(menuState)} />}
				</NavLink>

				{pathname !== '/' ? (
					<NavLink to='/'>
						<img src={Logo} alt='logo' className='logo' />
					</NavLink>
				) : (
					<img src={Logo} alt='logo' className='logo' style={{ boxSizing: 'content-box', height: '38px' }} />
				)}
				<ul className='list'>
					<NavLink to='/order-bpo-report' className={({ isActive }) => (isActive ? 'list-active link_font' : 'link_font')}>
						Order BPO Report
					</NavLink>
					<a href={indProfLink} target='_blank' rel='noreferrer' className={({ isActive }) => (isActive ? 'list-active' : '')}>
						<li className='link_font'>Industry Professionals</li>
					</a>
					{/* <NavLink to='/analyze-deal' className={({ isActive }) => (isActive ? 'list-active' : '')}>
						<li className='link_font'>Deal Analyzer</li>
					</NavLink> */}
					<NavLink to='/agent-finder' className={({ isActive }) => (isActive ? 'list-active' : '')}>
						<li className='link_font'>Agent Finder</li>
					</NavLink>
					<NavLink to='/advertise' className={({ isActive }) => (isActive ? 'list-active link_font' : 'link_font')}>
						Advertise
					</NavLink>
				</ul>

				{!token?.access ? (
					<>
						<ul className='list'>
							<li className='link_font' onClick={openLogin}>
								Login
							</li>
							<li className={`${classes.activeLink} ${'link_font'}`} onClick={openNewAccount}>
								Sign up
							</li>
						</ul>
						<p onClick={openLogin} className='mob_nav_element' style={{ marginRight: '10px' }}>
							Sign In
						</p>
					</>
				) : (
					<ul className='list logged' style={{ display: 'block' }}>
						<UserMenuOptions name={`${first_name} ${last_name}`} />
					</ul>
				)}
			</nav>
			<div className={menuState ? 'dropdownnav ' : 'navtoggle'}>
				<div className='dropdownnav_list'>
					<div className='nav_links_wrapper'>
						<NavLink
							to='/order-bpo-report'
							className={({ isActive }) => (isActive ? 'list-active nav_link' : 'nav_link')}
							onClick={() => {
								setMenuState(false);
							}}
						>
							<p>Order BPO Report</p>
						</NavLink>
						<NavLink
							to='/agent-finder'
							className={({ isActive }) => (isActive ? 'list-active nav_link' : 'nav_link')}
							onClick={() => {
								setMenuState(false);
							}}
						>
							<p>Agent Finder</p>
						</NavLink>
						<a
							href={indProfLink}
							target='_blank'
							rel='noreferrer'
							className='nav_link'
							onClick={() => {
								setMenuState(false);
							}}
						>
							<p>Industry Professionals</p>
						</a>
						<NavLink
							to='/advertise'
							className={({ isActive }) => (isActive ? 'list-active nav_link' : 'nav_link')}
							onClick={() => {
								setMenuState(false);
							}}
						>
							<p>Advertise</p>
						</NavLink>
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => state;
export default connect(mapStateToProps, { GetDetails })(Navbar);
