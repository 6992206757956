const URL = process.env.REACT_APP_URL;

export const timeData = [
	'7:00 AM',
	'7:30 AM',
	'8:00 AM',
	'8:30 AM',
	'9:00 AM',
	'9:30 AM',
	'10:00 AM',
	'10:30 AM',
	'11:00 AM',
	'11:30 AM',
	'12:00 PM',
	'12:30 PM',
	'1:00 PM',
	'1:30 PM',
	'2:00 PM',
	'2:30 PM',
	'3:00 PM',
	'3:30 PM',
	'4:00 PM',
	'4:30 PM',
	'5:00 PM',
	'5:30 PM',
	'6:00 PM',
];

const resources = {
	headerTitle: 'Resources',
	route: '',
	option: [
		{
			text: "Buyer's checklist",
			route: '',
		},
		{
			text: 'Resource center',
			route: '',
		},
		{
			text: 'First timer',
			route: '',
		},
		{
			text: 'Housing market',
			route: '',
		},
	],
};

export const choices = [
	{ value: 'today', label: 'Today' },
	{ value: 'yesterday', label: 'Yesterday' },
	{ value: 'this_week', label: 'This Week' },
	{ value: 'last_week', label: 'Last Week' },
	{ value: 'this_month', label: 'This Month' },
	{ value: 'last_month', label: 'Last Month' },
	{ value: 'this_quarter', label: 'This Quarter' },
	{ value: 'last_quarter', label: 'Last Quarter' },
	{ value: 'this_year', label: 'This Year' },
	{ value: 'last_year', label: 'Last Year' },
];

export const propertyList = {
	roof: 'Tile',
	cooling: null,
	style: 'Traditional',
	area: 1043,
	bathsFull: 5,
	bathsHalf: 6,
	stories: 3,
	fireplaces: 1,
	flooring: null,
	heating: 'Radiant Heat',
	bathrooms: null,
	foundation: 'Slab',
	laundryFeatures: 'Area,Electric Dryer Hookup,Individual Room,Washer Hookup',
	occupantName: null,
	ownerName: null,
	lotDescription: 'Private Backyard',
	pool: 'Association,Private,In Ground',
	subType: null,
	bedrooms: 2,
	interiorFeatures: 'Drapes/Curtains/Window Cover, Fire/Smoke Alarm, High Ceiling, Island Kitchen',
	lotSize: '127X146',
	areaSource: 'Floorplans',
	maintenanceExpense: null,
	additionalRooms: 'Recreation,Master Bedroom,See Remarks',
	exteriorFeatures: 'Back Yard Fenced, Covered Patio/Deck, Patio/Deck',
	water: null,
	view: 'Golf Course',
	lotSizeArea: null,
	subdivision: 'Waterstone Springs',
	construction: 'In Kitchen,Stackable,Washer Included',
	lotSizeAreaUnits: null,
	type: 'RES',
	garageSpaces: 8.74556425245327,
	bathsThreeQuarter: null,
	accessibility: 'Manned Gate',
	acres: null,
	occupantType: null,
	subTypeText: null,
	yearBuilt: 1998,
};

export const quesLists = [
	[
		{
			title: 'When do you plan on selling?',
			subTitle: 'Please tell us your ideal timeline for closing the sale.',
			options: ['ASAP', '6 - 12 months', '1 - 3 months', '12+ months', '3 - 6 months'],
			formID: 'sellingPlan',
		},
	],
	[
		{
			title: 'What is the current condition of your home?',
			subTitle: 'Please select an answer',
			options: ['Needs Significant work', 'Needs a little work', 'Tear down'],
			formID: 'currentCondition',
		},
	],
	[
		{
			title: 'Why are you selling your home?',
			subTitle: 'Please select an answer',
			options: ['Upgrading my home', 'Downsizing my home', 'Selling secondary home', 'Retiring', 'Relocating', 'Other'],
			formID: 'whySell',
		},
	],
	[
		{
			title: 'Are you currently working with a real estate agent? ',
			subTitle: 'Please select an answer',
			options: ['Yes', 'No'],
			formID: 'haveAnAgent',
		},
	],
	[
		{
			title: 'Are you also looking to buy a home?',
			subTitle: 'Please select an answer',
			options: ['Yes', 'No'],
			formID: 'wantToBuyAHome',
		},
	],
	[
		{
			title: 'Have you made any improvements to your home?',
			subTitle: 'Please provide any improvement made',
			options: 'improvementForm',
		},
	],
	[
		{
			title: 'We’ll like to know you more:',
			subTitle: '',
			options: 'personalDetailsForm',
		},
	],
];

const buyingOptions = {
	headerTitle: 'Buying Options',
	route: '',
	option: [
		{
			text: 'Buy with Homelytica',
			route: '',
		},
		{
			text: 'Buy with a broker',
			route: '',
		},
	],
};
/**
 * dropdown that appears when a user hovers over buy
 */
const buyList = [
	{
		headerTitle: 'Home For Sale',
		route: '/buy',
		option: [
			{
				text: 'Home for sale',
				route: '',
			},
			{
				text: 'Foreclosure',
				route: '',
			},
			{
				text: 'For sale by owner',
				route: '',
			},
			{
				text: 'Open houses',
				route: '',
			},
		],
	},
	{
		headerTitle: '',
		route: '',
		option: [
			{
				text: 'New construction',
				route: '',
			},
			{
				text: 'Upcoming',
				route: '',
			},
			{
				text: 'Recent home sales',
				route: '',
			},
			{
				text: 'All houses',
				route: '',
			},
		],
	},
	buyingOptions,
	resources,
];

const sellList = [
	{
		headerTitle: 'My Home',
		route: '/sell ',
		option: [
			{
				text: 'A BPO Home Value',
				route: '',
			},
			{
				text: 'My BPO Dashboard',
				route: '',
			},
		],
	},
	{
		headerTitle: 'BPO Selling Options',
		route: '/sell ',
		option: [
			{
				text: 'A BPO Home Value',
				route: '',
			},
			{
				text: 'My BPO Dashboard',
				route: '',
			},
		],
	},
	resources,
];

const analyticsList = [
	{
		headerTitle: '',
		option: [
			{
				text: 'Investors Deal Analyzer',
				route: '',
			},
			{
				text: 'BPO',
				route: '',
			},
			{
				text: 'BPO Farm',
				route: '',
			},
		],
	},
];

const professionalOptions = [
	{ value: 'agent', label: 'Real Estate Agent' },
	{ value: 'realty_agent', label: 'BPO Homes Realty Agent' },
	// { value: 'home_improvement', label: 'Home Improvement Services' },
	// { value: 'photographer', label: 'Photographer' },
	// { value: 'home_builder', label: 'Home Builder' },
	// { value: 'home_inspector', label: 'Home Inspector' },
	// { value: 'property_manager', label: 'Property Manager' },
	// {
	// 	value: 'real_estate_prof',
	// 	label: 'Other Real Estate Professional',
	// },
];

const states = [
	{
		label: 'Alabama',
		value: 'Alabama',
	},
	{
		label: 'Alaska',
		value: 'Alaska',
	},
	{
		label: 'American Samoa',
		value: 'American Samoa',
	},
	{
		label: 'Arizona',
		value: 'Arizona',
	},
	{
		label: 'Arkansas',
		value: 'Arkansas',
	},
	{
		label: 'California',
		value: 'California',
	},
	{
		label: 'Colorado',
		value: 'Colorado',
	},
	{
		label: 'Connecticut',
		value: 'Connecticut',
	},
	{
		label: 'Delaware',
		value: 'Delaware',
	},
	{
		label: 'District Of Columbia',
		value: 'District Of Columbia',
	},
	{
		label: 'Federated States Of Micronesia',
		value: 'Federated States Of Micronesia',
	},
	{
		label: 'Florida',
		value: 'Florida',
	},
	{
		label: 'Georgia',
		value: 'Georgia',
	},
	{
		label: 'Guam',
		value: 'Guam',
	},
	{
		label: 'Hawaii',
		value: 'Hawaii',
	},
	{
		label: 'Idaho',
		value: 'Idaho',
	},
	{
		label: 'Illinois',
		value: 'Illinois',
	},
	{
		label: 'Indiana',
		value: 'Indiana',
	},
	{
		label: 'Iowa',
		value: 'Iowa',
	},
	{
		label: 'Kansas',
		value: 'Kansas',
	},
	{
		label: 'Kentucky',
		value: 'Kentucky',
	},
	{
		label: 'Louisiana',
		value: 'Louisiana',
	},
	{
		label: 'Maine',
		value: 'Maine',
	},
	{
		label: 'Marshall Islands',
		value: 'Marshall Islands',
	},
	{
		label: 'Maryland',
		value: 'Maryland',
	},
	{
		label: 'Massachusetts',
		value: 'Massachusetts',
	},
	{
		label: 'Michigan',
		value: 'Michigan',
	},
	{
		label: 'Minnesota',
		value: 'Minnesota',
	},
	{
		label: 'Mississippi',
		value: 'Mississippi',
	},
	{
		label: 'Missouri',
		value: 'Missouri',
	},
	{
		label: 'Montana',
		value: 'Montana',
	},
	{
		label: 'Nebraska',
		value: 'Nebraska',
	},
	{
		label: 'Nevada',
		value: 'Nevada',
	},
	{
		label: 'New Hampshire',
		value: 'New Hampshire',
	},
	{
		label: 'New Jersey',
		value: 'New Jersey',
	},
	{
		label: 'New Mexico',
		value: 'New Mexico',
	},
	{
		label: 'New York',
		value: 'New York',
	},
	{
		label: 'North Carolina',
		value: 'North Carolina',
	},
	{
		label: 'North Dakota',
		value: 'North Dakota',
	},
	{
		label: 'Northern Mariana Islands',
		value: 'Northern Mariana Islands',
	},
	{
		label: 'Ohio',
		value: 'Ohio',
	},
	{
		label: 'Oklahoma',
		value: 'Oklahoma',
	},
	{
		label: 'Oregon',
		value: 'Oregon',
	},
	{
		label: 'Palau',
		value: 'Palau',
	},
	{
		label: 'Pennsylvania',
		value: 'Pennsylvania',
	},
	{
		label: 'Puerto Rico',
		value: 'Puerto Rico',
	},
	{
		label: 'Rhode Island',
		value: 'Rhode Island',
	},
	{
		label: 'South Carolina',
		value: 'South Carolina',
	},
	{
		label: 'South Dakota',
		value: 'South Dakota',
	},
	{
		label: 'Tennessee',
		value: 'Tennessee',
	},
	{
		label: 'Texas',
		value: 'Texas',
	},
	{
		label: 'Utah',
		value: 'Utah',
	},
	{
		label: 'Vermont',
		value: 'Vermont',
	},
	{
		label: 'Virgin Islands',
		value: 'Virgin Islands',
	},
	{
		label: 'Virginia',
		value: 'Virginia',
	},
	{
		label: 'Washington',
		value: 'Washington',
	},
	{
		label: 'West Virginia',
		value: 'West Virginia',
	},
	{
		label: 'Wisconsin',
		value: 'Wisconsin',
	},
	{
		label: 'Wyoming',
		value: 'WY',
	},
];
const statesShort = [
	{
		label: 'Alabama',
		value: 'AL',
	},
	{
		label: 'Alaska',
		value: 'AK',
	},
	{
		label: 'American Samoa',
		value: 'AS',
	},
	{
		label: 'Arizona',
		value: 'AZ',
	},
	{
		label: 'Arkansas',
		value: 'AR',
	},
	{
		label: 'California',
		value: 'CA',
	},
	{
		label: 'Colorado',
		value: 'CO',
	},
	{
		label: 'Connecticut',
		value: 'CT',
	},
	{
		label: 'Delaware',
		value: 'DE',
	},
	{
		label: 'District Of Columbia',
		value: 'DC',
	},
	{
		label: 'Federated States Of Micronesia',
		value: 'FM',
	},
	{
		label: 'Florida',
		value: 'FL',
	},
	{
		label: 'Georgia',
		value: 'GA',
	},
	{
		label: 'Guam',
		value: 'GU',
	},
	{
		label: 'Hawaii',
		value: 'HI',
	},
	{
		label: 'Idaho',
		value: 'ID',
	},
	{
		label: 'Illinois',
		value: 'IL',
	},
	{
		label: 'Indiana',
		value: 'IN',
	},
	{
		label: 'Iowa',
		value: 'IA',
	},
	{
		label: 'Kansas',
		value: 'KS',
	},
	{
		label: 'Kentucky',
		value: 'KY',
	},
	{
		label: 'Louisiana',
		value: 'LA',
	},
	{
		label: 'Maine',
		value: 'ME',
	},
	{
		label: 'Marshall Islands',
		value: 'MH',
	},
	{
		label: 'Maryland',
		value: 'MD',
	},
	{
		label: 'Massachusetts',
		value: 'MA',
	},
	{
		label: 'Michigan',
		value: 'MI',
	},
	{
		label: 'Minnesota',
		value: 'MN',
	},
	{
		label: 'Mississippi',
		value: 'MS',
	},
	{
		label: 'Missouri',
		value: 'MO',
	},
	{
		label: 'Montana',
		value: 'MT',
	},
	{
		label: 'Nebraska',
		value: 'NE',
	},
	{
		label: 'Nevada',
		value: 'NV',
	},
	{
		label: 'New Hampshire',
		value: 'NH',
	},
	{
		label: 'New Jersey',
		value: 'NJ',
	},
	{
		label: 'New Mexico',
		value: 'NM',
	},
	{
		label: 'New York',
		value: 'NY',
	},
	{
		label: 'North Carolina',
		value: 'NC',
	},
	{
		label: 'North Dakota',
		value: 'ND',
	},
	{
		label: 'Northern Mariana Islands',
		value: 'MP',
	},
	{
		label: 'Ohio',
		value: 'OH',
	},
	{
		label: 'Oklahoma',
		value: 'OK',
	},
	{
		label: 'Oregon',
		value: 'OR',
	},
	{
		label: 'Palau',
		value: 'PW',
	},
	{
		label: 'Pennsylvania',
		value: 'PA',
	},
	{
		label: 'Puerto Rico',
		value: 'PR',
	},
	{
		label: 'Rhode Island',
		value: 'RI',
	},
	{
		label: 'South Carolina',
		value: 'SC',
	},
	{
		label: 'South Dakota',
		value: 'SD',
	},
	{
		label: 'Tennessee',
		value: 'TN',
	},
	{
		label: 'Texas',
		value: 'TX',
	},
	{
		label: 'Utah',
		value: 'UT',
	},
	{
		label: 'Vermont',
		value: 'VT',
	},
	{
		label: 'Virgin Islands',
		value: 'VI',
	},
	{
		label: 'Virginia',
		value: 'VA',
	},
	{
		label: 'Washington',
		value: 'WA',
	},
	{
		label: 'West Virginia',
		value: 'WV',
	},
	{
		label: 'Wisconsin',
		value: 'WI',
	},
	{
		label: 'Wyoming',
		value: 'WY',
	},
];

const farmPackages = [
	{
		title: 'Premier Agent (SET UP FEE NOT INCLUDED $99)',
		benefits: [
			`<b>1 Designated Farm Area </b>`,
			`Marketing to <b>300 Sellers</b>`,
			`<b>1</b> Guaranteed Pre qualified Lead Per Month`,
			`<b>4-6</b> Farm Area Pre qualified Farm Area Leads Annually`,
			`Estimated 15 Total Annual Leads`,
			`Email Marketing to Sellers Monthly Designs Included`,
			`Text MMS Marketing to Sellers Monthly Designs Included`,
			`Sellers Calls Quarterly`,
			`Luxury Investor Website`,
			`Premier Agent Advertising`,
			`NOD Notice of Default Pre Foreclosure Leads Unlimited`,
			`Divorce Leads Unlimited`,
			`Probate Leads Unlimited`,
			`For Sale By Owner FSBO Leads Unlimited`,
			`Expired Leads Unlimited`,
			`Canceled Leads Unlimited`,
			`Withdrawn Leads Unlimited`,
			`Agent Postcards Available for Purchase at  BPO Tech Cost (Postage Included)`,
			`Unlimited BPO Tech Training Center Access`,
			`Deal Analyzer Access`,
			`BPO Report Ordering Page`,
			`Investor Log (Investor List Purchase Available) `,
			`20% Referral Fee Required From BPO Homes  Pre Qualified Leads`,
		],
	},
	{
		title: 'Premier Agent Pro (SET UP FEE NOT INCLUDED $99)',
		benefits: [
			`<b>2 Designated Farm Areas</b> `,
			`Marketing to <b>600 Sellers</b>`,
			`<b>1</b> Guaranteed Pre qualified Lead Per Month`,
			`<b>8-10</b> Farm Area Pre Qualified Farm Area Leads Annually`,
			`Postcards Mailer Twice Per Year`,
			`Email Marketing to Sellers Monthly Designs Included`,
			`Text MMS Marketing to Sellers Monthly Designs Included`,
			`Seller Calls Quarterly`,
			`Luxury Investor Website`,
			`Premier Agent Advertising`,
			`NOD Notice of Default Pre Foreclosure Leads Unlimited`,
			`Divorce Leads Unlimited`,
			`Probate Leads Unlimited`,
			`For Sale By Owner FSBO Leads Unlimited`,
			`Expired Leads Unlimited`,
			`Canceled Leads Unlimited`,
			`Withdrawn Leads Unlimited`,
			`Deal Analyzer Access`,
			`BPO Report Ordering Page`,
			`Agent Postcards Available for Purchase at  BPO Tech Cost (Postage Included)`,
			`Unlimited BPO Tech Training Center Access`,
			`Investor Log (Investor List Purchase Available) `,
			`20% Referral Fee Required From BPO Homes Pre Qualified Leads`,
		],
	},
	{
		title: 'Premier Agent Elite (SET UP FEE NOT INCLUDED $99)',
		benefits: [
			`<b>3 Designated Farm Areas</b> `,
			`Marketing to <b>900 Sellers</b>`,
			`<b>1</b> Guaranteed Pre qualified Lead Per Month`,
			`<b>12-15</b> Pre Qualified Farm Leads Annually`,
			`Postcards Purchased At Cost  `,
			`Email Marketing to Sellers Monthly Designs Included`,
			`Text MMS Marketing to Sellers Monthly Designs Included`,
			`Call Sellers Every 4 Months`,
			`Luxury Investor Website`,
			`Premier Agent Advertising`,
			`NOD Notice of Default Pre Foreclosure Leads Unlimited`,
			`Divorce Leads Unlimited`,
			`Probate Leads Unlimited`,
			`For Sale By Owner FSBO Leads Unlimited`,
			`Expired Leads Unlimited`,
			`Canceled Leads Unlimited`,
			`Withdrawn Leads Unlimited`,
			`Agent Postcards Available for Purchase at  BPO Tech Cost (Postage Included)`,
			`Deal Analyzer Access`,
			`BPO Report Ordering Page`,
			`Unlimited BPO Tech Training Center Access`,
			`Investor Log (Investor List Purchase Available) `,
			`20% Referral Fee Required From BPO Homes Pre Qualified Leads`,
		],
	},
	{
		title: 'Premier Agent Ultra (SET UP FEE NOT INCLUDED $99)',
		benefits: [
			`<b>4</b> Designated Farm Areas `,
			`Marketing to <b>1200 Sellers</b>`,
			`<b>1</b> Guaranteed Pre qualified Lead Per Month`,
			`<b>17-20</b> Pre Qualified Leads Annually`,
			`Email Marketing to Sellers Monthly Designs Included`,
			`Text MMS Marketing to Sellers Monthly Designs Included`,
			`Call Sellers Every 4 Months`,
			`Luxury Investor Website`,
			`Premier Agent Advertising`,
			`NOD Notice of Default Pre Foreclosure Leads Unlimited`,
			`Divorce Leads Unlimited`,
			`Probate Leads Unlimited`,
			`For Sale By Owner FSBO Leads Unlimited`,
			`Expired Leads Unlimited`,
			`Canceled Leads Unlimited`,
			`Withdrawn Leads Unlimited`,
			`Deal Analyzer Access`,
			`BPO Report Ordering Page`,
			`Unlimited BPO Tech Training Center Access`,
			`Agent Postcards Available for Purchase at BPO Tech Cost (Postage Included)`,
			`Investor Log (Investor List Purchase Available) `,
			`20% Referral Fee Required From BPO Homes Pre Qualified Leads`,
		],
	},
];

const schedule = [
	{
		name: 'Email Campaigns',
		starter_days_to_add: 30,
		pro_days_to_add: 30,
		elite_days_to_add: 30,
	},
	{
		name: 'MMS Campaigns',
		starter_days_to_add: 30,
		pro_days_to_add: 30,
		elite_days_to_add: 30,
	},
	// {
	// 	name: 'Facebook Ads',
	// 	starter_days_to_add: 30,
	// 	pro_days_to_add: 30,
	// 	elite_days_to_add: 30,
	// },
	{
		name: 'Post Card Mailers',
		starter_days_to_add: 180,
		pro_days_to_add: 90,
		elite_days_to_add: 30,
	},
	{
		name: 'Seller Calling',
		starter_days_to_add: 180,
		pro_days_to_add: 180,
		elite_days_to_add: 90,
	},
];

//array of time zones in usa

const timeZones = [
	{
		value: 'Eastern Time Zone',
		label: 'Eastern Time Zone',
	},
	{
		value: 'Hawaii Time Zone',
		label: 'Hawaii Time Zone',
	},
	{
		value: 'Mountain Time Zone',
		label: 'Mountain Time Zone',
	},
	{
		value: 'Central Time Zone',
		label: 'Central Time Zone',
	},
	{
		value: 'Atlantic Time Zone',
		label: 'Atlantic Time Zone',
	},
	{
		value: 'Pacific Time Zone',
		label: 'Pacific Time Zone',
	},
	{
		value: 'Alaska Time Zone',
		label: 'Alaska Time Zone',
	},
];

const californiaCounties = [
	{
		label: 'ALAMEDA',
		value: 'ALAMEDA',
	},
	{
		label: 'ALPINE',
		value: 'ALPINE',
	},
	{
		label: 'AMADOR',
		value: 'AMADOR',
	},
	{
		label: 'BUTTE',
		value: 'BUTTE',
	},
	{
		label: 'CALAVERAS',
		value: 'CALAVERAS',
	},
	{
		label: 'COLUSA',
		value: 'COLUSA',
	},
	{
		label: 'CONTRA COSTA',
		value: 'CONTRA_COSTA',
	},
	{
		label: 'DEL NORTE',
		value: 'DEL_NORTE',
	},
	{
		label: 'EL DORADO',
		value: 'EL_DORADO',
	},
	{
		label: 'FRESNO',
		value: 'FRESNO',
	},
	{
		label: 'GLENN',
		value: 'GLENN',
	},
	{
		label: 'HUMBOLDT',
		value: 'HUMBOLDT',
	},
	{
		label: 'IMPERIAL',
		value: 'IMPERIAL',
	},
	{
		label: 'INYO',
		value: 'INYO',
	},
	{
		label: 'KERN',
		value: 'KERN',
	},
	{
		label: 'KINGS',
		value: 'KINGS',
	},
	{
		label: 'LAKE',
		value: 'LAKE',
	},
	{
		label: 'LASSEN',
		value: 'LASSEN',
	},
	{
		label: 'LOS ANGELES',
		value: 'LOS_ANGELES',
	},
	{
		label: 'MADERA',
		value: 'MADERA',
	},
	{
		label: 'MARIN',
		value: 'MARIN',
	},
	{
		label: 'MARIPOSA',
		value: 'MARIPOSA',
	},
	{
		label: 'MENDOCINO',
		value: 'MENDOCINO',
	},
	{
		label: 'MERCED',
		value: 'MERCED',
	},
	{
		label: 'MODOC',
		value: 'MODOC',
	},
	{
		label: 'MONO',
		value: 'MONO',
	},
	{
		label: 'MONTEREY',
		value: 'MONTEREY',
	},
	{
		label: 'NAPA',
		value: 'NAPA',
	},
	{
		label: 'NEVADA',
		value: 'NEVADA',
	},
	{
		label: 'ORANGE',
		value: 'ORANGE',
	},
	{
		label: 'PLACER',
		value: 'PLACER',
	},
	{
		label: 'PLUMAS',
		value: 'PLUMAS',
	},
	{
		label: 'RIVERSIDE',
		value: 'RIVERSIDE',
	},
	{
		label: 'SACRAMENTO',
		value: 'SACRAMENTO',
	},
	{
		label: 'SANTA BARBARA',
		value: 'SANTA_BARBARA',
	},
	{
		label: 'SANTA CLARA',
		value: 'SANTA_CLARA',
	},
	{
		label: 'SANTA CRUZ',
		value: 'SANTA_CRUZ',
	},
	{
		label: 'SAN BENITO',
		value: 'SAN_BENITO',
	},
	{
		label: 'SAN BERNARDINO',
		value: 'SAN_BERNARDINO',
	},
	{
		label: 'SAN DIEGO',
		value: 'SAN_DIEGO',
	},
	{
		label: 'SAN FRANCISCO',
		value: 'SAN_FRANCISCO',
	},
	{
		label: 'SAN JOAQUIN',
		value: 'SAN_JOAQUIN',
	},
	{
		label: 'SAN LUIS OBISPO',
		value: 'SAN_LUIS_OBISPO',
	},
	{
		label: 'SAN MATEO',
		value: 'SAN_MATEO',
	},
	{
		label: 'SHASTA',
		value: 'SHASTA',
	},
	{
		label: 'SIERRA',
		value: 'SIERRA',
	},
	{
		label: 'SISKIYOU',
		value: 'SISKIYOU',
	},
	{
		label: 'SOLANO',
		value: 'SOLANO',
	},
	{
		label: 'SONOMA',
		value: 'SONOMA',
	},
	{
		label: 'STANISLAUS',
		value: 'STANISLAUS',
	},
	{
		label: 'SUTTER',
		value: 'SUTTER',
	},
	{
		label: 'TEHAMA',
		value: 'TEHAMA',
	},
	{
		label: 'TRINITY',
		value: 'TRINITY',
	},
	{
		label: 'TULARE',
		value: 'TULARE',
	},
	{
		label: 'TUOLUMNE',
		value: 'TUOLUMNE',
	},
	{
		label: 'VENTURA',
		value: 'VENTURA',
	},
	{
		label: 'YOLO',
		value: 'YOLO',
	},
	{
		label: 'YUBA',
		value: 'YUBA',
	},
];

const countiesAndCities = {
	ALAMEDA: [
		{
			label: 'LIVERMORE',
			value: 'LIVERMORE',
		},
		{
			label: 'UNION CITY',
			value: 'UNION CITY',
		},
		{
			label: 'DISCOVERY BAY',
			value: 'DISCOVERY BAY',
		},
		{
			label: 'FREMONT',
			value: 'FREMONT',
		},
		{
			label: 'EMERYVILLE',
			value: 'EMERYVILLE',
		},
		{
			label: 'TRACY',
			value: 'TRACY',
		},
		{
			label: 'DUBLIN',
			value: 'DUBLIN',
		},
		{
			label: 'OAKLAND',
			value: 'OAKLAND',
		},
		{
			label: 'SAN FRANCISCO',
			value: 'SAN FRANCISCO',
		},
		{
			label: 'KENSINGTON',
			value: 'KENSINGTON',
		},
		{
			label: 'MOUNTAIN HOUSE',
			value: 'MOUNTAIN HOUSE',
		},
		{
			label: 'BERKELEY',
			value: 'BERKELEY',
		},
		{
			label: 'BYRON',
			value: 'BYRON',
		},
		{
			label: 'SAN LEANDRO',
			value: 'SAN LEANDRO',
		},
		{
			label: 'ALAMEDA',
			value: 'ALAMEDA',
		},
		{
			label: 'NEWARK',
			value: 'NEWARK',
		},
		{
			label: 'SUNOL',
			value: 'SUNOL',
		},
		{
			label: 'ALBANY',
			value: 'ALBANY',
		},
		{
			label: 'SAN LORENZO',
			value: 'SAN LORENZO',
		},
		{
			label: 'HAYWARD',
			value: 'HAYWARD',
		},
		{
			label: 'PIEDMONT',
			value: 'PIEDMONT',
		},
		{
			label: 'PLEASANTON',
			value: 'PLEASANTON',
		},
		{
			label: 'CASTRO VALLEY',
			value: 'CASTRO VALLEY',
		},
	],
	ALPINE: [
		{
			label: 'ARNOLD',
			value: 'ARNOLD',
		},
		{
			label: 'MESA VISTA',
			value: 'MESA VISTA',
		},
		{
			label: 'BEAR VALLEY',
			value: 'BEAR VALLEY',
		},
		{
			label: 'MARKLEEVILLE',
			value: 'MARKLEEVILLE',
		},
		{
			label: 'ALPINE VILLAGE',
			value: 'ALPINE VILLAGE',
		},
		{
			label: 'KIRKWOOD',
			value: 'KIRKWOOD',
		},
	],
	AMADOR: [
		{
			label: 'KIRKWOOD',
			value: 'KIRKWOOD',
		},
		{
			label: 'FIDDLETOWN',
			value: 'FIDDLETOWN',
		},
		{
			label: 'BUCKHORN',
			value: 'BUCKHORN',
		},
		{
			label: 'PIONEER',
			value: 'PIONEER',
		},
		{
			label: 'AMADOR PINES',
			value: 'AMADOR PINES',
		},
		{
			label: 'LOCKWOOD',
			value: 'LOCKWOOD',
		},
		{
			label: 'DRYTOWN',
			value: 'DRYTOWN',
		},
		{
			label: 'PLYMOUTH',
			value: 'PLYMOUTH',
		},
		{
			label: 'SUTTER CREEK',
			value: 'SUTTER CREEK',
		},
		{
			label: 'AMADOR CITY',
			value: 'AMADOR CITY',
		},
		{
			label: 'JACKSON',
			value: 'JACKSON',
		},
		{
			label: 'VOLCANO',
			value: 'VOLCANO',
		},
		{
			label: 'RIVER PINES',
			value: 'RIVER PINES',
		},
		{
			label: 'RED CORRAL',
			value: 'RED CORRAL',
		},
		{
			label: 'PINE GROVE',
			value: 'PINE GROVE',
		},
		{
			label: 'IONE',
			value: 'IONE',
		},
	],
	BUTTE: [
		{
			label: 'OROVILLE EAST',
			value: 'OROVILLE EAST',
		},
		{
			label: 'STIRLING CITY',
			value: 'STIRLING CITY',
		},
		{
			label: 'BUTTE VALLEY',
			value: 'BUTTE VALLEY',
		},
		{
			label: 'BUTTE MEADOWS',
			value: 'BUTTE MEADOWS',
		},
		{
			label: 'CONCOW',
			value: 'CONCOW',
		},
		{
			label: 'PARADISE',
			value: 'PARADISE',
		},
		{
			label: 'BIGGS',
			value: 'BIGGS',
		},
		{
			label: 'COHASSET',
			value: 'COHASSET',
		},
		{
			label: 'FEATHER FALLS',
			value: 'FEATHER FALLS',
		},
		{
			label: 'RACKERBY',
			value: 'RACKERBY',
		},
		{
			label: 'PALERMO',
			value: 'PALERMO',
		},
		{
			label: 'OROVILLE',
			value: 'OROVILLE',
		},
		{
			label: 'MARYSVILLE',
			value: 'MARYSVILLE',
		},
		{
			label: 'CHICO',
			value: 'CHICO',
		},
		{
			label: 'THERMALITO',
			value: 'THERMALITO',
		},
		{
			label: 'DAYTON',
			value: 'DAYTON',
		},
		{
			label: 'BANGOR',
			value: 'BANGOR',
		},
		{
			label: 'BERRY CREEK',
			value: 'BERRY CREEK',
		},
		{
			label: 'YANKEE HILL',
			value: 'YANKEE HILL',
		},
		{
			label: 'CLIPPER MILLS',
			value: 'CLIPPER MILLS',
		},
		{
			label: 'FORBESTOWN',
			value: 'FORBESTOWN',
		},
		{
			label: 'NELSON',
			value: 'NELSON',
		},
		{
			label: 'MAGALIA',
			value: 'MAGALIA',
		},
		{
			label: 'GRIDLEY',
			value: 'GRIDLEY',
		},
		{
			label: 'HONCUT',
			value: 'HONCUT',
		},
		{
			label: 'DURHAM',
			value: 'DURHAM',
		},
		{
			label: 'FOREST RANCH',
			value: 'FOREST RANCH',
		},
		{
			label: 'RICHVALE',
			value: 'RICHVALE',
		},
	],
	CALAVERAS: [
		{
			label: 'COPPEROPOLIS',
			value: 'COPPEROPOLIS',
		},
		{
			label: 'VALLECITO',
			value: 'VALLECITO',
		},
		{
			label: 'DORRINGTON',
			value: 'DORRINGTON',
		},
		{
			label: 'HATHAWAY PINES',
			value: 'HATHAWAY PINES',
		},
		{
			label: 'VALLEY SPRINGS',
			value: 'VALLEY SPRINGS',
		},
		{
			label: 'BURSON',
			value: 'BURSON',
		},
		{
			label: 'RANCHO CALAVERAS',
			value: 'RANCHO CALAVERAS',
		},
		{
			label: 'RAIL ROAD FLAT',
			value: 'RAIL ROAD FLAT',
		},
		{
			label: 'WILSEYVILLE',
			value: 'WILSEYVILLE',
		},
		{
			label: 'MOUNTAIN RANCH',
			value: 'MOUNTAIN RANCH',
		},
		{
			label: 'SHEEP RANCH',
			value: 'SHEEP RANCH',
		},
		{
			label: 'ANGELS CITY',
			value: 'ANGELS CITY',
		},
		{
			label: 'DOUGLAS FLAT',
			value: 'DOUGLAS FLAT',
		},
		{
			label: 'AVERY',
			value: 'AVERY',
		},
		{
			label: 'CAMP CONNELL',
			value: 'CAMP CONNELL',
		},
		{
			label: 'ANGELS',
			value: 'ANGELS',
		},
		{
			label: 'MOKELUMNE HILL',
			value: 'MOKELUMNE HILL',
		},
		{
			label: 'ARNOLD',
			value: 'ARNOLD',
		},
		{
			label: 'FOREST MEADOWS',
			value: 'FOREST MEADOWS',
		},
		{
			label: 'WEST POINT',
			value: 'WEST POINT',
		},
		{
			label: 'SAN ANDREAS',
			value: 'SAN ANDREAS',
		},
		{
			label: 'CAMPO SECO',
			value: 'CAMPO SECO',
		},
		{
			label: 'GLENCOE',
			value: 'GLENCOE',
		},
		{
			label: 'RAILROAD FLAT',
			value: 'RAILROAD FLAT',
		},
		{
			label: 'WALLACE',
			value: 'WALLACE',
		},
		{
			label: 'MURPHYS',
			value: 'MURPHYS',
		},
		{
			label: 'FARMINGTON',
			value: 'FARMINGTON',
		},
		{
			label: 'LINDEN',
			value: 'LINDEN',
		},
		{
			label: 'ANGELS CAMP',
			value: 'ANGELS CAMP',
		},
		{
			label: 'BEAR VALLEY',
			value: 'BEAR VALLEY',
		},
	],
	COLUSA: [
		{
			label: 'COLLEGE CITY',
			value: 'COLLEGE CITY',
		},
		{
			label: 'ARBUCKLE',
			value: 'ARBUCKLE',
		},
		{
			label: 'COLUSA',
			value: 'COLUSA',
		},
		{
			label: 'MERIDIAN',
			value: 'MERIDIAN',
		},
		{
			label: 'PRINCETON',
			value: 'PRINCETON',
		},
		{
			label: 'MAXWELL',
			value: 'MAXWELL',
		},
		{
			label: 'WILLOWS',
			value: 'WILLOWS',
		},
		{
			label: 'WILLIAMS',
			value: 'WILLIAMS',
		},
		{
			label: 'KNIGHTS LANDING',
			value: 'KNIGHTS LANDING',
		},
		{
			label: 'GRIMES',
			value: 'GRIMES',
		},
		{
			label: 'STONYFORD',
			value: 'STONYFORD',
		},
	],
	CONTRA_COSTA: [
		{
			label: 'BRENTWOOD',
			value: 'BRENTWOOD',
		},
		{
			label: 'TASSAJARA',
			value: 'TASSAJARA',
		},
		{
			label: 'LIVERMORE',
			value: 'LIVERMORE',
		},
		{
			label: 'DANVILLE',
			value: 'DANVILLE',
		},
		{
			label: 'BETHEL ISLAND',
			value: 'BETHEL ISLAND',
		},
		{
			label: 'PACHECO',
			value: 'PACHECO',
		},
		{
			label: 'HERCULES',
			value: 'HERCULES',
		},
		{
			label: 'EL SOBRANTE',
			value: 'EL SOBRANTE',
		},
		{
			label: 'BRIONES',
			value: 'BRIONES',
		},
		{
			label: 'CROCKETT',
			value: 'CROCKETT',
		},
		{
			label: 'PITTSBURG',
			value: 'PITTSBURG',
		},
		{
			label: 'EL CERRITO',
			value: 'EL CERRITO',
		},
		{
			label: 'CLAYTON',
			value: 'CLAYTON',
		},
		{
			label: 'ANTIOCH',
			value: 'ANTIOCH',
		},
		{
			label: 'PLEASANTON',
			value: 'PLEASANTON',
		},
		{
			label: 'PORT COSTA',
			value: 'PORT COSTA',
		},
		{
			label: 'MORAGA',
			value: 'MORAGA',
		},
		{
			label: 'SAN PABLO',
			value: 'SAN PABLO',
		},
		{
			label: 'EAST CONTRA COSTA',
			value: 'EAST CONTRA COSTA',
		},
		{
			label: 'CANYON',
			value: 'CANYON',
		},
		{
			label: 'SAN RAMON',
			value: 'SAN RAMON',
		},
		{
			label: 'LAFAYETTE',
			value: 'LAFAYETTE',
		},
		{
			label: 'OAKLEY',
			value: 'OAKLEY',
		},
		{
			label: 'PINOLE',
			value: 'PINOLE',
		},
		{
			label: 'PLEASANT HILL',
			value: 'PLEASANT HILL',
		},
		{
			label: 'CONCORD',
			value: 'CONCORD',
		},
		{
			label: 'ORINDA',
			value: 'ORINDA',
		},
		{
			label: 'RICHMOND',
			value: 'RICHMOND',
		},
		{
			label: 'DISCOVERY BAY',
			value: 'DISCOVERY BAY',
		},
		{
			label: 'RODEO',
			value: 'RODEO',
		},
		{
			label: 'MARTINEZ',
			value: 'MARTINEZ',
		},
		{
			label: 'OAKLAND',
			value: 'OAKLAND',
		},
		{
			label: 'KNIGHTSEN',
			value: 'KNIGHTSEN',
		},
		{
			label: 'KENSINGTON',
			value: 'KENSINGTON',
		},
		{
			label: 'ALAMO',
			value: 'ALAMO',
		},
		{
			label: 'DIABLO',
			value: 'DIABLO',
		},
		{
			label: 'WALNUT CREEK',
			value: 'WALNUT CREEK',
		},
		{
			label: 'BYRON',
			value: 'BYRON',
		},
		{
			label: 'CLYDE',
			value: 'CLYDE',
		},
		{
			label: 'BAY POINT',
			value: 'BAY POINT',
		},
	],
	DEL_NORTE: [
		{
			label: 'KLAMATH',
			value: 'KLAMATH',
		},
		{
			label: 'CRESCENT CITY',
			value: 'CRESCENT CITY',
		},
		{
			label: 'SMITH RIVER',
			value: 'SMITH RIVER',
		},
		{
			label: 'FORT DICK',
			value: 'FORT DICK',
		},
		{
			label: 'GASQUET',
			value: 'GASQUET',
		},
		{
			label: 'BERTSCH-OCEANVIEW',
			value: 'BERTSCH-OCEANVIEW',
		},
	],
	EL_DORADO: [
		{
			label: 'RESCUE',
			value: 'RESCUE',
		},
		{
			label: 'KELSEY',
			value: 'KELSEY',
		},
		{
			label: 'LOTUS',
			value: 'LOTUS',
		},
		{
			label: 'DIAMOND SPRINGS',
			value: 'DIAMOND SPRINGS',
		},
		{
			label: 'TWIN BRIDGES',
			value: 'TWIN BRIDGES',
		},
		{
			label: 'FIDDLETOWN',
			value: 'FIDDLETOWN',
		},
		{
			label: 'POLLOCK PINES',
			value: 'POLLOCK PINES',
		},
		{
			label: 'SOUTH EL DORADO',
			value: 'SOUTH EL DORADO',
		},
		{
			label: 'KYBURZ',
			value: 'KYBURZ',
		},
		{
			label: 'SOMERSET',
			value: 'SOMERSET',
		},
		{
			label: 'PLACERVILLE',
			value: 'PLACERVILLE',
		},
		{
			label: 'ECHO LAKE',
			value: 'ECHO LAKE',
		},
		{
			label: 'MOUNT AUKUM',
			value: 'MOUNT AUKUM',
		},
		{
			label: 'HOMEWOOD',
			value: 'HOMEWOOD',
		},
		{
			label: 'GREENWOOD',
			value: 'GREENWOOD',
		},
		{
			label: 'CAMINO',
			value: 'CAMINO',
		},
		{
			label: 'S LAKE TAHOE',
			value: 'S LAKE TAHOE',
		},
		{
			label: 'COLOMA',
			value: 'COLOMA',
		},
		{
			label: 'SHINGLE SPRINGS',
			value: 'SHINGLE SPRINGS',
		},
		{
			label: 'SOUTH LAKE TAHOE',
			value: 'SOUTH LAKE TAHOE',
		},
		{
			label: 'EL DORADO HLS',
			value: 'EL DORADO HLS',
		},
		{
			label: 'GEORGETOWN',
			value: 'GEORGETOWN',
		},
		{
			label: 'EL DORADO HILLS',
			value: 'EL DORADO HILLS',
		},
		{
			label: 'COOL',
			value: 'COOL',
		},
		{
			label: 'PILOT HILL',
			value: 'PILOT HILL',
		},
		{
			label: 'GARDEN VALLEY',
			value: 'GARDEN VALLEY',
		},
		{
			label: 'EL DORADO',
			value: 'EL DORADO',
		},
		{
			label: 'GRIZZLY FLATS',
			value: 'GRIZZLY FLATS',
		},
		{
			label: 'CAMERON PARK',
			value: 'CAMERON PARK',
		},
		{
			label: 'TAHOMA',
			value: 'TAHOMA',
		},
	],
	FRESNO: [
		{
			label: 'PINEHURST',
			value: 'PINEHURST',
		},
		{
			label: 'KINGS CANYON NATIONAL PK',
			value: 'KINGS CANYON NATIONAL PK',
		},
		{
			label: 'MILLERTON',
			value: 'MILLERTON',
		},
		{
			label: 'DEL REY',
			value: 'DEL REY',
		},
		{
			label: 'FRESNO',
			value: 'FRESNO',
		},
		{
			label: 'TRANQUILLITY',
			value: 'TRANQUILLITY',
		},
		{
			label: 'LATON',
			value: 'LATON',
		},
		{
			label: 'ORANGE COVE',
			value: 'ORANGE COVE',
		},
		{
			label: 'BIG CREEK',
			value: 'BIG CREEK',
		},
		{
			label: 'PIEDRA',
			value: 'PIEDRA',
		},
		{
			label: 'BIOLA',
			value: 'BIOLA',
		},
		{
			label: 'KERMAN',
			value: 'KERMAN',
		},
		{
			label: 'HELM',
			value: 'HELM',
		},
		{
			label: 'DUNLAP',
			value: 'DUNLAP',
		},
		{
			label: 'EASTON',
			value: 'EASTON',
		},
		{
			label: 'PINEDALE',
			value: 'PINEDALE',
		},
		{
			label: 'FRIANT',
			value: 'FRIANT',
		},
		{
			label: 'MENDOTA',
			value: 'MENDOTA',
		},
		{
			label: 'PRATHER',
			value: 'PRATHER',
		},
		{
			label: 'MIRAMONTE',
			value: 'MIRAMONTE',
		},
		{
			label: 'COALINGA',
			value: 'COALINGA',
		},
		{
			label: 'HURON',
			value: 'HURON',
		},
		{
			label: 'DINUBA',
			value: 'DINUBA',
		},
		{
			label: 'HUME',
			value: 'HUME',
		},
		{
			label: 'LAKESHORE',
			value: 'LAKESHORE',
		},
		{
			label: 'SANGER',
			value: 'SANGER',
		},
		{
			label: 'CARUTHERS',
			value: 'CARUTHERS',
		},
		{
			label: 'SQUAW VALLEY',
			value: 'SQUAW VALLEY',
		},
		{
			label: 'FIREBAUGH',
			value: 'FIREBAUGH',
		},
		{
			label: 'FOWLER',
			value: 'FOWLER',
		},
		{
			label: 'CANTUA CREEK',
			value: 'CANTUA CREEK',
		},
		{
			label: 'AUBERRY',
			value: 'AUBERRY',
		},
		{
			label: 'SIERRA',
			value: 'SIERRA',
		},
		{
			label: 'TOLLHOUSE',
			value: 'TOLLHOUSE',
		},
		{
			label: 'PARLIER',
			value: 'PARLIER',
		},
		{
			label: 'CALWA',
			value: 'CALWA',
		},
		{
			label: 'SAN JOAQUIN',
			value: 'SAN JOAQUIN',
		},
		{
			label: 'OROSI',
			value: 'OROSI',
		},
		{
			label: 'RAISIN CITY',
			value: 'RAISIN CITY',
		},
		{
			label: 'FIVE POINTS',
			value: 'FIVE POINTS',
		},
		{
			label: 'KINGSBURG',
			value: 'KINGSBURG',
		},
		{
			label: 'SHAVER LAKE',
			value: 'SHAVER LAKE',
		},
		{
			label: 'SAN JOAQUIN-TRANQUILLITY',
			value: 'SAN JOAQUIN-TRANQUILLITY',
		},
		{
			label: 'REEDLEY',
			value: 'REEDLEY',
		},
		{
			label: 'DOS PALOS',
			value: 'DOS PALOS',
		},
		{
			label: 'CLOVIS',
			value: 'CLOVIS',
		},
		{
			label: 'CENTERVILLE',
			value: 'CENTERVILLE',
		},
		{
			label: 'RIVERDALE',
			value: 'RIVERDALE',
		},
		{
			label: 'SELMA',
			value: 'SELMA',
		},
	],
	GLENN: [
		{
			label: 'WILLOWS',
			value: 'WILLOWS',
		},
		{
			label: 'ARTOIS',
			value: 'ARTOIS',
		},
		{
			label: 'ELK CREEK',
			value: 'ELK CREEK',
		},
		{
			label: 'ORLAND',
			value: 'ORLAND',
		},
		{
			label: 'GLENN',
			value: 'GLENN',
		},
		{
			label: 'HAMILTON CITY',
			value: 'HAMILTON CITY',
		},
		{
			label: 'PRINCETON',
			value: 'PRINCETON',
		},
		{
			label: 'BUTTE CITY',
			value: 'BUTTE CITY',
		},
	],
	HUMBOLDT: [
		{
			label: 'MYRTLETOWN',
			value: 'MYRTLETOWN',
		},
		{
			label: 'BLUE LAKE',
			value: 'BLUE LAKE',
		},
		{
			label: 'GARBERVILLE',
			value: 'GARBERVILLE',
		},
		{
			label: 'PHILLIPSVILLE',
			value: 'PHILLIPSVILLE',
		},
		{
			label: 'SOMES BAR',
			value: 'SOMES BAR',
		},
		{
			label: 'ORLEANS',
			value: 'ORLEANS',
		},
		{
			label: 'PETROLIA',
			value: 'PETROLIA',
		},
		{
			label: 'ARCATA',
			value: 'ARCATA',
		},
		{
			label: 'MAD RIVER',
			value: 'MAD RIVER',
		},
		{
			label: 'BIG LAGOON',
			value: 'BIG LAGOON',
		},
		{
			label: 'FERNDALE',
			value: 'FERNDALE',
		},
		{
			label: 'RIO DELL',
			value: 'RIO DELL',
		},
		{
			label: 'WESTHAVEN-MOONSTONE',
			value: 'WESTHAVEN-MOONSTONE',
		},
		{
			label: 'TRINIDAD',
			value: 'TRINIDAD',
		},
		{
			label: 'CARLOTTA',
			value: 'CARLOTTA',
		},
		{
			label: 'WILLOW CREEK',
			value: 'WILLOW CREEK',
		},
		{
			label: 'WEOTT',
			value: 'WEOTT',
		},
		{
			label: 'MYERS FLAT',
			value: 'MYERS FLAT',
		},
		{
			label: 'SHELTER COVE',
			value: 'SHELTER COVE',
		},
		{
			label: 'SCOTIA',
			value: 'SCOTIA',
		},
		{
			label: 'CUTTEN',
			value: 'CUTTEN',
		},
		{
			label: 'HOOPA',
			value: 'HOOPA',
		},
		{
			label: 'BLOCKSBURG',
			value: 'BLOCKSBURG',
		},
		{
			label: 'PINE HILLS',
			value: 'PINE HILLS',
		},
		{
			label: 'FIELDS LANDING',
			value: 'FIELDS LANDING',
		},
		{
			label: 'ORICK',
			value: 'ORICK',
		},
		{
			label: 'ALDERPOINT',
			value: 'ALDERPOINT',
		},
		{
			label: 'HYDESVILLE',
			value: 'HYDESVILLE',
		},
		{
			label: 'HONEYDEW',
			value: 'HONEYDEW',
		},
		{
			label: 'FIELDBROOK',
			value: 'FIELDBROOK',
		},
		{
			label: 'KEP EL',
			value: 'KEP EL',
		},
		{
			label: 'WHITETHORN',
			value: 'WHITETHORN',
		},
		{
			label: 'BAYSIDE',
			value: 'BAYSIDE',
		},
		{
			label: 'MCKINLEYVILLE',
			value: 'MCKINLEYVILLE',
		},
		{
			label: 'KNEELAND',
			value: 'KNEELAND',
		},
		{
			label: 'BRIDGEVILLE',
			value: 'BRIDGEVILLE',
		},
		{
			label: 'WEITCHPEC',
			value: 'WEITCHPEC',
		},
		{
			label: 'LOLETA',
			value: 'LOLETA',
		},
		{
			label: 'REDCREST',
			value: 'REDCREST',
		},
		{
			label: 'WAUTEC',
			value: 'WAUTEC',
		},
		{
			label: 'REDWAY',
			value: 'REDWAY',
		},
		{
			label: 'KORBEL',
			value: 'KORBEL',
		},
		{
			label: 'EUREKA',
			value: 'EUREKA',
		},
		{
			label: 'MIRANDA',
			value: 'MIRANDA',
		},
		{
			label: 'SAMOA',
			value: 'SAMOA',
		},
		{
			label: 'FORTUNA',
			value: 'FORTUNA',
		},
	],
	IMPERIAL: [
		{
			label: 'CALIPATRIA-WESTMORLAND',
			value: 'CALIPATRIA-WESTMORLAND',
		},
		{
			label: 'CALEXICO',
			value: 'CALEXICO',
		},
		{
			label: 'PALO VERDE',
			value: 'PALO VERDE',
		},
		{
			label: 'SALTON SEA BEACH',
			value: 'SALTON SEA BEACH',
		},
		{
			label: 'THERMAL',
			value: 'THERMAL',
		},
		{
			label: 'OCOTILLO',
			value: 'OCOTILLO',
		},
		{
			label: 'CALIPATRIA',
			value: 'CALIPATRIA',
		},
		{
			label: 'BOMBAY BEACH',
			value: 'BOMBAY BEACH',
		},
		{
			label: 'BRAWLEY',
			value: 'BRAWLEY',
		},
		{
			label: 'WESTMORLAND',
			value: 'WESTMORLAND',
		},
		{
			label: 'SEELEY',
			value: 'SEELEY',
		},
		{
			label: 'WINTERHAVEN',
			value: 'WINTERHAVEN',
		},
		{
			label: 'SALTON CITY',
			value: 'SALTON CITY',
		},
		{
			label: 'BARD',
			value: 'BARD',
		},
		{
			label: 'HEBER',
			value: 'HEBER',
		},
		{
			label: 'EL CENTRO',
			value: 'EL CENTRO',
		},
		{
			label: 'IMPERIAL',
			value: 'IMPERIAL',
		},
		{
			label: 'DESERT SHORES',
			value: 'DESERT SHORES',
		},
		{
			label: 'NILAND',
			value: 'NILAND',
		},
		{
			label: 'HOLTVILLE',
			value: 'HOLTVILLE',
		},
	],
	INYO: [
		{
			label: 'SHOSHONE',
			value: 'SHOSHONE',
		},
		{
			label: 'LITTLE LAKE',
			value: 'LITTLE LAKE',
		},
		{
			label: 'HOMEWOOD CANYON',
			value: 'HOMEWOOD CANYON',
		},
		{
			label: 'ALABAMA HILLS',
			value: 'ALABAMA HILLS',
		},
		{
			label: 'BISHOP',
			value: 'BISHOP',
		},
		{
			label: 'PEARSONVILLE',
			value: 'PEARSONVILLE',
		},
		{
			label: 'FURNACE CREEK',
			value: 'FURNACE CREEK',
		},
		{
			label: 'KEELER',
			value: 'KEELER',
		},
		{
			label: 'TRONA',
			value: 'TRONA',
		},
		{
			label: 'OLANCHA',
			value: 'OLANCHA',
		},
		{
			label: 'DARWIN',
			value: 'DARWIN',
		},
		{
			label: 'TECOPA',
			value: 'TECOPA',
		},
		{
			label: 'BIG PINE',
			value: 'BIG PINE',
		},
		{
			label: 'CHARLESTON VIEW',
			value: 'CHARLESTON VIEW',
		},
		{
			label: 'DEATH VALLEY',
			value: 'DEATH VALLEY',
		},
		{
			label: 'LONE PINE',
			value: 'LONE PINE',
		},
		{
			label: 'WILKERSON',
			value: 'WILKERSON',
		},
		{
			label: 'INDEPENDENCE',
			value: 'INDEPENDENCE',
		},
		{
			label: 'CARTAGO',
			value: 'CARTAGO',
		},
	],
	KERN: [
		{
			label: 'WOFFORD HEIGHTS',
			value: 'WOFFORD HEIGHTS',
		},
		{
			label: 'LANCASTER',
			value: 'LANCASTER',
		},
		{
			label: 'LEBEC',
			value: 'LEBEC',
		},
		{
			label: 'TUPMAN',
			value: 'TUPMAN',
		},
		{
			label: 'MONOLITH',
			value: 'MONOLITH',
		},
		{
			label: 'WOODY',
			value: 'WOODY',
		},
		{
			label: 'EAST KERN',
			value: 'EAST KERN',
		},
		{
			label: 'PINE MOUNTAIN CLUB',
			value: 'PINE MOUNTAIN CLUB',
		},
		{
			label: 'LAMONT',
			value: 'LAMONT',
		},
		{
			label: 'DELANO',
			value: 'DELANO',
		},
		{
			label: 'BEAR VLY SPGS',
			value: 'BEAR VLY SPGS',
		},
		{
			label: 'LOST HILLS',
			value: 'LOST HILLS',
		},
		{
			label: 'EDISON',
			value: 'EDISON',
		},
		{
			label: 'ROSAMOND',
			value: 'ROSAMOND',
		},
		{
			label: 'ROSEDALE',
			value: 'ROSEDALE',
		},
		{
			label: 'RIDGECREST',
			value: 'RIDGECREST',
		},
		{
			label: 'GOLDEN HILLS',
			value: 'GOLDEN HILLS',
		},
		{
			label: 'MCFARLAND',
			value: 'MCFARLAND',
		},
		{
			label: 'GLENNVILLE',
			value: 'GLENNVILLE',
		},
		{
			label: 'NORTH EDWARDS',
			value: 'NORTH EDWARDS',
		},
		{
			label: 'EL ADOBE',
			value: 'EL ADOBE',
		},
		{
			label: 'SHAFTER',
			value: 'SHAFTER',
		},
		{
			label: 'MC FARLAND',
			value: 'MC FARLAND',
		},
		{
			label: 'LAKE OF THE WOODS',
			value: 'LAKE OF THE WOODS',
		},
		{
			label: 'PUMPKIN CENTER',
			value: 'PUMPKIN CENTER',
		},
		{
			label: 'BODFISH',
			value: 'BODFISH',
		},
		{
			label: 'BAKERSFIELD',
			value: 'BAKERSFIELD',
		},
		{
			label: 'DERBY ACRES',
			value: 'DERBY ACRES',
		},
		{
			label: 'MOJAVE',
			value: 'MOJAVE',
		},
		{
			label: 'DUSTIN ACRES',
			value: 'DUSTIN ACRES',
		},
		{
			label: 'LAKE ISABELLA',
			value: 'LAKE ISABELLA',
		},
		{
			label: 'WILLOW SPRINGS',
			value: 'WILLOW SPRINGS',
		},
		{
			label: 'ALTA SIERRA',
			value: 'ALTA SIERRA',
		},
		{
			label: 'OILDALE',
			value: 'OILDALE',
		},
		{
			label: 'BORON',
			value: 'BORON',
		},
		{
			label: 'BUTTONWILLOW',
			value: 'BUTTONWILLOW',
		},
		{
			label: 'CALIFORNIA CITY',
			value: 'CALIFORNIA CITY',
		},
		{
			label: 'BEAR VALLEY SPRINGS',
			value: 'BEAR VALLEY SPRINGS',
		},
		{
			label: 'HAVILAH',
			value: 'HAVILAH',
		},
		{
			label: 'CANTIL',
			value: 'CANTIL',
		},
		{
			label: 'JOHANNESBURG',
			value: 'JOHANNESBURG',
		},
		{
			label: 'TAFT',
			value: 'TAFT',
		},
		{
			label: 'KEENE',
			value: 'KEENE',
		},
		{
			label: 'SQUIRREL MOUNTAIN VALLEY',
			value: 'SQUIRREL MOUNTAIN VALLEY',
		},
		{
			label: 'ARVIN',
			value: 'ARVIN',
		},
		{
			label: 'MOUNTAIN MESA',
			value: 'MOUNTAIN MESA',
		},
		{
			label: 'FRAZIER PARK',
			value: 'FRAZIER PARK',
		},
		{
			label: 'KERNVILLE',
			value: 'KERNVILLE',
		},
		{
			label: 'EAST NILES',
			value: 'EAST NILES',
		},
		{
			label: 'WOFFORD HTS',
			value: 'WOFFORD HTS',
		},
		{
			label: 'WELDON',
			value: 'WELDON',
		},
		{
			label: 'WASCO',
			value: 'WASCO',
		},
		{
			label: 'FREMONT VALLEY',
			value: 'FREMONT VALLEY',
		},
		{
			label: 'EDWARDS',
			value: 'EDWARDS',
		},
		{
			label: 'MARICOPA',
			value: 'MARICOPA',
		},
		{
			label: 'CALIENTE',
			value: 'CALIENTE',
		},
		{
			label: 'STALLION SPRINGS',
			value: 'STALLION SPRINGS',
		},
		{
			label: 'GREENFIELD',
			value: 'GREENFIELD',
		},
		{
			label: 'INYOKERN',
			value: 'INYOKERN',
		},
		{
			label: 'FELLOWS',
			value: 'FELLOWS',
		},
		{
			label: 'ONYX',
			value: 'ONYX',
		},
		{
			label: 'MC KITTRICK',
			value: 'MC KITTRICK',
		},
		{
			label: 'RANDSBURG',
			value: 'RANDSBURG',
		},
		{
			label: 'TEHACHAPI',
			value: 'TEHACHAPI',
		},
	],
	KINGS: [
		{
			label: 'RIVERDALE',
			value: 'RIVERDALE',
		},
		{
			label: 'AVENAL',
			value: 'AVENAL',
		},
		{
			label: 'STRATFORD',
			value: 'STRATFORD',
		},
		{
			label: 'LEMOORE',
			value: 'LEMOORE',
		},
		{
			label: 'ALPAUGH',
			value: 'ALPAUGH',
		},
		{
			label: 'ARMONA',
			value: 'ARMONA',
		},
		{
			label: 'CORCORAN',
			value: 'CORCORAN',
		},
		{
			label: 'KINGSBURG',
			value: 'KINGSBURG',
		},
		{
			label: 'HANFORD',
			value: 'HANFORD',
		},
		{
			label: 'KETTLEMAN CITY',
			value: 'KETTLEMAN CITY',
		},
		{
			label: 'LATON',
			value: 'LATON',
		},
	],
	LAKE: [
		{
			label: 'CLEARLAKE OAKS',
			value: 'CLEARLAKE OAKS',
		},
		{
			label: 'WITTER SPRINGS',
			value: 'WITTER SPRINGS',
		},
		{
			label: 'POTTER VALLEY',
			value: 'POTTER VALLEY',
		},
		{
			label: 'FINLEY',
			value: 'FINLEY',
		},
		{
			label: 'CLEARLAKE',
			value: 'CLEARLAKE',
		},
		{
			label: 'UPPER LAKE',
			value: 'UPPER LAKE',
		},
		{
			label: 'NICE',
			value: 'NICE',
		},
		{
			label: 'LUCERNE',
			value: 'LUCERNE',
		},
		{
			label: 'HIDDEN VALLEY LAKE',
			value: 'HIDDEN VALLEY LAKE',
		},
		{
			label: 'LAKEPORT',
			value: 'LAKEPORT',
		},
		{
			label: 'MIDDLETOWN',
			value: 'MIDDLETOWN',
		},
		{
			label: 'GLENHAVEN',
			value: 'GLENHAVEN',
		},
		{
			label: 'KELSEYVILLE',
			value: 'KELSEYVILLE',
		},
		{
			label: 'CLEARLAKE PARK',
			value: 'CLEARLAKE PARK',
		},
		{
			label: 'LOWER LAKE',
			value: 'LOWER LAKE',
		},
		{
			label: 'LOCH LOMOND',
			value: 'LOCH LOMOND',
		},
		{
			label: 'COBB',
			value: 'COBB',
		},
	],
	LASSEN: [
		{
			label: 'ADIN',
			value: 'ADIN',
		},
		{
			label: 'JANESVILLE',
			value: 'JANESVILLE',
		},
		{
			label: 'TERMO',
			value: 'TERMO',
		},
		{
			label: 'SPAULDING',
			value: 'SPAULDING',
		},
		{
			label: 'PATTON VILLAGE',
			value: 'PATTON VILLAGE',
		},
		{
			label: 'LITCHFIELD',
			value: 'LITCHFIELD',
		},
		{
			label: 'HERLONG',
			value: 'HERLONG',
		},
		{
			label: 'CLEAR CREEK',
			value: 'CLEAR CREEK',
		},
		{
			label: 'JOHNSTONVILLE',
			value: 'JOHNSTONVILLE',
		},
		{
			label: 'STONES LANDING',
			value: 'STONES LANDING',
		},
		{
			label: 'NUBIEBER',
			value: 'NUBIEBER',
		},
		{
			label: 'BIEBER',
			value: 'BIEBER',
		},
		{
			label: 'WESTWOOD',
			value: 'WESTWOOD',
		},
		{
			label: 'STANDISH',
			value: 'STANDISH',
		},
		{
			label: 'MCARTHUR',
			value: 'MCARTHUR',
		},
		{
			label: 'MILFORD',
			value: 'MILFORD',
		},
		{
			label: 'WENDEL',
			value: 'WENDEL',
		},
		{
			label: 'MADELINE',
			value: 'MADELINE',
		},
		{
			label: 'SUSANVILLE',
			value: 'SUSANVILLE',
		},
		{
			label: 'RAVENDALE',
			value: 'RAVENDALE',
		},
		{
			label: 'LITTLE VALLEY',
			value: 'LITTLE VALLEY',
		},
		{
			label: 'EAGLEVILLE',
			value: 'EAGLEVILLE',
		},
		{
			label: 'DOYLE',
			value: 'DOYLE',
		},
	],
	LOS_ANGELES: [
		{
			label: 'RESEDA',
			value: 'RESEDA',
		},
		{
			label: 'MONTEREY PARK',
			value: 'MONTEREY PARK',
		},
		{
			label: 'NORTHRIDGE',
			value: 'NORTHRIDGE',
		},
		{
			label: 'NORTH HOLLYWOOD',
			value: 'NORTH HOLLYWOOD',
		},
		{
			label: 'ROSEMEAD',
			value: 'ROSEMEAD',
		},
		{
			label: 'BRADBURY',
			value: 'BRADBURY',
		},
		{
			label: 'MOUNT WILSON',
			value: 'MOUNT WILSON',
		},
		{
			label: 'LA HABRA',
			value: 'LA HABRA',
		},
		{
			label: 'ROSAMOND',
			value: 'ROSAMOND',
		},
		{
			label: 'VENICE',
			value: 'VENICE',
		},
		{
			label: 'EAST RANCHO DOMINGUEZ',
			value: 'EAST RANCHO DOMINGUEZ',
		},
		{
			label: 'ACTON',
			value: 'ACTON',
		},
		{
			label: 'SANTA FE SPRINGS',
			value: 'SANTA FE SPRINGS',
		},
		{
			label: 'LA HABRA HEIGHTS',
			value: 'LA HABRA HEIGHTS',
		},
		{
			label: 'POMONA',
			value: 'POMONA',
		},
		{
			label: 'AGUA DULCE',
			value: 'AGUA DULCE',
		},
		{
			label: 'HIDDEN HILLS',
			value: 'HIDDEN HILLS',
		},
		{
			label: 'GLENDORA',
			value: 'GLENDORA',
		},
		{
			label: 'INGLEWOOD',
			value: 'INGLEWOOD',
		},
		{
			label: 'SHERWOOD FOREST',
			value: 'SHERWOOD FOREST',
		},
		{
			label: 'EL MONTE',
			value: 'EL MONTE',
		},
		{
			label: 'LAWNDALE',
			value: 'LAWNDALE',
		},
		{
			label: 'SANTA MONICA',
			value: 'SANTA MONICA',
		},
		{
			label: 'ARLETA',
			value: 'ARLETA',
		},
		{
			label: 'WEST WHITTIER-LOS NIETOS',
			value: 'WEST WHITTIER-LOS NIETOS',
		},
		{
			label: 'WOODLAND HILLS',
			value: 'WOODLAND HILLS',
		},
		{
			label: 'EL SEGUNDO',
			value: 'EL SEGUNDO',
		},
		{
			label: 'QUARTZ HILL',
			value: 'QUARTZ HILL',
		},
		{
			label: 'WALNUT',
			value: 'WALNUT',
		},
		{
			label: 'SUN VILLAGE',
			value: 'SUN VILLAGE',
		},
		{
			label: 'PASADENA',
			value: 'PASADENA',
		},
		{
			label: 'LA CAñADA FLINTRIDGE',
			value: 'LA CAñADA FLINTRIDGE',
		},
		{
			label: 'TARZANA',
			value: 'TARZANA',
		},
		{
			label: 'BURBANK',
			value: 'BURBANK',
		},
		{
			label: 'BELLFLOWER',
			value: 'BELLFLOWER',
		},
		{
			label: 'LAKE VIEW TER',
			value: 'LAKE VIEW TER',
		},
		{
			label: 'ELIZABETH LAKE',
			value: 'ELIZABETH LAKE',
		},
		{
			label: 'WINNETKA',
			value: 'WINNETKA',
		},
		{
			label: 'AGOURA',
			value: 'AGOURA',
		},
		{
			label: 'ENCINO',
			value: 'ENCINO',
		},
		{
			label: 'VALENCIA',
			value: 'VALENCIA',
		},
		{
			label: 'CHINO HILLS',
			value: 'CHINO HILLS',
		},
		{
			label: 'LA CRESCENTA',
			value: 'LA CRESCENTA',
		},
		{
			label: 'EAGLE ROCK',
			value: 'EAGLE ROCK',
		},
		{
			label: 'BELL',
			value: 'BELL',
		},
		{
			label: 'CORNELL',
			value: 'CORNELL',
		},
		{
			label: 'SANTA CLARITA',
			value: 'SANTA CLARITA',
		},
		{
			label: 'LA VERNE',
			value: 'LA VERNE',
		},
		{
			label: 'GLENDALE',
			value: 'GLENDALE',
		},
		{
			label: 'BALDWIN PARK',
			value: 'BALDWIN PARK',
		},
		{
			label: 'TORRANCE',
			value: 'TORRANCE',
		},
		{
			label: 'CANOGA PARK',
			value: 'CANOGA PARK',
		},
		{
			label: 'LOS NIETOS',
			value: 'LOS NIETOS',
		},
		{
			label: 'ROLLING HILLS',
			value: 'ROLLING HILLS',
		},
		{
			label: 'CARSON',
			value: 'CARSON',
		},
		{
			label: 'LAKE LOS ANGELES',
			value: 'LAKE LOS ANGELES',
		},
		{
			label: 'AZUSA',
			value: 'AZUSA',
		},
		{
			label: 'PORTER RANCH',
			value: 'PORTER RANCH',
		},
		{
			label: 'SAN DIMAS',
			value: 'SAN DIMAS',
		},
		{
			label: 'DEL SUR',
			value: 'DEL SUR',
		},
		{
			label: 'TUJUNGA',
			value: 'TUJUNGA',
		},
		{
			label: 'PANORAMA CITY',
			value: 'PANORAMA CITY',
		},
		{
			label: 'LA PALMA',
			value: 'LA PALMA',
		},
		{
			label: 'LA CANADA FLINTRIDGE',
			value: 'LA CANADA FLINTRIDGE',
		},
		{
			label: 'PHILLIPS RANCH',
			value: 'PHILLIPS RANCH',
		},
		{
			label: 'LADERA HEIGHTS',
			value: 'LADERA HEIGHTS',
		},
		{
			label: 'SAN GABRIEL',
			value: 'SAN GABRIEL',
		},
		{
			label: 'MAYWOOD',
			value: 'MAYWOOD',
		},
		{
			label: 'ELIZABETH LK',
			value: 'ELIZABETH LK',
		},
		{
			label: 'NORWALK',
			value: 'NORWALK',
		},
		{
			label: 'COMMERCE',
			value: 'COMMERCE',
		},
		{
			label: 'SAN FERNANDO',
			value: 'SAN FERNANDO',
		},
		{
			label: 'LAKE HUGHES',
			value: 'LAKE HUGHES',
		},
		{
			label: 'DUARTE',
			value: 'DUARTE',
		},
		{
			label: 'WRIGHTWOOD',
			value: 'WRIGHTWOOD',
		},
		{
			label: 'LA CRESCENTA-MONTROSE',
			value: 'LA CRESCENTA-MONTROSE',
		},
		{
			label: 'NEWHALL',
			value: 'NEWHALL',
		},
		{
			label: 'CLAREMONT',
			value: 'CLAREMONT',
		},
		{
			label: 'CUDAHY',
			value: 'CUDAHY',
		},
		{
			label: 'MT BALDY',
			value: 'MT BALDY',
		},
		{
			label: 'ROSEWOOD',
			value: 'ROSEWOOD',
		},
		{
			label: 'LEONA VALLEY',
			value: 'LEONA VALLEY',
		},
		{
			label: 'SHERMAN OAKS',
			value: 'SHERMAN OAKS',
		},
		{
			label: 'CHATSWORTH',
			value: 'CHATSWORTH',
		},
		{
			label: 'SIERRA MADRE',
			value: 'SIERRA MADRE',
		},
		{
			label: 'COMPTON',
			value: 'COMPTON',
		},
		{
			label: 'CASTAIC',
			value: 'CASTAIC',
		},
		{
			label: 'LA PUENTE',
			value: 'LA PUENTE',
		},
		{
			label: 'SOUTH GATE',
			value: 'SOUTH GATE',
		},
		{
			label: 'ROLLING HILLS ESTATES',
			value: 'ROLLING HILLS ESTATES',
		},
		{
			label: 'WESTCHESTER',
			value: 'WESTCHESTER',
		},
		{
			label: 'LAKE VIEW TERRACE',
			value: 'LAKE VIEW TERRACE',
		},
		{
			label: 'BEVERLY HILLS',
			value: 'BEVERLY HILLS',
		},
		{
			label: 'MALIBU',
			value: 'MALIBU',
		},
		{
			label: 'WEST HILLS',
			value: 'WEST HILLS',
		},
		{
			label: 'PLAYA DEL REY',
			value: 'PLAYA DEL REY',
		},
		{
			label: 'RANCHO PALOS VERDES',
			value: 'RANCHO PALOS VERDES',
		},
		{
			label: 'SUNLAND',
			value: 'SUNLAND',
		},
		{
			label: 'CERRITOS',
			value: 'CERRITOS',
		},
		{
			label: 'LEBEC',
			value: 'LEBEC',
		},
		{
			label: 'DIAMOND BAR',
			value: 'DIAMOND BAR',
		},
		{
			label: 'LANCASTER',
			value: 'LANCASTER',
		},
		{
			label: 'HACIENDA HEIGHTS',
			value: 'HACIENDA HEIGHTS',
		},
		{
			label: 'VAN NUYS',
			value: 'VAN NUYS',
		},
		{
			label: 'ARTESIA',
			value: 'ARTESIA',
		},
		{
			label: 'BELL GARDENS',
			value: 'BELL GARDENS',
		},
		{
			label: 'CALABASAS HILLS',
			value: 'CALABASAS HILLS',
		},
		{
			label: 'MANHATTAN BEACH',
			value: 'MANHATTAN BEACH',
		},
		{
			label: 'CALABASAS',
			value: 'CALABASAS',
		},
		{
			label: 'PEARBLOSSOM',
			value: 'PEARBLOSSOM',
		},
		{
			label: 'WEST COVINA',
			value: 'WEST COVINA',
		},
		{
			label: 'MONTROSE',
			value: 'MONTROSE',
		},
		{
			label: 'RANCHO DOMINGUEZ',
			value: 'RANCHO DOMINGUEZ',
		},
		{
			label: 'HARBOR CITY',
			value: 'HARBOR CITY',
		},
		{
			label: 'BASSETT',
			value: 'BASSETT',
		},
		{
			label: 'CULVER CITY',
			value: 'CULVER CITY',
		},
		{
			label: 'PLAYA VISTA',
			value: 'PLAYA VISTA',
		},
		{
			label: 'SAN MARINO',
			value: 'SAN MARINO',
		},
		{
			label: 'PALOS VERDES PENINSULA',
			value: 'PALOS VERDES PENINSULA',
		},
		{
			label: 'HI VISTA',
			value: 'HI VISTA',
		},
		{
			label: 'DOWNEY',
			value: 'DOWNEY',
		},
		{
			label: 'SUN VALLEY',
			value: 'SUN VALLEY',
		},
		{
			label: 'EAST LOS ANGELES',
			value: 'EAST LOS ANGELES',
		},
		{
			label: 'WILMINGTON',
			value: 'WILMINGTON',
		},
		{
			label: 'LOMITA',
			value: 'LOMITA',
		},
		{
			label: 'HASLEY CANYON',
			value: 'HASLEY CANYON',
		},
		{
			label: 'PALMDALE',
			value: 'PALMDALE',
		},
		{
			label: 'HAWAIIAN GARDENS',
			value: 'HAWAIIAN GARDENS',
		},
		{
			label: 'FAIRMONT',
			value: 'FAIRMONT',
		},
		{
			label: 'VERNON',
			value: 'VERNON',
		},
		{
			label: 'SAUGUS',
			value: 'SAUGUS',
		},
		{
			label: 'KAGEL CANYON',
			value: 'KAGEL CANYON',
		},
		{
			label: 'WESTLAKE VILLAGE',
			value: 'WESTLAKE VILLAGE',
		},
		{
			label: 'VALYERMO',
			value: 'VALYERMO',
		},
		{
			label: 'ALTADENA',
			value: 'ALTADENA',
		},
		{
			label: 'MONROVIA',
			value: 'MONROVIA',
		},
		{
			label: 'SIGNAL HILL',
			value: 'SIGNAL HILL',
		},
		{
			label: 'ALHAMBRA',
			value: 'ALHAMBRA',
		},
		{
			label: 'LAKEWOOD',
			value: 'LAKEWOOD',
		},
		{
			label: 'WHITTIER',
			value: 'WHITTIER',
		},
		{
			label: 'WEST CARSON',
			value: 'WEST CARSON',
		},
		{
			label: 'CYPRESS',
			value: 'CYPRESS',
		},
		{
			label: 'MISSION HILLS',
			value: 'MISSION HILLS',
		},
		{
			label: 'AGOURA HILLS',
			value: 'AGOURA HILLS',
		},
		{
			label: 'UNIVERSAL CITY',
			value: 'UNIVERSAL CITY',
		},
		{
			label: 'COVINA',
			value: 'COVINA',
		},
		{
			label: 'AVALON',
			value: 'AVALON',
		},
		{
			label: 'HUNTINGTON PARK',
			value: 'HUNTINGTON PARK',
		},
		{
			label: 'ARCADIA',
			value: 'ARCADIA',
		},
		{
			label: 'HOLLYWOOD',
			value: 'HOLLYWOOD',
		},
		{
			label: 'WEST HOLLYWOOD',
			value: 'WEST HOLLYWOOD',
		},
		{
			label: 'SAN PEDRO',
			value: 'SAN PEDRO',
		},
		{
			label: 'TEMPLE CITY',
			value: 'TEMPLE CITY',
		},
		{
			label: 'SOUTH EL MONTE',
			value: 'SOUTH EL MONTE',
		},
		{
			label: 'MONTE NIDO',
			value: 'MONTE NIDO',
		},
		{
			label: 'CITY OF INDUSTRY',
			value: 'CITY OF INDUSTRY',
		},
		{
			label: 'VAL VERDE',
			value: 'VAL VERDE',
		},
		{
			label: 'PALOS VERDES ESTATES',
			value: 'PALOS VERDES ESTATES',
		},
		{
			label: 'HERMOSA BEACH',
			value: 'HERMOSA BEACH',
		},
		{
			label: 'LA MIRADA',
			value: 'LA MIRADA',
		},
		{
			label: 'STEVENSON RANCH',
			value: 'STEVENSON RANCH',
		},
		{
			label: 'HAWTHORNE',
			value: 'HAWTHORNE',
		},
		{
			label: 'VIEW PARK',
			value: 'VIEW PARK',
		},
		{
			label: 'SOUTH PASADENA',
			value: 'SOUTH PASADENA',
		},
		{
			label: 'LOS ANGELES',
			value: 'LOS ANGELES',
		},
		{
			label: 'MARINA DEL REY',
			value: 'MARINA DEL REY',
		},
		{
			label: 'GORMAN',
			value: 'GORMAN',
		},
		{
			label: 'ROWLAND HEIGHTS',
			value: 'ROWLAND HEIGHTS',
		},
		{
			label: 'VALLEY VILLAGE',
			value: 'VALLEY VILLAGE',
		},
		{
			label: 'GREEN VALLEY',
			value: 'GREEN VALLEY',
		},
		{
			label: 'GARDENA',
			value: 'GARDENA',
		},
		{
			label: 'TOLUCA LAKE',
			value: 'TOLUCA LAKE',
		},
		{
			label: 'PACIFIC PALISADES',
			value: 'PACIFIC PALISADES',
		},
		{
			label: 'REDONDO BEACH',
			value: 'REDONDO BEACH',
		},
		{
			label: 'CANYON COUNTRY',
			value: 'CANYON COUNTRY',
		},
		{
			label: 'PALOS VERDES',
			value: 'PALOS VERDES',
		},
		{
			label: 'LYNWOOD',
			value: 'LYNWOOD',
		},
		{
			label: 'PACOIMA',
			value: 'PACOIMA',
		},
		{
			label: 'SIMI VALLEY',
			value: 'SIMI VALLEY',
		},
		{
			label: 'STUDIO CITY',
			value: 'STUDIO CITY',
		},
		{
			label: 'LITTLEROCK',
			value: 'LITTLEROCK',
		},
		{
			label: 'PICO RIVERA',
			value: 'PICO RIVERA',
		},
		{
			label: 'JUNIPER HILLS',
			value: 'JUNIPER HILLS',
		},
		{
			label: 'BOX CANYON',
			value: 'BOX CANYON',
		},
		{
			label: 'IRWINDALE',
			value: 'IRWINDALE',
		},
		{
			label: 'LLANO',
			value: 'LLANO',
		},
		{
			label: 'LAKE BALBOA',
			value: 'LAKE BALBOA',
		},
		{
			label: 'NORTH HILLS',
			value: 'NORTH HILLS',
		},
		{
			label: 'GRANADA HILLS',
			value: 'GRANADA HILLS',
		},
		{
			label: 'SOUTH ANTELOPE VALLEY',
			value: 'SOUTH ANTELOPE VALLEY',
		},
		{
			label: 'LONG BEACH',
			value: 'LONG BEACH',
		},
		{
			label: 'TOPANGA',
			value: 'TOPANGA',
		},
		{
			label: 'WINDSOR HILLS',
			value: 'WINDSOR HILLS',
		},
		{
			label: 'THOUSAND OAKS',
			value: 'THOUSAND OAKS',
		},
		{
			label: 'WALNUT PARK',
			value: 'WALNUT PARK',
		},
		{
			label: 'PARAMOUNT',
			value: 'PARAMOUNT',
		},
		{
			label: 'MONTEBELLO',
			value: 'MONTEBELLO',
		},
		{
			label: 'SHADOW HILLS',
			value: 'SHADOW HILLS',
		},
		{
			label: 'SYLMAR',
			value: 'SYLMAR',
		},
	],
	MADERA: [
		{
			label: 'COARSEGOLD',
			value: 'COARSEGOLD',
		},
		{
			label: 'MADERA',
			value: 'MADERA',
		},
		{
			label: 'AUBERRY',
			value: 'AUBERRY',
		},
		{
			label: 'AHWAHNEE',
			value: 'AHWAHNEE',
		},
		{
			label: 'OAKHURST',
			value: 'OAKHURST',
		},
		{
			label: 'YOSEMITE LAKES',
			value: 'YOSEMITE LAKES',
		},
		{
			label: 'FRIANT',
			value: 'FRIANT',
		},
		{
			label: 'O NEALS',
			value: 'O NEALS',
		},
		{
			label: 'RAYMOND',
			value: 'RAYMOND',
		},
		{
			label: 'CHOWCHILLA',
			value: 'CHOWCHILLA',
		},
		{
			label: 'FAIRMEAD',
			value: 'FAIRMEAD',
		},
		{
			label: 'WISHON',
			value: 'WISHON',
		},
		{
			label: 'MADERA ACRES',
			value: 'MADERA ACRES',
		},
		{
			label: 'BASS LAKE',
			value: 'BASS LAKE',
		},
		{
			label: 'NORTH FORK',
			value: 'NORTH FORK',
		},
		{
			label: 'FRESNO',
			value: 'FRESNO',
		},
		{
			label: 'FIREBAUGH',
			value: 'FIREBAUGH',
		},
	],
	MARIN: [
		{
			label: 'PETALUMA',
			value: 'PETALUMA',
		},
		{
			label: 'BELVEDERE TIBURON',
			value: 'BELVEDERE TIBURON',
		},
		{
			label: 'STRAWBERRY',
			value: 'STRAWBERRY',
		},
		{
			label: 'MARSHALL',
			value: 'MARSHALL',
		},
		{
			label: 'FAIRFAX',
			value: 'FAIRFAX',
		},
		{
			label: 'FOREST KNOLLS',
			value: 'FOREST KNOLLS',
		},
		{
			label: 'POINT REYES STATION',
			value: 'POINT REYES STATION',
		},
		{
			label: 'SANTA VENETIA',
			value: 'SANTA VENETIA',
		},
		{
			label: 'TAMALPAIS-HOMESTEAD VALLEY',
			value: 'TAMALPAIS-HOMESTEAD VALLEY',
		},
		{
			label: 'WOODACRE',
			value: 'WOODACRE',
		},
		{
			label: 'INVERNESS',
			value: 'INVERNESS',
		},
		{
			label: 'LARKSPUR',
			value: 'LARKSPUR',
		},
		{
			label: 'SAN GERONIMO',
			value: 'SAN GERONIMO',
		},
		{
			label: 'SAN ANSELMO',
			value: 'SAN ANSELMO',
		},
		{
			label: 'MILL VALLEY',
			value: 'MILL VALLEY',
		},
		{
			label: 'LUCAS VALLEY-MARINWOOD',
			value: 'LUCAS VALLEY-MARINWOOD',
		},
		{
			label: 'NICASIO',
			value: 'NICASIO',
		},
		{
			label: 'LAGUNITAS',
			value: 'LAGUNITAS',
		},
		{
			label: 'SAN QUENTIN',
			value: 'SAN QUENTIN',
		},
		{
			label: 'SLEEPY HOLLOW',
			value: 'SLEEPY HOLLOW',
		},
		{
			label: 'TOMALES',
			value: 'TOMALES',
		},
		{
			label: 'TIBURON',
			value: 'TIBURON',
		},
		{
			label: 'LAGUNITAS-FOREST KNOLLS',
			value: 'LAGUNITAS-FOREST KNOLLS',
		},
		{
			label: 'GREENBRAE',
			value: 'GREENBRAE',
		},
		{
			label: 'NOVATO',
			value: 'NOVATO',
		},
		{
			label: 'DILLON BEACH',
			value: 'DILLON BEACH',
		},
		{
			label: 'CORTE MADERA',
			value: 'CORTE MADERA',
		},
		{
			label: 'STINSON BEACH',
			value: 'STINSON BEACH',
		},
		{
			label: 'OLEMA',
			value: 'OLEMA',
		},
		{
			label: 'KENTFIELD',
			value: 'KENTFIELD',
		},
		{
			label: 'SAN RAFAEL',
			value: 'SAN RAFAEL',
		},
		{
			label: 'MARIN CITY',
			value: 'MARIN CITY',
		},
		{
			label: 'ALTO',
			value: 'ALTO',
		},
		{
			label: 'BLACK POINT-GREEN POINT',
			value: 'BLACK POINT-GREEN POINT',
		},
		{
			label: 'BOLINAS',
			value: 'BOLINAS',
		},
		{
			label: 'SAUSALITO',
			value: 'SAUSALITO',
		},
		{
			label: 'BELVEDERE',
			value: 'BELVEDERE',
		},
		{
			label: 'ROSS',
			value: 'ROSS',
		},
		{
			label: 'MUIR BEACH',
			value: 'MUIR BEACH',
		},
	],
	MARIPOSA: [
		{
			label: 'MARIPOSA',
			value: 'MARIPOSA',
		},
		{
			label: 'AHWAHNEE',
			value: 'AHWAHNEE',
		},
		{
			label: 'GROVELAND',
			value: 'GROVELAND',
		},
		{
			label: 'HORNITOS',
			value: 'HORNITOS',
		},
		{
			label: 'EL PORTAL',
			value: 'EL PORTAL',
		},
		{
			label: 'RAYMOND',
			value: 'RAYMOND',
		},
		{
			label: 'YOSEMITE',
			value: 'YOSEMITE',
		},
		{
			label: 'WAWONA',
			value: 'WAWONA',
		},
		{
			label: 'LE GRAND',
			value: 'LE GRAND',
		},
		{
			label: 'FISH CAMP',
			value: 'FISH CAMP',
		},
		{
			label: 'COULTERVILLE',
			value: 'COULTERVILLE',
		},
		{
			label: 'YOSEMITE NATIONAL PARK',
			value: 'YOSEMITE NATIONAL PARK',
		},
		{
			label: 'CATHEYS VALLEY',
			value: 'CATHEYS VALLEY',
		},
		{
			label: 'SNELLING',
			value: 'SNELLING',
		},
		{
			label: 'MIDPINES',
			value: 'MIDPINES',
		},
		{
			label: 'LA GRANGE',
			value: 'LA GRANGE',
		},
	],
	MENDOCINO: [
		{
			label: 'DOS RIOS',
			value: 'DOS RIOS',
		},
		{
			label: 'LAYTONVILLE',
			value: 'LAYTONVILLE',
		},
		{
			label: 'COVELO',
			value: 'COVELO',
		},
		{
			label: 'BROOKTRAILS',
			value: 'BROOKTRAILS',
		},
		{
			label: 'POTTER VALLEY',
			value: 'POTTER VALLEY',
		},
		{
			label: 'CASPAR',
			value: 'CASPAR',
		},
		{
			label: 'BOONVILLE',
			value: 'BOONVILLE',
		},
		{
			label: 'CALPELLA',
			value: 'CALPELLA',
		},
		{
			label: 'FORT BRAGG',
			value: 'FORT BRAGG',
		},
		{
			label: 'CLOVERDALE',
			value: 'CLOVERDALE',
		},
		{
			label: 'YORKVILLE',
			value: 'YORKVILLE',
		},
		{
			label: 'BRANSCOMB',
			value: 'BRANSCOMB',
		},
		{
			label: 'COMPTCHE',
			value: 'COMPTCHE',
		},
		{
			label: 'HOPLAND',
			value: 'HOPLAND',
		},
		{
			label: 'WILLITS',
			value: 'WILLITS',
		},
		{
			label: 'WHITETHORN',
			value: 'WHITETHORN',
		},
		{
			label: 'MENDOCINO',
			value: 'MENDOCINO',
		},
		{
			label: 'UKIAH',
			value: 'UKIAH',
		},
		{
			label: 'ELK',
			value: 'ELK',
		},
		{
			label: 'LITTLE RIVER',
			value: 'LITTLE RIVER',
		},
		{
			label: 'TALMAGE',
			value: 'TALMAGE',
		},
		{
			label: 'PHILO',
			value: 'PHILO',
		},
		{
			label: 'MANCHESTER',
			value: 'MANCHESTER',
		},
		{
			label: 'LITTLERIVER',
			value: 'LITTLERIVER',
		},
		{
			label: 'POINT ARENA',
			value: 'POINT ARENA',
		},
		{
			label: 'REDWOOD VALLEY',
			value: 'REDWOOD VALLEY',
		},
		{
			label: 'LEGGETT',
			value: 'LEGGETT',
		},
		{
			label: 'PIERCY',
			value: 'PIERCY',
		},
		{
			label: 'ALBION',
			value: 'ALBION',
		},
		{
			label: 'WESTPORT',
			value: 'WESTPORT',
		},
		{
			label: 'NAVARRO',
			value: 'NAVARRO',
		},
		{
			label: 'LAYTONVILLE-LEGGETT',
			value: 'LAYTONVILLE-LEGGETT',
		},
		{
			label: 'GUALALA',
			value: 'GUALALA',
		},
		{
			label: 'GARBERVILLE',
			value: 'GARBERVILLE',
		},
	],
	MERCED: [
		{
			label: 'LE GRAND',
			value: 'LE GRAND',
		},
		{
			label: 'HILMAR',
			value: 'HILMAR',
		},
		{
			label: 'NEWMAN',
			value: 'NEWMAN',
		},
		{
			label: 'SNELLING',
			value: 'SNELLING',
		},
		{
			label: 'LIVINGSTON',
			value: 'LIVINGSTON',
		},
		{
			label: 'MERCED',
			value: 'MERCED',
		},
		{
			label: 'TURLOCK',
			value: 'TURLOCK',
		},
		{
			label: 'DENAIR',
			value: 'DENAIR',
		},
		{
			label: 'STEVINSON',
			value: 'STEVINSON',
		},
		{
			label: 'SOUTH DOS PALOS',
			value: 'SOUTH DOS PALOS',
		},
		{
			label: 'ATWATER',
			value: 'ATWATER',
		},
		{
			label: 'SANTA NELLA',
			value: 'SANTA NELLA',
		},
		{
			label: 'PLANADA',
			value: 'PLANADA',
		},
		{
			label: 'MCSWAIN',
			value: 'MCSWAIN',
		},
		{
			label: 'CRESSEY',
			value: 'CRESSEY',
		},
		{
			label: 'FRANKLIN',
			value: 'FRANKLIN',
		},
		{
			label: 'LOS BANOS',
			value: 'LOS BANOS',
		},
		{
			label: 'DELHI',
			value: 'DELHI',
		},
		{
			label: 'GUSTINE',
			value: 'GUSTINE',
		},
		{
			label: 'HILMAR-IRWIN',
			value: 'HILMAR-IRWIN',
		},
		{
			label: 'CHOWCHILLA',
			value: 'CHOWCHILLA',
		},
		{
			label: 'BALLICO',
			value: 'BALLICO',
		},
		{
			label: 'DOS PALOS',
			value: 'DOS PALOS',
		},
		{
			label: 'EL NIDO',
			value: 'EL NIDO',
		},
		{
			label: 'WINTON',
			value: 'WINTON',
		},
	],
	MODOC: [
		{
			label: 'CANBY',
			value: 'CANBY',
		},
		{
			label: 'NEWELL',
			value: 'NEWELL',
		},
		{
			label: 'EAGLEVILLE',
			value: 'EAGLEVILLE',
		},
		{
			label: 'CALIFORNIA PINES',
			value: 'CALIFORNIA PINES',
		},
		{
			label: 'ADIN',
			value: 'ADIN',
		},
		{
			label: 'ALTURAS',
			value: 'ALTURAS',
		},
		{
			label: 'LIKELY',
			value: 'LIKELY',
		},
		{
			label: 'NEW PINE CREEK',
			value: 'NEW PINE CREEK',
		},
		{
			label: 'TIONESTA',
			value: 'TIONESTA',
		},
		{
			label: 'TULELAKE',
			value: 'TULELAKE',
		},
		{
			label: 'DAVIS CREEK',
			value: 'DAVIS CREEK',
		},
		{
			label: 'WILLOW RANCH',
			value: 'WILLOW RANCH',
		},
		{
			label: 'DAPHNEDALE PARK',
			value: 'DAPHNEDALE PARK',
		},
		{
			label: 'FORT BIDWELL',
			value: 'FORT BIDWELL',
		},
		{
			label: 'CEDARVILLE',
			value: 'CEDARVILLE',
		},
		{
			label: 'LOOKOUT',
			value: 'LOOKOUT',
		},
		{
			label: 'LAKE CITY',
			value: 'LAKE CITY',
		},
		{
			label: 'MCARTHUR',
			value: 'MCARTHUR',
		},
	],
	MONO: [
		{
			label: 'BENTON',
			value: 'BENTON',
		},
		{
			label: 'COLEVILLE',
			value: 'COLEVILLE',
		},
		{
			label: 'CROWLEY LAKE',
			value: 'CROWLEY LAKE',
		},
		{
			label: 'BISHOP',
			value: 'BISHOP',
		},
		{
			label: 'HAMMIL VALLEY',
			value: 'HAMMIL VALLEY',
		},
		{
			label: 'TOPAZ',
			value: 'TOPAZ',
		},
		{
			label: 'CHALFANT',
			value: 'CHALFANT',
		},
		{
			label: 'JUNE LAKE',
			value: 'JUNE LAKE',
		},
		{
			label: 'SWALL MEADOWS',
			value: 'SWALL MEADOWS',
		},
		{
			label: 'TOMS PLACE',
			value: 'TOMS PLACE',
		},
		{
			label: 'LEE VINING',
			value: 'LEE VINING',
		},
		{
			label: 'MONO CITY',
			value: 'MONO CITY',
		},
		{
			label: 'BRIDGEPORT',
			value: 'BRIDGEPORT',
		},
		{
			label: 'MAMMOTH LAKES',
			value: 'MAMMOTH LAKES',
		},
	],
	MONTEREY: [
		{
			label: 'CARMEL VALLEY',
			value: 'CARMEL VALLEY',
		},
		{
			label: 'DEL MONTE FOREST',
			value: 'DEL MONTE FOREST',
		},
		{
			label: 'MOSS LANDING',
			value: 'MOSS LANDING',
		},
		{
			label: 'ROYAL OAKS',
			value: 'ROYAL OAKS',
		},
		{
			label: 'GONZALES',
			value: 'GONZALES',
		},
		{
			label: 'MARINA',
			value: 'MARINA',
		},
		{
			label: 'LAS LOMAS',
			value: 'LAS LOMAS',
		},
		{
			label: 'CARMICHAEL',
			value: 'CARMICHAEL',
		},
		{
			label: 'SALINAS',
			value: 'SALINAS',
		},
		{
			label: 'COALINGA',
			value: 'COALINGA',
		},
		{
			label: 'DEL REY OAKS',
			value: 'DEL REY OAKS',
		},
		{
			label: 'LOCKWOOD',
			value: 'LOCKWOOD',
		},
		{
			label: 'BIG SUR',
			value: 'BIG SUR',
		},
		{
			label: 'CHUALAR',
			value: 'CHUALAR',
		},
		{
			label: 'SAN MIGUEL',
			value: 'SAN MIGUEL',
		},
		{
			label: 'CARMEL',
			value: 'CARMEL',
		},
		{
			label: 'BRADLEY',
			value: 'BRADLEY',
		},
		{
			label: 'CARMEL VALLEY VILLAGE',
			value: 'CARMEL VALLEY VILLAGE',
		},
		{
			label: 'CARMEL-BY-THE-SEA',
			value: 'CARMEL-BY-THE-SEA',
		},
		{
			label: 'PEBBLE BEACH',
			value: 'PEBBLE BEACH',
		},
		{
			label: 'PRUNEDALE',
			value: 'PRUNEDALE',
		},
		{
			label: 'CARMEL BY THE SEA',
			value: 'CARMEL BY THE SEA',
		},
		{
			label: 'SAND CITY',
			value: 'SAND CITY',
		},
		{
			label: 'WATSONVILLE',
			value: 'WATSONVILLE',
		},
		{
			label: 'MONTEREY',
			value: 'MONTEREY',
		},
		{
			label: 'GREENFIELD',
			value: 'GREENFIELD',
		},
		{
			label: 'SAN ARDO',
			value: 'SAN ARDO',
		},
		{
			label: 'CASTROVILLE',
			value: 'CASTROVILLE',
		},
		{
			label: 'EAST GARRISON',
			value: 'EAST GARRISON',
		},
		{
			label: 'JOLON',
			value: 'JOLON',
		},
		{
			label: 'PACIFIC GROVE',
			value: 'PACIFIC GROVE',
		},
		{
			label: 'PARKFIELD',
			value: 'PARKFIELD',
		},
		{
			label: 'SAN LUCAS',
			value: 'SAN LUCAS',
		},
		{
			label: 'AROMAS',
			value: 'AROMAS',
		},
		{
			label: 'SPRECKELS',
			value: 'SPRECKELS',
		},
		{
			label: 'KING CITY',
			value: 'KING CITY',
		},
		{
			label: 'SOLEDAD',
			value: 'SOLEDAD',
		},
		{
			label: 'SEASIDE',
			value: 'SEASIDE',
		},
	],
	NAPA: [
		{
			label: 'CALISTOGA',
			value: 'CALISTOGA',
		},
		{
			label: 'ANGWIN',
			value: 'ANGWIN',
		},
		{
			label: 'SAINT HELENA',
			value: 'SAINT HELENA',
		},
		{
			label: 'VALLEJO',
			value: 'VALLEJO',
		},
		{
			label: 'DEER PARK',
			value: 'DEER PARK',
		},
		{
			label: 'NAPA',
			value: 'NAPA',
		},
		{
			label: 'FAIRFIELD',
			value: 'FAIRFIELD',
		},
		{
			label: 'SONOMA',
			value: 'SONOMA',
		},
		{
			label: 'AMERICAN CANYON',
			value: 'AMERICAN CANYON',
		},
		{
			label: 'GLEN ELLEN',
			value: 'GLEN ELLEN',
		},
		{
			label: 'MIDDLETOWN',
			value: 'MIDDLETOWN',
		},
		{
			label: 'SILVERADO RESORT',
			value: 'SILVERADO RESORT',
		},
		{
			label: 'POPE VALLEY',
			value: 'POPE VALLEY',
		},
		{
			label: 'YOUNTVILLE',
			value: 'YOUNTVILLE',
		},
	],
	NEVADA: [
		{
			label: 'SODA SPRINGS',
			value: 'SODA SPRINGS',
		},
		{
			label: 'ROUGH AND READY',
			value: 'ROUGH AND READY',
		},
		{
			label: 'GRASS VALLEY',
			value: 'GRASS VALLEY',
		},
		{
			label: 'PENN VALLEY',
			value: 'PENN VALLEY',
		},
		{
			label: 'TRUCKEE',
			value: 'TRUCKEE',
		},
		{
			label: 'WASHINGTON',
			value: 'WASHINGTON',
		},
		{
			label: 'FLORISTON',
			value: 'FLORISTON',
		},
		{
			label: 'NORTH SAN JUAN',
			value: 'NORTH SAN JUAN',
		},
		{
			label: 'SMARTSVILLE',
			value: 'SMARTSVILLE',
		},
		{
			label: 'BIG OAK VALLEY',
			value: 'BIG OAK VALLEY',
		},
		{
			label: 'AUBURN',
			value: 'AUBURN',
		},
		{
			label: 'NEVADA CITY',
			value: 'NEVADA CITY',
		},
	],
	ORANGE: [
		{
			label: 'DOVE CANYON',
			value: 'DOVE CANYON',
		},
		{
			label: 'HUNTINGTON BEACH',
			value: 'HUNTINGTON BEACH',
		},
		{
			label: 'LADERA RANCH',
			value: 'LADERA RANCH',
		},
		{
			label: 'LONG BEACH',
			value: 'LONG BEACH',
		},
		{
			label: 'YORBA LINDA',
			value: 'YORBA LINDA',
		},
		{
			label: 'SURFSIDE',
			value: 'SURFSIDE',
		},
		{
			label: 'SAN JUAN CAPISTRANO',
			value: 'SAN JUAN CAPISTRANO',
		},
		{
			label: 'FOUNTAIN VALLEY',
			value: 'FOUNTAIN VALLEY',
		},
		{
			label: 'LAKE FOREST',
			value: 'LAKE FOREST',
		},
		{
			label: 'SILVERADO',
			value: 'SILVERADO',
		},
		{
			label: 'WESTMINSTER',
			value: 'WESTMINSTER',
		},
		{
			label: 'MIDWAY CITY',
			value: 'MIDWAY CITY',
		},
		{
			label: 'NEWPORT COAST',
			value: 'NEWPORT COAST',
		},
		{
			label: 'SANTA ANA',
			value: 'SANTA ANA',
		},
		{
			label: 'LAGUNA BEACH',
			value: 'LAGUNA BEACH',
		},
		{
			label: 'LOS ALAMITOS',
			value: 'LOS ALAMITOS',
		},
		{
			label: 'CYPRESS',
			value: 'CYPRESS',
		},
		{
			label: 'MISSION VIEJO',
			value: 'MISSION VIEJO',
		},
		{
			label: 'LAGUNA NIGUEL',
			value: 'LAGUNA NIGUEL',
		},
		{
			label: 'COTO DE CAZA',
			value: 'COTO DE CAZA',
		},
		{
			label: 'LAGUNA WOODS',
			value: 'LAGUNA WOODS',
		},
		{
			label: 'FOOTHILL RANCH',
			value: 'FOOTHILL RANCH',
		},
		{
			label: 'HUNTINGTN BCH',
			value: 'HUNTINGTN BCH',
		},
		{
			label: 'ORANGE',
			value: 'ORANGE',
		},
		{
			label: 'NEWPORT BEACH',
			value: 'NEWPORT BEACH',
		},
		{
			label: 'SUNSET BEACH',
			value: 'SUNSET BEACH',
		},
		{
			label: 'LA HABRA',
			value: 'LA HABRA',
		},
		{
			label: 'VILLA PARK',
			value: 'VILLA PARK',
		},
		{
			label: 'TUSTIN',
			value: 'TUSTIN',
		},
		{
			label: 'IRVINE',
			value: 'IRVINE',
		},
		{
			label: 'MODJESKA',
			value: 'MODJESKA',
		},
		{
			label: 'DANA POINT',
			value: 'DANA POINT',
		},
		{
			label: 'STANTON',
			value: 'STANTON',
		},
		{
			label: 'GARDEN GROVE',
			value: 'GARDEN GROVE',
		},
		{
			label: 'LA HABRA HEIGHTS',
			value: 'LA HABRA HEIGHTS',
		},
		{
			label: 'SOUTH COAST',
			value: 'SOUTH COAST',
		},
		{
			label: 'ANAHEIM',
			value: 'ANAHEIM',
		},
		{
			label: 'LA MIRADA',
			value: 'LA MIRADA',
		},
		{
			label: 'TRABUCO CANYON',
			value: 'TRABUCO CANYON',
		},
		{
			label: 'FULLERTON',
			value: 'FULLERTON',
		},
		{
			label: 'SAN CLEMENTE',
			value: 'SAN CLEMENTE',
		},
		{
			label: 'LA PALMA',
			value: 'LA PALMA',
		},
		{
			label: 'BUENA PARK',
			value: 'BUENA PARK',
		},
		{
			label: 'BREA',
			value: 'BREA',
		},
		{
			label: 'CAPISTRANO BEACH',
			value: 'CAPISTRANO BEACH',
		},
		{
			label: 'PLACENTIA',
			value: 'PLACENTIA',
		},
		{
			label: 'ALISO VIEJO',
			value: 'ALISO VIEJO',
		},
		{
			label: 'RANCHO SANTA MARGARITA',
			value: 'RANCHO SANTA MARGARITA',
		},
		{
			label: 'RANCHO MISSION VIEJO',
			value: 'RANCHO MISSION VIEJO',
		},
		{
			label: 'SEAL BEACH',
			value: 'SEAL BEACH',
		},
		{
			label: 'COSTA MESA',
			value: 'COSTA MESA',
		},
		{
			label: 'CORONA DEL MAR',
			value: 'CORONA DEL MAR',
		},
		{
			label: 'LAGUNA HILLS',
			value: 'LAGUNA HILLS',
		},
	],
	PLACER: [
		{
			label: 'NORTH AUBURN',
			value: 'NORTH AUBURN',
		},
		{
			label: 'DUTCH FLAT',
			value: 'DUTCH FLAT',
		},
		{
			label: 'WEIMAR',
			value: 'WEIMAR',
		},
		{
			label: 'CARNELIAN BAY',
			value: 'CARNELIAN BAY',
		},
		{
			label: 'GRANITE BAY',
			value: 'GRANITE BAY',
		},
		{
			label: 'MEADOW VISTA',
			value: 'MEADOW VISTA',
		},
		{
			label: 'ROCKLIN',
			value: 'ROCKLIN',
		},
		{
			label: 'AUBURN',
			value: 'AUBURN',
		},
		{
			label: 'NEWCASTLE',
			value: 'NEWCASTLE',
		},
		{
			label: 'GOLD RUN',
			value: 'GOLD RUN',
		},
		{
			label: 'ALTA',
			value: 'ALTA',
		},
		{
			label: 'OLYMPIC VALLEY',
			value: 'OLYMPIC VALLEY',
		},
		{
			label: 'EMIGRANT GAP',
			value: 'EMIGRANT GAP',
		},
		{
			label: 'IOWA HILL',
			value: 'IOWA HILL',
		},
		{
			label: 'HOMEWOOD',
			value: 'HOMEWOOD',
		},
		{
			label: 'TAHOE CITY',
			value: 'TAHOE CITY',
		},
		{
			label: 'ALPINE MEADOWS',
			value: 'ALPINE MEADOWS',
		},
		{
			label: 'SODA SPRINGS',
			value: 'SODA SPRINGS',
		},
		{
			label: 'LOOMIS',
			value: 'LOOMIS',
		},
		{
			label: 'TAHOE VISTA',
			value: 'TAHOE VISTA',
		},
		{
			label: 'SHERIDAN',
			value: 'SHERIDAN',
		},
		{
			label: 'ROSEVILLE',
			value: 'ROSEVILLE',
		},
		{
			label: 'LINCOLN',
			value: 'LINCOLN',
		},
		{
			label: 'PENRYN',
			value: 'PENRYN',
		},
		{
			label: 'COLFAX',
			value: 'COLFAX',
		},
		{
			label: 'FORESTHILL',
			value: 'FORESTHILL',
		},
		{
			label: 'ELVERTA',
			value: 'ELVERTA',
		},
		{
			label: 'PLEASANT GROVE',
			value: 'PLEASANT GROVE',
		},
		{
			label: 'TRUCKEE',
			value: 'TRUCKEE',
		},
		{
			label: 'KINGS BEACH',
			value: 'KINGS BEACH',
		},
		{
			label: 'APPLEGATE',
			value: 'APPLEGATE',
		},
		{
			label: 'LAKE TAHOE',
			value: 'LAKE TAHOE',
		},
		{
			label: 'TAHOMA',
			value: 'TAHOMA',
		},
		{
			label: 'NORDEN',
			value: 'NORDEN',
		},
	],
	PLUMAS: [
		{
			label: 'GREENVILLE',
			value: 'GREENVILLE',
		},
		{
			label: 'PLUMAS EUREKA',
			value: 'PLUMAS EUREKA',
		},
		{
			label: 'BLAIRSDEN GRAEAGLE',
			value: 'BLAIRSDEN GRAEAGLE',
		},
		{
			label: 'GRAEAGLE',
			value: 'GRAEAGLE',
		},
		{
			label: 'LAKE ALMANOR',
			value: 'LAKE ALMANOR',
		},
		{
			label: 'LAKE ALMANOR PENINSULA',
			value: 'LAKE ALMANOR PENINSULA',
		},
		{
			label: 'STRAWBERRY VALLEY',
			value: 'STRAWBERRY VALLEY',
		},
		{
			label: 'VINTON',
			value: 'VINTON',
		},
		{
			label: 'BLAIRSDEN',
			value: 'BLAIRSDEN',
		},
		{
			label: 'MABIE',
			value: 'MABIE',
		},
		{
			label: 'EAST QUINCY',
			value: 'EAST QUINCY',
		},
		{
			label: 'CHESTER',
			value: 'CHESTER',
		},
		{
			label: 'SPRING GARDEN',
			value: 'SPRING GARDEN',
		},
		{
			label: 'TOBIN',
			value: 'TOBIN',
		},
		{
			label: 'CHILCOOT',
			value: 'CHILCOOT',
		},
		{
			label: 'TAYLORSVILLE',
			value: 'TAYLORSVILLE',
		},
		{
			label: 'WARNER VALLEY',
			value: 'WARNER VALLEY',
		},
		{
			label: 'CANYON DAM',
			value: 'CANYON DAM',
		},
		{
			label: 'BECKWOURTH',
			value: 'BECKWOURTH',
		},
		{
			label: 'CRESCENT MILLS',
			value: 'CRESCENT MILLS',
		},
		{
			label: 'TWAIN',
			value: 'TWAIN',
		},
		{
			label: 'CROMBERG',
			value: 'CROMBERG',
		},
		{
			label: 'EAST SHORE',
			value: 'EAST SHORE',
		},
		{
			label: 'QUINCY',
			value: 'QUINCY',
		},
		{
			label: 'PORTOLA',
			value: 'PORTOLA',
		},
		{
			label: 'KEDDIE',
			value: 'KEDDIE',
		},
		{
			label: 'LAKE DAVIS',
			value: 'LAKE DAVIS',
		},
		{
			label: 'JOHNSVILLE',
			value: 'JOHNSVILLE',
		},
		{
			label: 'GENESEE',
			value: 'GENESEE',
		},
		{
			label: 'LA PORTE',
			value: 'LA PORTE',
		},
		{
			label: 'CLIO',
			value: 'CLIO',
		},
		{
			label: 'STORRIE',
			value: 'STORRIE',
		},
		{
			label: 'CLIPPER MILLS',
			value: 'CLIPPER MILLS',
		},
		{
			label: 'DELLEKER',
			value: 'DELLEKER',
		},
		{
			label: 'SENECA',
			value: 'SENECA',
		},
		{
			label: 'BUCKS LAKE',
			value: 'BUCKS LAKE',
		},
		{
			label: 'BELDEN',
			value: 'BELDEN',
		},
		{
			label: 'LITTLE GRASS VALLEY',
			value: 'LITTLE GRASS VALLEY',
		},
		{
			label: 'WESTWOOD',
			value: 'WESTWOOD',
		},
		{
			label: 'MEADOW VALLEY',
			value: 'MEADOW VALLEY',
		},
	],
	RIVERSIDE: [
		{
			label: 'TEMECULA',
			value: 'TEMECULA',
		},
		{
			label: 'BLYTHE CA 92225',
			value: 'BLYTHE CA 92225',
		},
		{
			label: 'REDLANDS',
			value: 'REDLANDS',
		},
		{
			label: 'MOUNTAIN CENTER',
			value: 'MOUNTAIN CENTER',
		},
		{
			label: 'HOME GARDENS',
			value: 'HOME GARDENS',
		},
		{
			label: 'LAKE ELSINORE',
			value: 'LAKE ELSINORE',
		},
		{
			label: 'CHINO',
			value: 'CHINO',
		},
		{
			label: 'TWENTYNINE PALMS',
			value: 'TWENTYNINE PALMS',
		},
		{
			label: 'CORONA',
			value: 'CORONA',
		},
		{
			label: 'HIGHGROVE',
			value: 'HIGHGROVE',
		},
		{
			label: 'INDIAN WELLS',
			value: 'INDIAN WELLS',
		},
		{
			label: 'MURRIETA',
			value: 'MURRIETA',
		},
		{
			label: 'BEAUMONT',
			value: 'BEAUMONT',
		},
		{
			label: 'IDYLLWILD-PINE COVE',
			value: 'IDYLLWILD-PINE COVE',
		},
		{
			label: 'MENIFEE',
			value: 'MENIFEE',
		},
		{
			label: 'IDYLLWILD',
			value: 'IDYLLWILD',
		},
		{
			label: 'YUCAIPA',
			value: 'YUCAIPA',
		},
		{
			label: 'LA QUINTA CA 92253',
			value: 'LA QUINTA CA 92253',
		},
		{
			label: 'SAN JACINTO',
			value: 'SAN JACINTO',
		},
		{
			label: 'MECCA',
			value: 'MECCA',
		},
		{
			label: 'DESERT PALMS',
			value: 'DESERT PALMS',
		},
		{
			label: 'JURUPA VALLEY',
			value: 'JURUPA VALLEY',
		},
		{
			label: 'CANYON LAKE',
			value: 'CANYON LAKE',
		},
		{
			label: 'IDYLLWILD CA 92549',
			value: 'IDYLLWILD CA 92549',
		},
		{
			label: 'MECCA CA 92254',
			value: 'MECCA CA 92254',
		},
		{
			label: 'CORONA DEL MAR',
			value: 'CORONA DEL MAR',
		},
		{
			label: 'QUAIL VALLEY',
			value: 'QUAIL VALLEY',
		},
		{
			label: 'MEADOWBROOK',
			value: 'MEADOWBROOK',
		},
		{
			label: 'BERMUDA DUNES',
			value: 'BERMUDA DUNES',
		},
		{
			label: 'RANCHO MIRAGE',
			value: 'RANCHO MIRAGE',
		},
		{
			label: 'HEMET CA 92545',
			value: 'HEMET CA 92545',
		},
		{
			label: 'PERRIS VALLEY',
			value: 'PERRIS VALLEY',
		},
		{
			label: 'VALLE VISTA',
			value: 'VALLE VISTA',
		},
		{
			label: 'TEMECULA CA 92590',
			value: 'TEMECULA CA 92590',
		},
		{
			label: 'LAKEVIEW',
			value: 'LAKEVIEW',
		},
		{
			label: 'MIRA LOMA',
			value: 'MIRA LOMA',
		},
		{
			label: 'MORENO VALLEY',
			value: 'MORENO VALLEY',
		},
		{
			label: 'HEMET CA 92544',
			value: 'HEMET CA 92544',
		},
		{
			label: 'PERRIS',
			value: 'PERRIS',
		},
		{
			label: 'CHERRY VALLEY',
			value: 'CHERRY VALLEY',
		},
		{
			label: 'EASTVALE',
			value: 'EASTVALE',
		},
		{
			label: 'ROMOLAND',
			value: 'ROMOLAND',
		},
		{
			label: 'RIPLEY',
			value: 'RIPLEY',
		},
		{
			label: 'SAGE',
			value: 'SAGE',
		},
		{
			label: 'RIVERSIDE CA 92503',
			value: 'RIVERSIDE CA 92503',
		},
		{
			label: 'LAKE RIVERSIDE',
			value: 'LAKE RIVERSIDE',
		},
		{
			label: 'BANNING',
			value: 'BANNING',
		},
		{
			label: 'CALIMESA',
			value: 'CALIMESA',
		},
		{
			label: 'INDIO',
			value: 'INDIO',
		},
		{
			label: 'CABAZON',
			value: 'CABAZON',
		},
		{
			label: 'WINCHESTER',
			value: 'WINCHESTER',
		},
		{
			label: 'ANZA',
			value: 'ANZA',
		},
		{
			label: 'AGUANGA',
			value: 'AGUANGA',
		},
		{
			label: 'RIVERSIDE',
			value: 'RIVERSIDE',
		},
		{
			label: 'DESERT HOT SPRINGS',
			value: 'DESERT HOT SPRINGS',
		},
		{
			label: 'PERRIS CA 92570',
			value: 'PERRIS CA 92570',
		},
		{
			label: 'WHITEWATER',
			value: 'WHITEWATER',
		},
		{
			label: 'PALM SPRINGS',
			value: 'PALM SPRINGS',
		},
		{
			label: 'LAKE MATHEWS',
			value: 'LAKE MATHEWS',
		},
		{
			label: 'TEMECULA CA 92592',
			value: 'TEMECULA CA 92592',
		},
		{
			label: 'PALM DESERT',
			value: 'PALM DESERT',
		},
		{
			label: 'COLTON',
			value: 'COLTON',
		},
		{
			label: 'DESERT CENTER',
			value: 'DESERT CENTER',
		},
		{
			label: 'NORTH PALM SPRINGS',
			value: 'NORTH PALM SPRINGS',
		},
		{
			label: 'ANZA CA 92539',
			value: 'ANZA CA 92539',
		},
		{
			label: 'NORCO',
			value: 'NORCO',
		},
		{
			label: 'LAKELAND VILLAGE',
			value: 'LAKELAND VILLAGE',
		},
		{
			label: 'MURRIETA CA 92562',
			value: 'MURRIETA CA 92562',
		},
		{
			label: 'COACHELLA',
			value: 'COACHELLA',
		},
		{
			label: 'MARCH AIR RESERVE BASE',
			value: 'MARCH AIR RESERVE BASE',
		},
		{
			label: 'THERMAL',
			value: 'THERMAL',
		},
		{
			label: 'FRENCH VALLEY',
			value: 'FRENCH VALLEY',
		},
		{
			label: 'NUEVO',
			value: 'NUEVO',
		},
		{
			label: 'HEMET',
			value: 'HEMET',
		},
		{
			label: 'LA QUINTA',
			value: 'LA QUINTA',
		},
		{
			label: 'FALLBROOK',
			value: 'FALLBROOK',
		},
		{
			label: 'BLYTHE',
			value: 'BLYTHE',
		},
		{
			label: 'ALAMO',
			value: 'ALAMO',
		},
		{
			label: 'CATHEDRAL CITY',
			value: 'CATHEDRAL CITY',
		},
		{
			label: 'SUN CITY',
			value: 'SUN CITY',
		},
		{
			label: 'NORTH SHORE',
			value: 'NORTH SHORE',
		},
		{
			label: 'MEAD VALLEY',
			value: 'MEAD VALLEY',
		},
		{
			label: 'TEMESCAL VALLEY',
			value: 'TEMESCAL VALLEY',
		},
		{
			label: 'WILDOMAR',
			value: 'WILDOMAR',
		},
		{
			label: 'HOMELAND',
			value: 'HOMELAND',
		},
		{
			label: 'WARM SPRINGS',
			value: 'WARM SPRINGS',
		},
		{
			label: 'THOUSAND PALMS',
			value: 'THOUSAND PALMS',
		},
		{
			label: 'DSRT HOT SPGS',
			value: 'DSRT HOT SPGS',
		},
	],
	SACRAMENTO: [
		{
			label: 'GALT',
			value: 'GALT',
		},
		{
			label: 'ELK GROVE',
			value: 'ELK GROVE',
		},
		{
			label: 'HERALD',
			value: 'HERALD',
		},
		{
			label: 'ORANGEVALE',
			value: 'ORANGEVALE',
		},
		{
			label: 'RIO VISTA',
			value: 'RIO VISTA',
		},
		{
			label: 'GOLD RIVER',
			value: 'GOLD RIVER',
		},
		{
			label: 'RYDE',
			value: 'RYDE',
		},
		{
			label: 'HOOD',
			value: 'HOOD',
		},
		{
			label: 'MCCLELLAN',
			value: 'MCCLELLAN',
		},
		{
			label: 'COURTLAND',
			value: 'COURTLAND',
		},
		{
			label: 'ANTELOPE',
			value: 'ANTELOPE',
		},
		{
			label: 'CITRUS HEIGHTS',
			value: 'CITRUS HEIGHTS',
		},
		{
			label: 'RANCHO MURIETA',
			value: 'RANCHO MURIETA',
		},
		{
			label: 'RANCHO CORDOVA',
			value: 'RANCHO CORDOVA',
		},
		{
			label: 'FOLSOM',
			value: 'FOLSOM',
		},
		{
			label: 'ROSEVILLE',
			value: 'ROSEVILLE',
		},
		{
			label: 'WILTON',
			value: 'WILTON',
		},
		{
			label: 'MATHER',
			value: 'MATHER',
		},
		{
			label: 'CARMICHAEL',
			value: 'CARMICHAEL',
		},
		{
			label: 'WALNUT GROVE',
			value: 'WALNUT GROVE',
		},
		{
			label: 'ELVERTA',
			value: 'ELVERTA',
		},
		{
			label: 'FAIR OAKS',
			value: 'FAIR OAKS',
		},
		{
			label: 'RIO LINDA',
			value: 'RIO LINDA',
		},
		{
			label: 'ISLETON',
			value: 'ISLETON',
		},
		{
			label: 'SLOUGHHOUSE',
			value: 'SLOUGHHOUSE',
		},
		{
			label: 'SACRAMENTO',
			value: 'SACRAMENTO',
		},
		{
			label: 'NORTH HIGHLANDS',
			value: 'NORTH HIGHLANDS',
		},
	],
	SANTA_BARBARA: [
		{
			label: 'BALLARD',
			value: 'BALLARD',
		},
		{
			label: 'NEW CUYAMA',
			value: 'NEW CUYAMA',
		},
		{
			label: 'SOLVANG',
			value: 'SOLVANG',
		},
		{
			label: 'CARPINTERIA',
			value: 'CARPINTERIA',
		},
		{
			label: 'SUMMERLAND',
			value: 'SUMMERLAND',
		},
		{
			label: 'GAVIOTA',
			value: 'GAVIOTA',
		},
		{
			label: 'GOLETA',
			value: 'GOLETA',
		},
		{
			label: 'SANTA BARBARA',
			value: 'SANTA BARBARA',
		},
		{
			label: 'LOS ALAMOS',
			value: 'LOS ALAMOS',
		},
		{
			label: 'SANTA MARIA',
			value: 'SANTA MARIA',
		},
		{
			label: 'SANTA YNEZ',
			value: 'SANTA YNEZ',
		},
		{
			label: 'CUYAMA',
			value: 'CUYAMA',
		},
		{
			label: 'BUELLTON',
			value: 'BUELLTON',
		},
		{
			label: 'GUADALUPE',
			value: 'GUADALUPE',
		},
		{
			label: 'ORCUTT',
			value: 'ORCUTT',
		},
		{
			label: 'MARICOPA',
			value: 'MARICOPA',
		},
		{
			label: 'LOS OLIVOS',
			value: 'LOS OLIVOS',
		},
		{
			label: 'MONTECITO',
			value: 'MONTECITO',
		},
		{
			label: 'LOMPOC',
			value: 'LOMPOC',
		},
		{
			label: 'CASMALIA',
			value: 'CASMALIA',
		},
	],
	SANTA_CLARA: [
		{
			label: 'LIVERMORE',
			value: 'LIVERMORE',
		},
		{
			label: 'MOUNTAIN VIEW',
			value: 'MOUNTAIN VIEW',
		},
		{
			label: 'MOUNT HAMILTON',
			value: 'MOUNT HAMILTON',
		},
		{
			label: 'PORTOLA VALLEY',
			value: 'PORTOLA VALLEY',
		},
		{
			label: 'LOS GATOS',
			value: 'LOS GATOS',
		},
		{
			label: 'CUPERTINO',
			value: 'CUPERTINO',
		},
		{
			label: 'SUNNYVALE',
			value: 'SUNNYVALE',
		},
		{
			label: 'ALVISO',
			value: 'ALVISO',
		},
		{
			label: 'MENLO PARK',
			value: 'MENLO PARK',
		},
		{
			label: 'MORGAN HILL',
			value: 'MORGAN HILL',
		},
		{
			label: 'COYOTE',
			value: 'COYOTE',
		},
		{
			label: 'PALO ALTO',
			value: 'PALO ALTO',
		},
		{
			label: 'SAN JOSE',
			value: 'SAN JOSE',
		},
		{
			label: 'SAN MARTIN',
			value: 'SAN MARTIN',
		},
		{
			label: 'LOS ALTOS',
			value: 'LOS ALTOS',
		},
		{
			label: 'LOS ALTOS HILLS',
			value: 'LOS ALTOS HILLS',
		},
		{
			label: 'STANFORD',
			value: 'STANFORD',
		},
		{
			label: 'MILPITAS',
			value: 'MILPITAS',
		},
		{
			label: 'REDWOOD ESTATES',
			value: 'REDWOOD ESTATES',
		},
		{
			label: 'WATSONVILLE',
			value: 'WATSONVILLE',
		},
		{
			label: 'LA HONDA',
			value: 'LA HONDA',
		},
		{
			label: 'GILROY',
			value: 'GILROY',
		},
		{
			label: 'HOLY CITY',
			value: 'HOLY CITY',
		},
		{
			label: 'SARATOGA',
			value: 'SARATOGA',
		},
		{
			label: 'SANTA CLARA',
			value: 'SANTA CLARA',
		},
		{
			label: 'HOLLISTER',
			value: 'HOLLISTER',
		},
		{
			label: 'MONTE SERENO',
			value: 'MONTE SERENO',
		},
		{
			label: 'CAMPBELL',
			value: 'CAMPBELL',
		},
	],
	SANTA_CRUZ: [
		{
			label: 'INTERLAKEN',
			value: 'INTERLAKEN',
		},
		{
			label: 'CAPITOLA',
			value: 'CAPITOLA',
		},
		{
			label: 'MOUNT HERMON',
			value: 'MOUNT HERMON',
		},
		{
			label: 'MT MADONNA',
			value: 'MT MADONNA',
		},
		{
			label: 'BONNY DOON',
			value: 'BONNY DOON',
		},
		{
			label: 'CORRALITOS',
			value: 'CORRALITOS',
		},
		{
			label: 'LIVE OAK',
			value: 'LIVE OAK',
		},
		{
			label: 'DAVENPORT',
			value: 'DAVENPORT',
		},
		{
			label: 'SOQUEL',
			value: 'SOQUEL',
		},
		{
			label: 'ROYAL OAKS',
			value: 'ROYAL OAKS',
		},
		{
			label: 'BROOKDALE',
			value: 'BROOKDALE',
		},
		{
			label: 'PESCADERO',
			value: 'PESCADERO',
		},
		{
			label: 'WATSONVILLE',
			value: 'WATSONVILLE',
		},
		{
			label: 'BOULDER CREEK',
			value: 'BOULDER CREEK',
		},
		{
			label: 'FELTON',
			value: 'FELTON',
		},
		{
			label: 'LOS GATOS',
			value: 'LOS GATOS',
		},
		{
			label: 'LA SELVA BEACH',
			value: 'LA SELVA BEACH',
		},
		{
			label: 'SCOTTS VALLEY',
			value: 'SCOTTS VALLEY',
		},
		{
			label: 'SANTA CRUZ',
			value: 'SANTA CRUZ',
		},
		{
			label: 'APTOS',
			value: 'APTOS',
		},
		{
			label: 'PAJARO DUNES',
			value: 'PAJARO DUNES',
		},
		{
			label: 'FREEDOM',
			value: 'FREEDOM',
		},
		{
			label: 'RIO DEL MAR',
			value: 'RIO DEL MAR',
		},
		{
			label: 'BEN LOMOND',
			value: 'BEN LOMOND',
		},
		{
			label: 'LOMPICO',
			value: 'LOMPICO',
		},
	],
	SAN_BENITO: [
		{
			label: 'COALINGA',
			value: 'COALINGA',
		},
		{
			label: 'KING CITY',
			value: 'KING CITY',
		},
		{
			label: 'TRES PINOS',
			value: 'TRES PINOS',
		},
		{
			label: 'HOLLISTER',
			value: 'HOLLISTER',
		},
		{
			label: 'PAICINES',
			value: 'PAICINES',
		},
		{
			label: 'SAN JUAN BAUTISTA',
			value: 'SAN JUAN BAUTISTA',
		},
		{
			label: 'AROMAS',
			value: 'AROMAS',
		},
	],
	SAN_BERNARDINO: [
		{
			label: 'RIMROCK',
			value: 'RIMROCK',
		},
		{
			label: 'REDLANDS',
			value: 'REDLANDS',
		},
		{
			label: 'LUDLOW',
			value: 'LUDLOW',
		},
		{
			label: 'SPRING VALLEY LAKE',
			value: 'SPRING VALLEY LAKE',
		},
		{
			label: 'DAGGETT',
			value: 'DAGGETT',
		},
		{
			label: 'VIDAL',
			value: 'VIDAL',
		},
		{
			label: 'SUGARLOAF',
			value: 'SUGARLOAF',
		},
		{
			label: 'BRYN MAWR',
			value: 'BRYN MAWR',
		},
		{
			label: 'AMBOY',
			value: 'AMBOY',
		},
		{
			label: 'CHINO',
			value: 'CHINO',
		},
		{
			label: 'TWENTYNINE PALMS',
			value: 'TWENTYNINE PALMS',
		},
		{
			label: 'ARROWBEAR LK',
			value: 'ARROWBEAR LK',
		},
		{
			label: 'RIALTO',
			value: 'RIALTO',
		},
		{
			label: 'HAVASU LAKE',
			value: 'HAVASU LAKE',
		},
		{
			label: 'HESPERIA',
			value: 'HESPERIA',
		},
		{
			label: 'YUCCA VALLEY',
			value: 'YUCCA VALLEY',
		},
		{
			label: 'UPLAND',
			value: 'UPLAND',
		},
		{
			label: 'HIGHLAND',
			value: 'HIGHLAND',
		},
		{
			label: 'RIDGECREST',
			value: 'RIDGECREST',
		},
		{
			label: 'POMONA',
			value: 'POMONA',
		},
		{
			label: 'YUCAIPA',
			value: 'YUCAIPA',
		},
		{
			label: 'KELSO',
			value: 'KELSO',
		},
		{
			label: 'CRESTMORE',
			value: 'CRESTMORE',
		},
		{
			label: 'PIñON HILLS',
			value: 'PIñON HILLS',
		},
		{
			label: 'PARKER DAM',
			value: 'PARKER DAM',
		},
		{
			label: 'GOFFS',
			value: 'GOFFS',
		},
		{
			label: 'OAK GLEN',
			value: 'OAK GLEN',
		},
		{
			label: 'LAKE ARROWHEAD',
			value: 'LAKE ARROWHEAD',
		},
		{
			label: 'ONTARIO',
			value: 'ONTARIO',
		},
		{
			label: 'JURUPA VALLEY',
			value: 'JURUPA VALLEY',
		},
		{
			label: 'VALLEY OF ENCHANTMENT',
			value: 'VALLEY OF ENCHANTMENT',
		},
		{
			label: 'ORO GRANDE',
			value: 'ORO GRANDE',
		},
		{
			label: 'DEVORE HEIGHTS',
			value: 'DEVORE HEIGHTS',
		},
		{
			label: 'RANCHO CUCAMONGA',
			value: 'RANCHO CUCAMONGA',
		},
		{
			label: 'SAN BERNARDINO',
			value: 'SAN BERNARDINO',
		},
		{
			label: 'BARSTOW',
			value: 'BARSTOW',
		},
		{
			label: 'ESSEX',
			value: 'ESSEX',
		},
		{
			label: 'TRONA',
			value: 'TRONA',
		},
		{
			label: 'CEDAR GLEN',
			value: 'CEDAR GLEN',
		},
		{
			label: 'LOMA LINDA',
			value: 'LOMA LINDA',
		},
		{
			label: 'ANGELUS OAKS',
			value: 'ANGELUS OAKS',
		},
		{
			label: 'CADIZ',
			value: 'CADIZ',
		},
		{
			label: 'EL MIRAGE',
			value: 'EL MIRAGE',
		},
		{
			label: 'RED MOUNTAIN',
			value: 'RED MOUNTAIN',
		},
		{
			label: 'HINKLEY',
			value: 'HINKLEY',
		},
		{
			label: 'VALLEY OF THE MOON',
			value: 'VALLEY OF THE MOON',
		},
		{
			label: 'BORON',
			value: 'BORON',
		},
		{
			label: 'CEDARPINES PARK',
			value: 'CEDARPINES PARK',
		},
		{
			label: 'PHELAN',
			value: 'PHELAN',
		},
		{
			label: 'EASTVALE',
			value: 'EASTVALE',
		},
		{
			label: 'JOHNSON VALLEY',
			value: 'JOHNSON VALLEY',
		},
		{
			label: 'APPLE VALLEY',
			value: 'APPLE VALLEY',
		},
		{
			label: 'HOMESTEAD VALLEY',
			value: 'HOMESTEAD VALLEY',
		},
		{
			label: 'FORT IRWIN',
			value: 'FORT IRWIN',
		},
		{
			label: 'ALTA LOMA',
			value: 'ALTA LOMA',
		},
		{
			label: 'BIG RIVER',
			value: 'BIG RIVER',
		},
		{
			label: 'MONTCLAIR',
			value: 'MONTCLAIR',
		},
		{
			label: 'BARSTOW-VICTORVILLE',
			value: 'BARSTOW-VICTORVILLE',
		},
		{
			label: 'CHINO HILLS',
			value: 'CHINO HILLS',
		},
		{
			label: 'MOUNTAIN PASS',
			value: 'MOUNTAIN PASS',
		},
		{
			label: 'EARP',
			value: 'EARP',
		},
		{
			label: 'FONTANA',
			value: 'FONTANA',
		},
		{
			label: 'SKYFOREST',
			value: 'SKYFOREST',
		},
		{
			label: 'BLUEWATER',
			value: 'BLUEWATER',
		},
		{
			label: 'BIG BEAR LAKE',
			value: 'BIG BEAR LAKE',
		},
		{
			label: 'RIVERSIDE',
			value: 'RIVERSIDE',
		},
		{
			label: 'HELENDALE',
			value: 'HELENDALE',
		},
		{
			label: 'YERMO',
			value: 'YERMO',
		},
		{
			label: 'MORONGO VALLEY',
			value: 'MORONGO VALLEY',
		},
		{
			label: 'BIG BEAR CITY',
			value: 'BIG BEAR CITY',
		},
		{
			label: 'CRESTLINE',
			value: 'CRESTLINE',
		},
		{
			label: 'ETIWANDA',
			value: 'ETIWANDA',
		},
		{
			label: 'JOSHUA TREE',
			value: 'JOSHUA TREE',
		},
		{
			label: 'BAKER',
			value: 'BAKER',
		},
		{
			label: 'COLTON',
			value: 'COLTON',
		},
		{
			label: 'FOREST FALLS',
			value: 'FOREST FALLS',
		},
		{
			label: 'FAWNSKIN',
			value: 'FAWNSKIN',
		},
		{
			label: 'CIMA',
			value: 'CIMA',
		},
		{
			label: 'ADELANTO',
			value: 'ADELANTO',
		},
		{
			label: 'RIMFOREST',
			value: 'RIMFOREST',
		},
		{
			label: 'ARROWHEAD FARMS',
			value: 'ARROWHEAD FARMS',
		},
		{
			label: 'BLOOMINGTON',
			value: 'BLOOMINGTON',
		},
		{
			label: 'BLUE JAY',
			value: 'BLUE JAY',
		},
		{
			label: 'MENTONE',
			value: 'MENTONE',
		},
		{
			label: 'WRIGHTWOOD',
			value: 'WRIGHTWOOD',
		},
		{
			label: 'MUSCOY',
			value: 'MUSCOY',
		},
		{
			label: 'VICTORVILLE',
			value: 'VICTORVILLE',
		},
		{
			label: 'PIONEERTOWN',
			value: 'PIONEERTOWN',
		},
		{
			label: 'GRAND TERRACE',
			value: 'GRAND TERRACE',
		},
		{
			label: 'LUCERNE VALLEY',
			value: 'LUCERNE VALLEY',
		},
		{
			label: 'BLYTHE',
			value: 'BLYTHE',
		},
		{
			label: 'LYTLE CREEK',
			value: 'LYTLE CREEK',
		},
		{
			label: 'ARROWBEAR LAKE',
			value: 'ARROWBEAR LAKE',
		},
		{
			label: 'CUCAMONGA',
			value: 'CUCAMONGA',
		},
		{
			label: 'MT BALDY',
			value: 'MT BALDY',
		},
		{
			label: 'RUNNING SPRINGS',
			value: 'RUNNING SPRINGS',
		},
		{
			label: 'GREEN VALLEY LAKE',
			value: 'GREEN VALLEY LAKE',
		},
		{
			label: 'NEWBERRY SPRINGS',
			value: 'NEWBERRY SPRINGS',
		},
		{
			label: 'HODGE',
			value: 'HODGE',
		},
		{
			label: 'PINON HILLS',
			value: 'PINON HILLS',
		},
		{
			label: 'LANDERS',
			value: 'LANDERS',
		},
		{
			label: 'OAK HILLS',
			value: 'OAK HILLS',
		},
		{
			label: 'NEEDLES',
			value: 'NEEDLES',
		},
		{
			label: 'NIPTON',
			value: 'NIPTON',
		},
		{
			label: 'TWIN PEAKS',
			value: 'TWIN PEAKS',
		},
	],
	SAN_DIEGO: [
		{
			label: 'SANTA YSABEL',
			value: 'SANTA YSABEL',
		},
		{
			label: 'HIDDEN MEADOWS',
			value: 'HIDDEN MEADOWS',
		},
		{
			label: 'IMPERIAL BEACH',
			value: 'IMPERIAL BEACH',
		},
		{
			label: 'PINE VALLEY',
			value: 'PINE VALLEY',
		},
		{
			label: 'VISTA',
			value: 'VISTA',
		},
		{
			label: 'DESCANSO',
			value: 'DESCANSO',
		},
		{
			label: 'DULZURA',
			value: 'DULZURA',
		},
		{
			label: 'SAN YSIDRO',
			value: 'SAN YSIDRO',
		},
		{
			label: 'POTRERO',
			value: 'POTRERO',
		},
		{
			label: 'BORREGO SPRINGS',
			value: 'BORREGO SPRINGS',
		},
		{
			label: 'OCEANSIDE-ESCONDIDO',
			value: 'OCEANSIDE-ESCONDIDO',
		},
		{
			label: 'RCHO SANTA FE',
			value: 'RCHO SANTA FE',
		},
		{
			label: 'RAMONA',
			value: 'RAMONA',
		},
		{
			label: 'DEL MAR',
			value: 'DEL MAR',
		},
		{
			label: 'CARDIFF BY THE SEA',
			value: 'CARDIFF BY THE SEA',
		},
		{
			label: 'LAKESIDE',
			value: 'LAKESIDE',
		},
		{
			label: 'ALPINE',
			value: 'ALPINE',
		},
		{
			label: 'HARBISON CANYON',
			value: 'HARBISON CANYON',
		},
		{
			label: 'RANCHO SANTA FE',
			value: 'RANCHO SANTA FE',
		},
		{
			label: 'MOUNT LAGUNA',
			value: 'MOUNT LAGUNA',
		},
		{
			label: 'BOULEVARD',
			value: 'BOULEVARD',
		},
		{
			label: 'ESCONDIDO',
			value: 'ESCONDIDO',
		},
		{
			label: 'LA MESA',
			value: 'LA MESA',
		},
		{
			label: 'SAN DIEGO',
			value: 'SAN DIEGO',
		},
		{
			label: 'BONITA',
			value: 'BONITA',
		},
		{
			label: 'SOLANA BEACH',
			value: 'SOLANA BEACH',
		},
		{
			label: 'CARDIFF',
			value: 'CARDIFF',
		},
		{
			label: 'SANTEE',
			value: 'SANTEE',
		},
		{
			label: 'PAUMA VALLEY',
			value: 'PAUMA VALLEY',
		},
		{
			label: 'ENCINITAS',
			value: 'ENCINITAS',
		},
		{
			label: 'EL CAJON',
			value: 'EL CAJON',
		},
		{
			label: 'SAN MARCOS',
			value: 'SAN MARCOS',
		},
		{
			label: 'JULIAN',
			value: 'JULIAN',
		},
		{
			label: 'MOUNTAIN EMPIRE',
			value: 'MOUNTAIN EMPIRE',
		},
		{
			label: 'BONSALL',
			value: 'BONSALL',
		},
		{
			label: 'JACUMBA',
			value: 'JACUMBA',
		},
		{
			label: 'CARLSBAD',
			value: 'CARLSBAD',
		},
		{
			label: 'LEMON GROVE',
			value: 'LEMON GROVE',
		},
		{
			label: 'TECATE',
			value: 'TECATE',
		},
		{
			label: 'CORONADO',
			value: 'CORONADO',
		},
		{
			label: 'FALLBROOK',
			value: 'FALLBROOK',
		},
		{
			label: 'OCEANSIDE',
			value: 'OCEANSIDE',
		},
		{
			label: 'VALLEY CENTER',
			value: 'VALLEY CENTER',
		},
		{
			label: 'SPRING VALLEY',
			value: 'SPRING VALLEY',
		},
		{
			label: 'POWAY',
			value: 'POWAY',
		},
		{
			label: 'PALOMAR MOUNTAIN',
			value: 'PALOMAR MOUNTAIN',
		},
		{
			label: 'GUATAY',
			value: 'GUATAY',
		},
		{
			label: 'RANCHITA',
			value: 'RANCHITA',
		},
		{
			label: 'JAMUL',
			value: 'JAMUL',
		},
		{
			label: 'WARNER SPRINGS',
			value: 'WARNER SPRINGS',
		},
		{
			label: 'PALA',
			value: 'PALA',
		},
		{
			label: 'CAMPO',
			value: 'CAMPO',
		},
		{
			label: 'NATIONAL CITY',
			value: 'NATIONAL CITY',
		},
		{
			label: 'LA JOLLA',
			value: 'LA JOLLA',
		},
		{
			label: 'CHULA VISTA',
			value: 'CHULA VISTA',
		},
	],
	SAN_FRANCISCO: [
		{
			label: 'SAN FRANCISCO',
			value: 'SAN FRANCISCO',
		},
		{
			label: 'DALY CITY',
			value: 'DALY CITY',
		},
	],
	SAN_JOAQUIN: [
		{
			label: 'GALT',
			value: 'GALT',
		},
		{
			label: 'VERNALIS',
			value: 'VERNALIS',
		},
		{
			label: 'MANTECA',
			value: 'MANTECA',
		},
		{
			label: 'LODI',
			value: 'LODI',
		},
		{
			label: 'COUNTRY CLUB',
			value: 'COUNTRY CLUB',
		},
		{
			label: 'RIPON',
			value: 'RIPON',
		},
		{
			label: 'THORNTON',
			value: 'THORNTON',
		},
		{
			label: 'RIVERBANK',
			value: 'RIVERBANK',
		},
		{
			label: 'LOCKEFORD',
			value: 'LOCKEFORD',
		},
		{
			label: 'LINCOLN VILLAGE',
			value: 'LINCOLN VILLAGE',
		},
		{
			label: 'ESCALON',
			value: 'ESCALON',
		},
		{
			label: 'BANTA',
			value: 'BANTA',
		},
		{
			label: 'LATHROP',
			value: 'LATHROP',
		},
		{
			label: 'WOODBRIDGE',
			value: 'WOODBRIDGE',
		},
		{
			label: 'TRACY',
			value: 'TRACY',
		},
		{
			label: 'WALNUT GROVE',
			value: 'WALNUT GROVE',
		},
		{
			label: 'OAKDALE',
			value: 'OAKDALE',
		},
		{
			label: 'STOCKTON',
			value: 'STOCKTON',
		},
		{
			label: 'MOUNTAIN HOUSE',
			value: 'MOUNTAIN HOUSE',
		},
		{
			label: 'FRENCH CAMP',
			value: 'FRENCH CAMP',
		},
		{
			label: 'HOLT',
			value: 'HOLT',
		},
		{
			label: 'ACAMPO',
			value: 'ACAMPO',
		},
		{
			label: 'CLEMENTS',
			value: 'CLEMENTS',
		},
		{
			label: 'FARMINGTON',
			value: 'FARMINGTON',
		},
		{
			label: 'VICTOR',
			value: 'VICTOR',
		},
		{
			label: 'LINDEN',
			value: 'LINDEN',
		},
		{
			label: 'AUGUST',
			value: 'AUGUST',
		},
	],
	SAN_LUIS_OBISPO: [
		{
			label: 'SAN LUIS OBISPO',
			value: 'SAN LUIS OBISPO',
		},
		{
			label: 'SHANDON',
			value: 'SHANDON',
		},
		{
			label: 'ADELAIDE',
			value: 'ADELAIDE',
		},
		{
			label: 'TEMPLETON',
			value: 'TEMPLETON',
		},
		{
			label: 'PISMO BEACH',
			value: 'PISMO BEACH',
		},
		{
			label: 'LOS OSOS',
			value: 'LOS OSOS',
		},
		{
			label: 'RAGGED POINT',
			value: 'RAGGED POINT',
		},
		{
			label: 'GROVER BEACH',
			value: 'GROVER BEACH',
		},
		{
			label: 'SAN SIMEON',
			value: 'SAN SIMEON',
		},
		{
			label: 'AVILA BEACH',
			value: 'AVILA BEACH',
		},
		{
			label: 'SHELL BEACH',
			value: 'SHELL BEACH',
		},
		{
			label: 'SANTA MARGAR',
			value: 'SANTA MARGAR',
		},
		{
			label: 'CAYUCOS',
			value: 'CAYUCOS',
		},
		{
			label: 'SAN MIGUEL',
			value: 'SAN MIGUEL',
		},
		{
			label: 'BRADLEY',
			value: 'BRADLEY',
		},
		{
			label: 'NIPOMO',
			value: 'NIPOMO',
		},
		{
			label: 'LAKE NACIMIENTO',
			value: 'LAKE NACIMIENTO',
		},
		{
			label: 'HARMONY',
			value: 'HARMONY',
		},
		{
			label: 'SANTA MARGARITA',
			value: 'SANTA MARGARITA',
		},
		{
			label: 'ARROYO GRANDE',
			value: 'ARROYO GRANDE',
		},
		{
			label: 'WOODLANDS',
			value: 'WOODLANDS',
		},
		{
			label: 'CRESTON',
			value: 'CRESTON',
		},
		{
			label: 'MORRO BAY',
			value: 'MORRO BAY',
		},
		{
			label: 'OAK SHORES',
			value: 'OAK SHORES',
		},
		{
			label: 'OCEANO',
			value: 'OCEANO',
		},
		{
			label: 'RANCHO SUEY',
			value: 'RANCHO SUEY',
		},
		{
			label: 'SANTA MARIA',
			value: 'SANTA MARIA',
		},
		{
			label: 'PASO ROBLES',
			value: 'PASO ROBLES',
		},
		{
			label: 'ATASCADERO',
			value: 'ATASCADERO',
		},
		{
			label: 'CAMBRIA',
			value: 'CAMBRIA',
		},
	],
	SAN_MATEO: [
		{
			label: 'BROADMOOR VLG',
			value: 'BROADMOOR VLG',
		},
		{
			label: 'EMERALD HILLS',
			value: 'EMERALD HILLS',
		},
		{
			label: 'HALF MOON BAY',
			value: 'HALF MOON BAY',
		},
		{
			label: 'ATHERTON',
			value: 'ATHERTON',
		},
		{
			label: 'PORTOLA VALLEY',
			value: 'PORTOLA VALLEY',
		},
		{
			label: 'BRISBANE',
			value: 'BRISBANE',
		},
		{
			label: 'SAN CARLOS',
			value: 'SAN CARLOS',
		},
		{
			label: 'LOMA MAR',
			value: 'LOMA MAR',
		},
		{
			label: 'SAN FRANCISCO',
			value: 'SAN FRANCISCO',
		},
		{
			label: 'MENLO PARK',
			value: 'MENLO PARK',
		},
		{
			label: 'BURLINGAME',
			value: 'BURLINGAME',
		},
		{
			label: 'WOODSIDE',
			value: 'WOODSIDE',
		},
		{
			label: 'BELMONT',
			value: 'BELMONT',
		},
		{
			label: 'FOSTER CITY',
			value: 'FOSTER CITY',
		},
		{
			label: 'MILLBRAE',
			value: 'MILLBRAE',
		},
		{
			label: 'MONTARA',
			value: 'MONTARA',
		},
		{
			label: 'DALY CITY',
			value: 'DALY CITY',
		},
		{
			label: 'SOUTH COASTSIDE',
			value: 'SOUTH COASTSIDE',
		},
		{
			label: 'REDWOOD CITY',
			value: 'REDWOOD CITY',
		},
		{
			label: 'SAN BRUNO',
			value: 'SAN BRUNO',
		},
		{
			label: 'PESCADERO',
			value: 'PESCADERO',
		},
		{
			label: 'LA HONDA',
			value: 'LA HONDA',
		},
		{
			label: 'HILLSBOROUGH',
			value: 'HILLSBOROUGH',
		},
		{
			label: 'PALOMAR PARK',
			value: 'PALOMAR PARK',
		},
		{
			label: 'SAN MATEO',
			value: 'SAN MATEO',
		},
		{
			label: 'EL GRANADA',
			value: 'EL GRANADA',
		},
		{
			label: 'EAST PALO ALTO',
			value: 'EAST PALO ALTO',
		},
		{
			label: 'SAN GREGORIO',
			value: 'SAN GREGORIO',
		},
		{
			label: 'SOUTH SAN FRANCISCO',
			value: 'SOUTH SAN FRANCISCO',
		},
		{
			label: 'COLMA',
			value: 'COLMA',
		},
		{
			label: 'MOSS BEACH',
			value: 'MOSS BEACH',
		},
		{
			label: 'PACIFICA',
			value: 'PACIFICA',
		},
	],
	SHASTA: [
		{
			label: 'LAKEHEAD',
			value: 'LAKEHEAD',
		},
		{
			label: 'WHITMORE',
			value: 'WHITMORE',
		},
		{
			label: 'MCARTHUR',
			value: 'MCARTHUR',
		},
		{
			label: 'INDEPENDENCE',
			value: 'INDEPENDENCE',
		},
		{
			label: 'BURNEY',
			value: 'BURNEY',
		},
		{
			label: 'DUNSMUIR',
			value: 'DUNSMUIR',
		},
		{
			label: 'SHASTA',
			value: 'SHASTA',
		},
		{
			label: 'BEAR VALLEY',
			value: 'BEAR VALLEY',
		},
		{
			label: 'CASTELLA',
			value: 'CASTELLA',
		},
		{
			label: 'MARKLEEVILLE',
			value: 'MARKLEEVILLE',
		},
		{
			label: 'LITTLE LAKE',
			value: 'LITTLE LAKE',
		},
		{
			label: 'BELLA VISTA',
			value: 'BELLA VISTA',
		},
		{
			label: 'HOMEWOOD CANYON',
			value: 'HOMEWOOD CANYON',
		},
		{
			label: 'BISHOP',
			value: 'BISHOP',
		},
		{
			label: 'MESA VISTA',
			value: 'MESA VISTA',
		},
		{
			label: 'JONES VALLEY',
			value: 'JONES VALLEY',
		},
		{
			label: 'PEARSONVILLE',
			value: 'PEARSONVILLE',
		},
		{
			label: 'CASSEL',
			value: 'CASSEL',
		},
		{
			label: 'SHINGLETOWN',
			value: 'SHINGLETOWN',
		},
		{
			label: 'MONTGOMERY CREEK',
			value: 'MONTGOMERY CREEK',
		},
		{
			label: 'SHASTA LAKE',
			value: 'SHASTA LAKE',
		},
		{
			label: 'SOUTHWEST SHASTA',
			value: 'SOUTHWEST SHASTA',
		},
		{
			label: 'OLD STATION',
			value: 'OLD STATION',
		},
		{
			label: 'TECOPA',
			value: 'TECOPA',
		},
		{
			label: 'DARWIN',
			value: 'DARWIN',
		},
		{
			label: 'OAK RUN',
			value: 'OAK RUN',
		},
		{
			label: 'BIG PINE',
			value: 'BIG PINE',
		},
		{
			label: 'DEATH VALLEY',
			value: 'DEATH VALLEY',
		},
		{
			label: 'HAT CREEK',
			value: 'HAT CREEK',
		},
		{
			label: 'MILLVILLE',
			value: 'MILLVILLE',
		},
		{
			label: 'ONO',
			value: 'ONO',
		},
		{
			label: 'LONE PINE',
			value: 'LONE PINE',
		},
		{
			label: 'WILKERSON',
			value: 'WILKERSON',
		},
		{
			label: 'ALPINE VILLAGE',
			value: 'ALPINE VILLAGE',
		},
		{
			label: 'ARNOLD',
			value: 'ARNOLD',
		},
		{
			label: 'CARTAGO',
			value: 'CARTAGO',
		},
		{
			label: 'BIG BEND',
			value: 'BIG BEND',
		},
		{
			label: 'SHOSHONE',
			value: 'SHOSHONE',
		},
		{
			label: 'ANDERSON',
			value: 'ANDERSON',
		},
		{
			label: 'PLATINA',
			value: 'PLATINA',
		},
		{
			label: 'FALL RIVER MILLS',
			value: 'FALL RIVER MILLS',
		},
		{
			label: 'COTTONWOOD',
			value: 'COTTONWOOD',
		},
		{
			label: 'ALABAMA HILLS',
			value: 'ALABAMA HILLS',
		},
		{
			label: 'FRENCH GULCH',
			value: 'FRENCH GULCH',
		},
		{
			label: 'IGO',
			value: 'IGO',
		},
		{
			label: 'REDDING',
			value: 'REDDING',
		},
		{
			label: 'KIRKWOOD',
			value: 'KIRKWOOD',
		},
		{
			label: 'PALO CEDRO',
			value: 'PALO CEDRO',
		},
		{
			label: 'FURNACE CREEK',
			value: 'FURNACE CREEK',
		},
		{
			label: 'KEELER',
			value: 'KEELER',
		},
		{
			label: 'TRONA',
			value: 'TRONA',
		},
		{
			label: 'ROUND MOUNTAIN',
			value: 'ROUND MOUNTAIN',
		},
		{
			label: 'OLANCHA',
			value: 'OLANCHA',
		},
		{
			label: 'MANTON',
			value: 'MANTON',
		},
		{
			label: 'CHARLESTON VIEW',
			value: 'CHARLESTON VIEW',
		},
	],
	SIERRA: [
		{
			label: 'CAMPTONVILLE',
			value: 'CAMPTONVILLE',
		},
		{
			label: 'ALLEGHANY',
			value: 'ALLEGHANY',
		},
		{
			label: 'SIERRAVILLE',
			value: 'SIERRAVILLE',
		},
		{
			label: 'CHILCOOT',
			value: 'CHILCOOT',
		},
		{
			label: 'CALPINE',
			value: 'CALPINE',
		},
		{
			label: 'VERDI',
			value: 'VERDI',
		},
		{
			label: 'DOWNIEVILLE',
			value: 'DOWNIEVILLE',
		},
		{
			label: 'SIERRA CITY',
			value: 'SIERRA CITY',
		},
		{
			label: 'SATTLEY',
			value: 'SATTLEY',
		},
		{
			label: 'GOODYEARS BAR',
			value: 'GOODYEARS BAR',
		},
		{
			label: 'LOYALTON',
			value: 'LOYALTON',
		},
		{
			label: 'NORTH SAN JUAN',
			value: 'NORTH SAN JUAN',
		},
	],
	SISKIYOU: [
		{
			label: 'KLAMATH RIVER',
			value: 'KLAMATH RIVER',
		},
		{
			label: 'HORSE CREEK',
			value: 'HORSE CREEK',
		},
		{
			label: 'MOUNT HEBRON',
			value: 'MOUNT HEBRON',
		},
		{
			label: 'CALLAHAN',
			value: 'CALLAHAN',
		},
		{
			label: 'DUNSMUIR',
			value: 'DUNSMUIR',
		},
		{
			label: 'ORLEANS',
			value: 'ORLEANS',
		},
		{
			label: 'SOMES BAR',
			value: 'SOMES BAR',
		},
		{
			label: 'GREENVIEW',
			value: 'GREENVIEW',
		},
		{
			label: 'FORT JONES',
			value: 'FORT JONES',
		},
		{
			label: 'SCOTT BAR',
			value: 'SCOTT BAR',
		},
		{
			label: 'GRENADA',
			value: 'GRENADA',
		},
		{
			label: 'HAPPY CAMP',
			value: 'HAPPY CAMP',
		},
		{
			label: 'EDGEWOOD',
			value: 'EDGEWOOD',
		},
		{
			label: 'SAWYERS BAR',
			value: 'SAWYERS BAR',
		},
		{
			label: 'TULELAKE',
			value: 'TULELAKE',
		},
		{
			label: 'GAZELLE',
			value: 'GAZELLE',
		},
		{
			label: 'YREKA',
			value: 'YREKA',
		},
		{
			label: 'MCCLOUD',
			value: 'MCCLOUD',
		},
		{
			label: 'ETNA',
			value: 'ETNA',
		},
		{
			label: 'MONTAGUE',
			value: 'MONTAGUE',
		},
		{
			label: 'DORRIS',
			value: 'DORRIS',
		},
		{
			label: 'MACDOEL',
			value: 'MACDOEL',
		},
		{
			label: 'HORNBROOK',
			value: 'HORNBROOK',
		},
		{
			label: 'SEIAD VALLEY',
			value: 'SEIAD VALLEY',
		},
		{
			label: 'WEED',
			value: 'WEED',
		},
		{
			label: 'MOUNT SHASTA',
			value: 'MOUNT SHASTA',
		},
		{
			label: 'FORKS OF SALMON',
			value: 'FORKS OF SALMON',
		},
	],
	SOLANO: [
		{
			label: 'DIXON',
			value: 'DIXON',
		},
		{
			label: 'VALLEJO',
			value: 'VALLEJO',
		},
		{
			label: 'VACAVILLE',
			value: 'VACAVILLE',
		},
		{
			label: 'BIRDS LNDG',
			value: 'BIRDS LNDG',
		},
		{
			label: 'AMERICAN CANYON',
			value: 'AMERICAN CANYON',
		},
		{
			label: 'ALLENDALE',
			value: 'ALLENDALE',
		},
		{
			label: 'RIO VISTA',
			value: 'RIO VISTA',
		},
		{
			label: 'GREEN VALLEY',
			value: 'GREEN VALLEY',
		},
		{
			label: 'BIRDS LANDING',
			value: 'BIRDS LANDING',
		},
		{
			label: 'SUISUN CITY',
			value: 'SUISUN CITY',
		},
		{
			label: 'WALNUT GROVE',
			value: 'WALNUT GROVE',
		},
		{
			label: 'WINTERS',
			value: 'WINTERS',
		},
		{
			label: 'FAIRFIELD',
			value: 'FAIRFIELD',
		},
		{
			label: 'HARTLEY',
			value: 'HARTLEY',
		},
		{
			label: 'TRAVIS AFB',
			value: 'TRAVIS AFB',
		},
		{
			label: 'ELMIRA',
			value: 'ELMIRA',
		},
		{
			label: 'DAVIS',
			value: 'DAVIS',
		},
		{
			label: 'BENICIA',
			value: 'BENICIA',
		},
	],
	SONOMA: [
		{
			label: 'FULTON',
			value: 'FULTON',
		},
		{
			label: 'EL VERANO',
			value: 'EL VERANO',
		},
		{
			label: 'ROHNERT PARK',
			value: 'ROHNERT PARK',
		},
		{
			label: 'CAMP MEEKER',
			value: 'CAMP MEEKER',
		},
		{
			label: 'FORESTVILLE',
			value: 'FORESTVILLE',
		},
		{
			label: 'DUNCANS MILLS',
			value: 'DUNCANS MILLS',
		},
		{
			label: 'CAZADERO',
			value: 'CAZADERO',
		},
		{
			label: 'LARKFIELD-WIKIUP',
			value: 'LARKFIELD-WIKIUP',
		},
		{
			label: 'OCCIDENTAL',
			value: 'OCCIDENTAL',
		},
		{
			label: 'SEBASTOPOL',
			value: 'SEBASTOPOL',
		},
		{
			label: 'ANNAPOLIS',
			value: 'ANNAPOLIS',
		},
		{
			label: 'RIO NIDO',
			value: 'RIO NIDO',
		},
		{
			label: 'BODEGA BAY',
			value: 'BODEGA BAY',
		},
		{
			label: 'RUSSIAN RIVER-COASTAL',
			value: 'RUSSIAN RIVER-COASTAL',
		},
		{
			label: 'SANTA ROSA',
			value: 'SANTA ROSA',
		},
		{
			label: 'COTATI',
			value: 'COTATI',
		},
		{
			label: 'BOYES HOT SPRINGS',
			value: 'BOYES HOT SPRINGS',
		},
		{
			label: 'GEYSERVILLE',
			value: 'GEYSERVILLE',
		},
		{
			label: 'WINDSOR',
			value: 'WINDSOR',
		},
		{
			label: 'CLOVERDALE',
			value: 'CLOVERDALE',
		},
		{
			label: 'FREESTONE',
			value: 'FREESTONE',
		},
		{
			label: 'BODEGA',
			value: 'BODEGA',
		},
		{
			label: 'SEA RANCH',
			value: 'SEA RANCH',
		},
		{
			label: 'VALLEY FORD',
			value: 'VALLEY FORD',
		},
		{
			label: 'GRATON',
			value: 'GRATON',
		},
		{
			label: 'PENNGROVE',
			value: 'PENNGROVE',
		},
		{
			label: 'GUERNEVILLE',
			value: 'GUERNEVILLE',
		},
		{
			label: 'CALISTOGA',
			value: 'CALISTOGA',
		},
		{
			label: 'AGUA CALIENTE',
			value: 'AGUA CALIENTE',
		},
		{
			label: 'SERENO DEL MAR',
			value: 'SERENO DEL MAR',
		},
		{
			label: 'SONOMA',
			value: 'SONOMA',
		},
		{
			label: 'KENWOOD',
			value: 'KENWOOD',
		},
		{
			label: 'GLEN ELLEN',
			value: 'GLEN ELLEN',
		},
		{
			label: 'JENNER',
			value: 'JENNER',
		},
		{
			label: 'HEALDSBURG',
			value: 'HEALDSBURG',
		},
		{
			label: 'MONTE RIO',
			value: 'MONTE RIO',
		},
		{
			label: 'GUALALA',
			value: 'GUALALA',
		},
		{
			label: 'THE SEA RANCH',
			value: 'THE SEA RANCH',
		},
		{
			label: 'PETALUMA',
			value: 'PETALUMA',
		},
	],
	STANISLAUS: [
		{
			label: 'TRACY',
			value: 'TRACY',
		},
		{
			label: 'VALLEY HOME',
			value: 'VALLEY HOME',
		},
		{
			label: 'CERES',
			value: 'CERES',
		},
		{
			label: 'OAKDALE',
			value: 'OAKDALE',
		},
		{
			label: 'GRAYSON',
			value: 'GRAYSON',
		},
		{
			label: 'HILMAR',
			value: 'HILMAR',
		},
		{
			label: 'WESTLEY',
			value: 'WESTLEY',
		},
		{
			label: 'KEYES',
			value: 'KEYES',
		},
		{
			label: 'NEWMAN',
			value: 'NEWMAN',
		},
		{
			label: 'LA GRANGE',
			value: 'LA GRANGE',
		},
		{
			label: 'LINDEN',
			value: 'LINDEN',
		},
		{
			label: 'FARMINGTON',
			value: 'FARMINGTON',
		},
		{
			label: 'EMPIRE',
			value: 'EMPIRE',
		},
		{
			label: 'TURLOCK',
			value: 'TURLOCK',
		},
		{
			label: 'DENAIR',
			value: 'DENAIR',
		},
		{
			label: 'KNIGHTS FERRY',
			value: 'KNIGHTS FERRY',
		},
		{
			label: 'HUGHSON',
			value: 'HUGHSON',
		},
		{
			label: 'VERNALIS',
			value: 'VERNALIS',
		},
		{
			label: 'WATERFORD',
			value: 'WATERFORD',
		},
		{
			label: 'PATTERSON',
			value: 'PATTERSON',
		},
		{
			label: 'LIVERMORE',
			value: 'LIVERMORE',
		},
		{
			label: 'MODESTO',
			value: 'MODESTO',
		},
		{
			label: 'RIVERBANK',
			value: 'RIVERBANK',
		},
		{
			label: 'GUSTINE',
			value: 'GUSTINE',
		},
		{
			label: 'HICKMAN',
			value: 'HICKMAN',
		},
		{
			label: 'SALIDA',
			value: 'SALIDA',
		},
		{
			label: 'CROWS LANDING',
			value: 'CROWS LANDING',
		},
	],
	SUTTER: [
		{
			label: 'WHEATLAND',
			value: 'WHEATLAND',
		},
		{
			label: 'EAST NICOLAUS',
			value: 'EAST NICOLAUS',
		},
		{
			label: 'RIO OSO',
			value: 'RIO OSO',
		},
		{
			label: 'LIVE OAK',
			value: 'LIVE OAK',
		},
		{
			label: 'SUTTER',
			value: 'SUTTER',
		},
		{
			label: 'NICOLAUS',
			value: 'NICOLAUS',
		},
		{
			label: 'YUBA CITY',
			value: 'YUBA CITY',
		},
		{
			label: 'ELVERTA',
			value: 'ELVERTA',
		},
		{
			label: 'PLEASANT GROVE',
			value: 'PLEASANT GROVE',
		},
		{
			label: 'ROBBINS',
			value: 'ROBBINS',
		},
		{
			label: 'GRIDLEY',
			value: 'GRIDLEY',
		},
		{
			label: 'KNIGHTS LANDING',
			value: 'KNIGHTS LANDING',
		},
		{
			label: 'SACRAMENTO',
			value: 'SACRAMENTO',
		},
		{
			label: 'TROWBRIDGE',
			value: 'TROWBRIDGE',
		},
		{
			label: 'MERIDIAN',
			value: 'MERIDIAN',
		},
	],
	TEHAMA: [
		{
			label: 'CORNING',
			value: 'CORNING',
		},
		{
			label: 'ANDERSON',
			value: 'ANDERSON',
		},
		{
			label: 'PLATINA',
			value: 'PLATINA',
		},
		{
			label: 'COTTONWOOD',
			value: 'COTTONWOOD',
		},
		{
			label: 'TEHAMA',
			value: 'TEHAMA',
		},
		{
			label: 'ORLAND',
			value: 'ORLAND',
		},
		{
			label: 'MILL CREEK',
			value: 'MILL CREEK',
		},
		{
			label: 'GERBER',
			value: 'GERBER',
		},
		{
			label: 'VINA',
			value: 'VINA',
		},
		{
			label: 'MANTON',
			value: 'MANTON',
		},
		{
			label: 'FLOURNOY',
			value: 'FLOURNOY',
		},
		{
			label: 'PROBERTA',
			value: 'PROBERTA',
		},
		{
			label: 'PAYNES CREEK',
			value: 'PAYNES CREEK',
		},
		{
			label: 'LOS MOLINOS',
			value: 'LOS MOLINOS',
		},
		{
			label: 'RED BLUFF',
			value: 'RED BLUFF',
		},
		{
			label: 'LAKE CALIFORNIA',
			value: 'LAKE CALIFORNIA',
		},
		{
			label: 'MINERAL',
			value: 'MINERAL',
		},
		{
			label: 'PASKENTA',
			value: 'PASKENTA',
		},
		{
			label: 'CHICO',
			value: 'CHICO',
		},
	],
	TRINITY: [
		{
			label: 'SALYER',
			value: 'SALYER',
		},
		{
			label: 'BURNT RANCH',
			value: 'BURNT RANCH',
		},
		{
			label: 'MAD RIVER',
			value: 'MAD RIVER',
		},
		{
			label: 'LEWISTON',
			value: 'LEWISTON',
		},
		{
			label: 'HELENA',
			value: 'HELENA',
		},
		{
			label: 'WEAVERVILLE',
			value: 'WEAVERVILLE',
		},
		{
			label: 'HAYFORK',
			value: 'HAYFORK',
		},
		{
			label: 'JUNCTION CITY',
			value: 'JUNCTION CITY',
		},
		{
			label: 'HYAMPOM',
			value: 'HYAMPOM',
		},
		{
			label: 'TRINITY CENTER',
			value: 'TRINITY CENTER',
		},
		{
			label: 'DOUGLAS CITY',
			value: 'DOUGLAS CITY',
		},
		{
			label: 'ZENIA',
			value: 'ZENIA',
		},
		{
			label: 'WILDWOOD',
			value: 'WILDWOOD',
		},
		{
			label: 'RUTH',
			value: 'RUTH',
		},
		{
			label: 'BIG BAR',
			value: 'BIG BAR',
		},
	],
	TULARE: [
		{
			label: 'ORANGE COVE',
			value: 'ORANGE COVE',
		},
		{
			label: 'TIPTON',
			value: 'TIPTON',
		},
		{
			label: 'SEQUOIA CREST',
			value: 'SEQUOIA CREST',
		},
		{
			label: 'POSO PARK',
			value: 'POSO PARK',
		},
		{
			label: 'CEDAR SLOPE',
			value: 'CEDAR SLOPE',
		},
		{
			label: 'WILSONIA',
			value: 'WILSONIA',
		},
		{
			label: 'SPRINGVILLE',
			value: 'SPRINGVILLE',
		},
		{
			label: 'CUTLER',
			value: 'CUTLER',
		},
		{
			label: 'DELANO',
			value: 'DELANO',
		},
		{
			label: 'HANFORD',
			value: 'HANFORD',
		},
		{
			label: 'LINDSAY',
			value: 'LINDSAY',
		},
		{
			label: 'HARTLAND',
			value: 'HARTLAND',
		},
		{
			label: 'IVANHOE',
			value: 'IVANHOE',
		},
		{
			label: 'TERRA BELLA',
			value: 'TERRA BELLA',
		},
		{
			label: 'SUGARLOAF SAW MILL',
			value: 'SUGARLOAF SAW MILL',
		},
		{
			label: 'CALIF HOT SPG',
			value: 'CALIF HOT SPG',
		},
		{
			label: 'SULTANA',
			value: 'SULTANA',
		},
		{
			label: 'KAWEAH',
			value: 'KAWEAH',
		},
		{
			label: 'CALIFORNIA HOT SPRINGS',
			value: 'CALIFORNIA HOT SPRINGS',
		},
		{
			label: 'POSEY',
			value: 'POSEY',
		},
		{
			label: 'OROSI',
			value: 'OROSI',
		},
		{
			label: 'PONDEROSA',
			value: 'PONDEROSA',
		},
		{
			label: 'KINGS CANYON',
			value: 'KINGS CANYON',
		},
		{
			label: 'RICHGROVE',
			value: 'RICHGROVE',
		},
		{
			label: 'IDLEWILD',
			value: 'IDLEWILD',
		},
		{
			label: 'SEQUOIA NATIONAL PARK',
			value: 'SEQUOIA NATIONAL PARK',
		},
		{
			label: 'DUCOR',
			value: 'DUCOR',
		},
		{
			label: 'CAMP NELSON',
			value: 'CAMP NELSON',
		},
		{
			label: 'JOVISTA',
			value: 'JOVISTA',
		},
		{
			label: 'TONYVILLE',
			value: 'TONYVILLE',
		},
		{
			label: 'PIXLEY',
			value: 'PIXLEY',
		},
		{
			label: 'ALPAUGH',
			value: 'ALPAUGH',
		},
		{
			label: 'WOODVILLE',
			value: 'WOODVILLE',
		},
		{
			label: 'WAUKENA',
			value: 'WAUKENA',
		},
		{
			label: 'PANORAMA HEIGHTS',
			value: 'PANORAMA HEIGHTS',
		},
		{
			label: 'MIRAMONTE',
			value: 'MIRAMONTE',
		},
		{
			label: 'TULARE',
			value: 'TULARE',
		},
		{
			label: 'DINUBA',
			value: 'DINUBA',
		},
		{
			label: 'GOSHEN',
			value: 'GOSHEN',
		},
		{
			label: 'PINE FLAT',
			value: 'PINE FLAT',
		},
		{
			label: 'KERNVILLE',
			value: 'KERNVILLE',
		},
		{
			label: 'YETTEM',
			value: 'YETTEM',
		},
		{
			label: 'CORCORAN',
			value: 'CORCORAN',
		},
		{
			label: 'LONDON',
			value: 'LONDON',
		},
		{
			label: 'WOODLAKE',
			value: 'WOODLAKE',
		},
		{
			label: 'VISALIA',
			value: 'VISALIA',
		},
		{
			label: 'EARLIMART',
			value: 'EARLIMART',
		},
		{
			label: 'ALLENSWORTH',
			value: 'ALLENSWORTH',
		},
		{
			label: 'STRATHMORE',
			value: 'STRATHMORE',
		},
		{
			label: 'EXETER',
			value: 'EXETER',
		},
		{
			label: 'FARMERSVILLE',
			value: 'FARMERSVILLE',
		},
		{
			label: 'THREE RIVERS',
			value: 'THREE RIVERS',
		},
		{
			label: 'SUGARLOAF VILLAGE',
			value: 'SUGARLOAF VILLAGE',
		},
		{
			label: 'KINGSBURG',
			value: 'KINGSBURG',
		},
		{
			label: 'REEDLEY',
			value: 'REEDLEY',
		},
		{
			label: 'TRAVER',
			value: 'TRAVER',
		},
		{
			label: 'INYOKERN',
			value: 'INYOKERN',
		},
		{
			label: 'POPLAR',
			value: 'POPLAR',
		},
		{
			label: 'SILVER CITY',
			value: 'SILVER CITY',
		},
		{
			label: 'KINGS CANYON NATIONAL PK',
			value: 'KINGS CANYON NATIONAL PK',
		},
		{
			label: 'KENNEDY MEADOWS',
			value: 'KENNEDY MEADOWS',
		},
		{
			label: 'SEVILLE',
			value: 'SEVILLE',
		},
		{
			label: 'PORTERVILLE',
			value: 'PORTERVILLE',
		},
		{
			label: 'LEMON COVE',
			value: 'LEMON COVE',
		},
		{
			label: 'BADGER',
			value: 'BADGER',
		},
	],
	TUOLUMNE: [
		{
			label: 'MI WUK VILLAGE',
			value: 'MI WUK VILLAGE',
		},
		{
			label: 'ARNOLD',
			value: 'ARNOLD',
		},
		{
			label: 'EAST SONORA',
			value: 'EAST SONORA',
		},
		{
			label: 'COLUMBIA',
			value: 'COLUMBIA',
		},
		{
			label: 'STRAWBERRY',
			value: 'STRAWBERRY',
		},
		{
			label: 'PINECREST',
			value: 'PINECREST',
		},
		{
			label: 'SIERRA VILLAGE',
			value: 'SIERRA VILLAGE',
		},
		{
			label: 'GROVELAND',
			value: 'GROVELAND',
		},
		{
			label: 'LONG BARN',
			value: 'LONG BARN',
		},
		{
			label: 'TWAIN HARTE',
			value: 'TWAIN HARTE',
		},
		{
			label: 'MOCCASIN',
			value: 'MOCCASIN',
		},
		{
			label: 'CHINESE CAMP',
			value: 'CHINESE CAMP',
		},
		{
			label: 'SONORA',
			value: 'SONORA',
		},
		{
			label: 'SOULSBYVILLE',
			value: 'SOULSBYVILLE',
		},
		{
			label: 'PHOENIX LAKE',
			value: 'PHOENIX LAKE',
		},
		{
			label: 'JAMESTOWN',
			value: 'JAMESTOWN',
		},
		{
			label: 'TUOLUMNE CITY',
			value: 'TUOLUMNE CITY',
		},
		{
			label: 'COLD SPRINGS',
			value: 'COLD SPRINGS',
		},
		{
			label: 'COULTERVILLE',
			value: 'COULTERVILLE',
		},
		{
			label: 'TUOLUMNE',
			value: 'TUOLUMNE',
		},
		{
			label: 'LA GRANGE',
			value: 'LA GRANGE',
		},
		{
			label: 'BIG OAK FLAT',
			value: 'BIG OAK FLAT',
		},
	],
	VENTURA: [
		{
			label: 'NEWBURY PARK',
			value: 'NEWBURY PARK',
		},
		{
			label: 'SANTA ROSA VALLEY',
			value: 'SANTA ROSA VALLEY',
		},
		{
			label: 'LAKE SHERWOOD',
			value: 'LAKE SHERWOOD',
		},
		{
			label: 'OAK VIEW',
			value: 'OAK VIEW',
		},
		{
			label: 'PIRU',
			value: 'PIRU',
		},
		{
			label: 'POINT MUGU NAWC',
			value: 'POINT MUGU NAWC',
		},
		{
			label: 'CANOGA PARK',
			value: 'CANOGA PARK',
		},
		{
			label: 'WESTLAKE',
			value: 'WESTLAKE',
		},
		{
			label: 'OXNARD',
			value: 'OXNARD',
		},
		{
			label: 'MEINERS OAKS',
			value: 'MEINERS OAKS',
		},
		{
			label: 'SANTA PAULA',
			value: 'SANTA PAULA',
		},
		{
			label: 'OJAI',
			value: 'OJAI',
		},
		{
			label: 'CAMARILLO',
			value: 'CAMARILLO',
		},
		{
			label: 'FRAZIER PARK',
			value: 'FRAZIER PARK',
		},
		{
			label: 'FILLMORE',
			value: 'FILLMORE',
		},
		{
			label: 'MIRA MONTE',
			value: 'MIRA MONTE',
		},
		{
			label: 'MARICOPA',
			value: 'MARICOPA',
		},
		{
			label: 'SOMIS',
			value: 'SOMIS',
		},
		{
			label: 'SANTA SUSANA',
			value: 'SANTA SUSANA',
		},
		{
			label: 'OAK PARK',
			value: 'OAK PARK',
		},
		{
			label: 'CARPINTERIA',
			value: 'CARPINTERIA',
		},
		{
			label: 'VENTURA',
			value: 'VENTURA',
		},
		{
			label: 'MOORPARK',
			value: 'MOORPARK',
		},
		{
			label: 'SIMI VALLEY',
			value: 'SIMI VALLEY',
		},
		{
			label: 'BOX CANYON',
			value: 'BOX CANYON',
		},
		{
			label: 'CHATSWORTH',
			value: 'CHATSWORTH',
		},
		{
			label: 'PORT HUENEME',
			value: 'PORT HUENEME',
		},
		{
			label: 'WESTLAKE VILLAGE',
			value: 'WESTLAKE VILLAGE',
		},
		{
			label: 'BELL CANYON',
			value: 'BELL CANYON',
		},
		{
			label: 'THOUSAND OAKS',
			value: 'THOUSAND OAKS',
		},
		{
			label: 'HIDDEN VALLEY',
			value: 'HIDDEN VALLEY',
		},
		{
			label: 'MALIBU',
			value: 'MALIBU',
		},
		{
			label: 'WEST HILLS',
			value: 'WEST HILLS',
		},
	],
	YOLO: [
		{
			label: 'CLARKSBURG',
			value: 'CLARKSBURG',
		},
		{
			label: 'ESPARTO',
			value: 'ESPARTO',
		},
		{
			label: 'EAST YOLO',
			value: 'EAST YOLO',
		},
		{
			label: 'ZAMORA',
			value: 'ZAMORA',
		},
		{
			label: 'WEST SACRAMENTO',
			value: 'WEST SACRAMENTO',
		},
		{
			label: 'GUINDA',
			value: 'GUINDA',
		},
		{
			label: 'COURTLAND',
			value: 'COURTLAND',
		},
		{
			label: 'RUMSEY',
			value: 'RUMSEY',
		},
		{
			label: 'CAPAY',
			value: 'CAPAY',
		},
		{
			label: 'ARBUCKLE',
			value: 'ARBUCKLE',
		},
		{
			label: 'WINTERS',
			value: 'WINTERS',
		},
		{
			label: 'BROOKS',
			value: 'BROOKS',
		},
		{
			label: 'MADISON',
			value: 'MADISON',
		},
		{
			label: 'DAVIS',
			value: 'DAVIS',
		},
		{
			label: 'KNIGHTS LANDING',
			value: 'KNIGHTS LANDING',
		},
		{
			label: 'EL MACERO',
			value: 'EL MACERO',
		},
		{
			label: 'TANCRED',
			value: 'TANCRED',
		},
		{
			label: 'YOLO',
			value: 'YOLO',
		},
		{
			label: 'DUNNIGAN',
			value: 'DUNNIGAN',
		},
		{
			label: 'WOODLAND',
			value: 'WOODLAND',
		},
		{
			label: 'DIXON',
			value: 'DIXON',
		},
	],
	YUBA: [
		{
			label: 'WHEATLAND',
			value: 'WHEATLAND',
		},
		{
			label: 'BROWNS VALLEY',
			value: 'BROWNS VALLEY',
		},
		{
			label: 'HALLWOOD',
			value: 'HALLWOOD',
		},
		{
			label: 'BANGOR',
			value: 'BANGOR',
		},
		{
			label: 'STRAWBERRY VALLEY',
			value: 'STRAWBERRY VALLEY',
		},
		{
			label: 'SMARTSVILLE',
			value: 'SMARTSVILLE',
		},
		{
			label: 'OLIVEHURST',
			value: 'OLIVEHURST',
		},
		{
			label: 'PLUMAS LAKE',
			value: 'PLUMAS LAKE',
		},
		{
			label: 'WEST LINDA',
			value: 'WEST LINDA',
		},
		{
			label: 'DOBBINS',
			value: 'DOBBINS',
		},
		{
			label: 'CHALLENGE',
			value: 'CHALLENGE',
		},
		{
			label: 'CLIPPER MILLS',
			value: 'CLIPPER MILLS',
		},
		{
			label: 'FORBESTOWN',
			value: 'FORBESTOWN',
		},
		{
			label: 'OREGON HOUSE',
			value: 'OREGON HOUSE',
		},
		{
			label: 'LINDA',
			value: 'LINDA',
		},
		{
			label: 'NORTH SAN JUAN',
			value: 'NORTH SAN JUAN',
		},
		{
			label: 'BEALE AFB',
			value: 'BEALE AFB',
		},
		{
			label: 'RACKERBY',
			value: 'RACKERBY',
		},
		{
			label: 'CAMPTONVILLE',
			value: 'CAMPTONVILLE',
		},
		{
			label: 'LOMA RICA',
			value: 'LOMA RICA',
		},
		{
			label: 'OROVILLE',
			value: 'OROVILLE',
		},
		{
			label: 'ARBOGA',
			value: 'ARBOGA',
		},
		{
			label: 'BROWNSVILLE',
			value: 'BROWNSVILLE',
		},
		{
			label: 'MARYSVILLE',
			value: 'MARYSVILLE',
		},
		{
			label: 'N SAN JUAN',
			value: 'N SAN JUAN',
		},
	],
};

const specialties = ['', 'Buyer Agent', 'Listing Agent', 'Relocation', 'Short Sale', 'Foreclosure', 'Consulting', 'Other'];
const languages = [
	'',
	'English',
	'Spanish',
	'French',
	'German',
	'Mandarin',
	'Cantonese',
	'Arabic',
	'Hindi',
	'Polish',
	'Italian',
	'Thai',
	'Korean',
	'Greek',
];

const headerImages = [
	{
		img: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/headerImage1.jpg',
		title: 'Header 1',
	},
	{
		img: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/headerImage2.jpg',
		title: 'Header 2',
	},
	{
		img: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/headerImage3.jpg',
		title: 'Header 3',
	},
	{
		img: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/headerImage4.jpg',
		title: 'Header 4',
	},
	{
		img: 'https://bpooperationsmain.s3.eu-west-2.amazonaws.com/template_images/headerImage5.jpg',
		title: 'Header 5',
	},
];

const ratingOptions = [
	{ label: 'Local Knowledge', value: 'local_knowledge' },
	{ label: 'Process Expertise', value: 'process_expertise' },
	{ label: 'Responsiveness', value: 'responsiveness' },
	{ label: 'Negotiation Skills', value: 'negotiation_skills' },
];

const mapPolygonStyles = { fillColor: '#FFAA01', strokeColor: '#1378A5' };
const exteriorList = ['Street View', 'Front House', 'Left Side', 'Right Side', 'Backyard', 'Other Exterior photos'];
const interiorList = ['Kitchen', 'Bathroom(s)', 'Floorings', 'Other Interior photos'];

const additionalFields = [
	{
		label: 'Home Style',
		amount: 'home_style_value',
		feedback: 'home_style_feedback',
		tooltip: 'This indicates if the this style of home is up with the current desirable Market Trends for the area',
	},
	{
		label: 'Feng Shui',
		amount: 'feng_shui_value',
		feedback: 'feng_shui_feedback',
		tooltip: 'This indicates if the floor plan or flow of the home is desirable',
	},
	{
		label: 'Proximity to Amenities or Neutral',
		amount: 'proximity_to_amenities_value',
		feedback: 'proximity_to_amenities_feedback',
		tooltip: 'Property Near Parks, Shopping, Walking Trails, Dinning, Cul De Sac',
	},
	{
		label: 'Proximity Neighborhood Negatives & Nuisances or Neutral',
		amount: 'proximity_neighborhood_negative_value',
		feedback: 'proximity_neighborhood_negative_feedback',
		tooltip: 'Freeways, Busy Streets, Far Away from shopping & Dining',
	},
];
const property_condition_options = ['Poor', 'Average', 'Above Average', 'Excellent Condition'];

const investorTypes = [
	{
		label: 'Flips',
		name: 'flips',
	},
	{
		label: 'Development',
		name: 'development',
	},
	{
		label: 'Home additions',
		name: 'home-additions',
	},
	{
		label: 'Rentals',
		name: 'rentals',
	},
];

const mainWithOptions = [
	{
		name: 'Electrical',
		options: [
			'EV chargers',
			'Electrical wiring',
			'Emergency electrical services',
			'Landscape lighting',
			'Electrical repairs',
			'Rewiring',
			'Surge protector',
			'Ceiling fan installation',
			'Chandelier installation',
			'Custom lighting',
			'Electrical Construction',
			'Generator installation',
			'Outlet and switch repair',
			'Electrical inspections',
			'Remodels',
			'Safety inspections',
			'Appliance circuits',
			'Electrical upgrades',
			'Home automation',
			'Recessed lighting',
			'Smoke detectors',
			'Aluminum wiring',
			'Electrical maintenance',
			'Electrical panels',
		],
	},
	{
		name: 'Plumbing',
		options: [
			'Drain cleaning and unclogging',
			'Water heater maintenance',
			'Sewer line inspections and repairs',
			'Mold remediation',
			'Garbage disposal repairs and replacement',
			'Pipe restoration and excavation',
			'Water damage cleanup',
			'Leak repairs',
			'Sewer Line Install',
			'Piping',
			'Septics',
			'Well',
			'Wells',
			'Potable Water Systems',
			'Public Sewer Systems',
			'Buy Water Heater',
			'Water Heater Repair',
			'Water Heater Install',
			'Tankless Water Heater',
			'Tankless Water Heater Install',
			'Water Heater Replacement',
			'Tankless Water Heater Repair',
			'PVC',
			'ABS',
			'Sewer Line',
			'Copper Plumbing',
			'Galvanized Plumbing Repairs',
			'Galvanized Plumbing',
		],
	},
	{
		name: 'Roofing',
		options: [
			'Roof replacement',
			'Leak repair',
			'Roof patching',
			'Gutter installation and repair',
			'Roof inspections',
			'Flashing fabrication and installation',
			'New roof installation',
			'Moss Growth Removal',
			'Roof Install',
			'Tar & Gravel Roof',
			'Torch Down Roof',
			'Foam Roof',
			'Composition Roof',
			'Composition Asphalt Shingle Roof',
			'Asphalt Shingle Roof',
			'Gable Roof',
			'Flat Roof',
			'Gambrel Roof',
			'Mansard Roof',
			'Hip Roof',
			'Metal Roofing',
			'Slate Roof',
			'Butterfly Roof',
			'Concrete Tiles',
			'Shed Roof',
			'Dutch Gable Roof',
			'Wood Shake Roof',
			'Shake Roof',
			'Dormer',
			'Dormers',
			'Rubber Roofing',
			'Shingles',
			'Membrane Roofing',
			'Saltbox',
			'Gutter Install',
			'Sheathing Install',
			'Roof Sheathing Install',
			'Spanish Tiles Roof',
			'Spanish Roof Tiles',
			'Spanish Tiles',
			'Skylights',
			'Skylight Install',
			'Moss Growth',
			'Moss Removal',
		],
	},
	{
		name: 'Insulation',
		options: [
			'Wall insulation',
			'Spray foam',
			'Cellulose insulation',
			'Fiberglass batt insulation',
			'Glass wool',
			'Mineral wool',
			'Rigid board insulation',
			'BIBS insulation',
			'Floors',
			'Air sealing',
			'Blown In Insulation',
			'Blown Insulation',
			'Closed Cell Spray Foam: R-6 to R-7',
			'Foam Board: R-4.5 to R-5',
			'RetroFoam Injection Foam: R-4.6 to R-5',
			'Open Cell Spray Foam: R-3.6 to R-3.9',
			'Cellulose (Loose-Fill): R-3.1 to R-3.8',
			'Fiberglass (Batts): R-2.9 to R3.8',
			'Fiberglass (Loose-Fill): R-2.2 to R-2.9',
			'R-1 Insulation',
			'R-1.5',
			'R-2',
			'R-2.5',
			'R-3',
			'R-3.5',
			'R-4',
			'R-4.5',
			'R-4.6',
			'R-5.5',
			'R-6',
			'R-7',
		],
	},
	{
		name: 'Flooring',
		options: [
			'Tile',
			'Laminate flooring',
			'Carpet',
			'Hardwood flooring',
			'Vinyl Plank Flooring',
			'Engineered wood',
			'Linoleum',
			'Stone',
			'Cork',
			'Hardwood',
			'Vinyl',
			'Bamboo flooring',
			'Concrete',
			'Bamboo',
			'Cantera flooring',
			'Bathroom flooring',
			'Hybrid flooring',
			'Parquet flooring',
			'Resin flooring',
			'Carpet tiles',
			'Concrete floors',
			'Other types of flooring',
			'Flooring Install',
			'Flooring Removal',
			'Flooring Damage',
			'Flooring Repair',
		],
	},
	{
		name: 'Handyman',
		options: [
			'Plumbing',
			'Carpentry',
			'Painting',
			'Electricity',
			'Furniture assembly',
			'Pressure washing',
			'Deck repairs',
			'Gutter cleaning',
			'Smart home upgrades',
			'Tiling',
			'Fixture Replacement',
			'Repairs',
			'Fix fences',
			'Floor improvements',
			'HVAC',
			'Landscaping',
			'Art hanging',
			'Babyproofing',
			'Drywall repair',
			'Door adjustments',
			'Drywall',
			'Skylight installation',
			'Window coverings',
			'Door repair',
			'Miscellaneous Repairs',
			'Home Improvement',
		],
	},
	{
		name: 'Landscaping',
		options: [
			'Lawn mower',
			'Mulch',
			'Landscape design',
			'Weed control',
			'Fertilization',
			'Lawn aerator',
			'Pruning',
			'Mowing',
			'Pruning shrubs',
			'Snow removal',
			'Bush trimming',
			'Irrigation',
			'Dethatching',
			'Edging',
			'Planting',
			'Gutter cleaning',
			'Hedging',
			'Leaf removal',
			'Lawn returfing',
			'Trimming',
			'Lawn bed maintenance',
			'Pest control',
			'Clean up',
			'Sod Installation',
			'Grass Installation',
			'Flower Planting',
			'Plant Planting',
			'Tree Planting',
			'Tree Removal',
			'Irrigation startup or shutdown services',
		],
	},
	{
		name: 'General Contractor',
		options: [
			'Remodeling',
			'Remodel Kitchen',
			'Remodel Baths',
			'Framing',
			'Electrical',
			'Plumbing',
			'Roofing',
			'Flooring',
			'Tiles',
			'Landscaping',
			'Concrete',
			'Electrical Contactor',
			'Roofing Contractor',
			'Commercial Contractor',
			'GC',
			'General Contractor',
			'Residential Contractor',
			'New Construction',
			'Rebuild',
			'Demolition',
			'Demo',
			'Gut',
			'Insulation',
			'Foundation',
			'Structural Engineering',
		],
	},
	{
		name: 'Garage Door',
		options: ['Garage Door Repair', 'Garage Door Install', 'Garage Door', 'Buy Garage Door', 'Purchase Garage Door'],
	},

	{
		name: 'HVAC',
		options: ['Heat', 'Air', 'Duct Installation', 'Air Duct Install', 'Heating Duct Install', 'Duct Install', 'HVAC', 'Buy AC Unit', 'Buy Furnace'],
	},
	{
		name: 'Home Staging',
		options: ['Staging', 'Stage my House', 'Stager'],
	},
	{
		name: 'Painting',
		options: ['Painting', 'Painter'],
	},
	{
		name: 'Home Design',
		options: ['Homes Design', 'Homes Designer'],
	},
	{
		name: 'Fire Safety',
		options: [
			'Inspections',
			'Maintenance',
			'Fire Sprinkler System Design',
			'Fire Sprinkler',
			'Maintenance Inspections',
			'Fire Sprinkler Maintenance',
			'Fire Suppression',
			'FM 200 Inspections',
			'Kitchen Suppression',
			'Commercial Sprinklers',
			'Residential Sprinklers',
			'Residential Fire Sprinkler Inspections',
			'Commercial Fire Sprinkler Inspections',
			'Fire Sprinkler Install',
			'Fire Sprinkler Plumbing',
			'Fire Sprinkler Monitoring',
			'Fire Sprinkler System Repair',
		],
	},
	{
		name: 'House/ Home Cleaning',
		options: ['House Cleaning', 'Kitchen Cleaning', 'Bedroom Cleaning', 'Bathroom Cleaning', 'Dusting'],
	},
	{
		name: 'Kitchen Counter Suppliers',
		options: [
			'Solid Surface Countertops',
			'Butcher Block Countertops',
			'Laminate Countertops',
			'Soapstone',
			'Ceramic Tile',
			'Stainless Steel',
			'Kitchen Counters',
			'Granite Counter Tops',
			'Quartz Counter Tops',
			'Marble Counter Tops',
			'Kitchen Cabinets',
			'Custom Kitchen Cabinets',
		],
	},
	{
		name: 'Tile Suppliers',
		options: [
			'Bathroom Tile',
			'Flooring & Carpet',
			'Kitchen Flooring',
			'Material',
			'Ceramic',
			'Porcelain',
			'Stone',
			'Stores',
			'Home Depot',
			"Lowe's",
			'Walmart',
			'Square Tiles',
			'Rectangle Tiles',
			'Rectangular Tiles',
			'Hexagon Tiles',
			'Herringbone',
			'Matte',
			'Glossy',
			'Glazed',
			'Polished',
			'Large Format Tiles',
			'Standard Format Tiles',
			'Mosaic',
			'Features',
			'Outdoor Tiles',
			'Tiles With Water Protection',
			'Accent Tiles',
			'GREENGUARD Certified Tiles',
			'Pressed Tiles',
			'Straight Edge Tiles',
			'Rectified Tiles',
			'Contemporary Tiles',
			'Modern Tiles',
			'Farmhouse Tiles',
			'Classic Tiles',
			'Ivy Hill Tile',
			'Somertile',
			'MSI',
			'Daltile',
			'Marazzi Tile',
			'Emser Tile',
			'TrafficMASTER',
			'Bedrosians',
		],
	},
	{
		name: 'Window Suppliers',
		options: [
			'Awning windows',
			'Casement windows',
			'Double hung windows',
			'Single hung windows',
			'Sliding windows',
			'Picture windows',
			'Bay windows',
			'Bow windows',
			'Skylights',
			'Hopper windows',
			'Transom windows',
			'Garden windows',
			'Slider windows',
			'Fixed windows',
			'Glass block windows',
			'Jalousie windows',
			'Storm windows',
			'Egress windows',
			'Ingress windows',
			'Shaped windows',
			'Arched windows',
			'Round windows',
			'Casement',
			'Dormer window',
			'Single hung',
		],
	},
	{
		name: 'Architect',
		options: [
			'Residential Plans',
			'Commercial Plans',
			'Home Design',
			'Building Design',
			'Architectural Engineering',
			'Structural Engineering',
			'Plan Design',
			'Residential Architect',
			'Commercial Architect',
		],
	},
	{
		name: 'Mold Abatement',
		options: ['Mold Removal'],
	},
	{
		name: 'Junk Removal',
		options: ['Junk Removal', 'Junk Hauling', 'Demolition', 'Demo', 'Gut', 'Property Gutting'],
	},
	{
		name: 'Asbestos Abatement',
		options: ['Asbestos Removal'],
	},
	{
		name: 'Mortgage Brokers',
		options: ['Lending', 'Mortgage Rates', 'Loan'],
	},
	{
		name: 'Homes Insurance',
		options: ['Asbestos Removal'],
	},
];

export {
	mainWithOptions,
	sellList,
	buyList,
	analyticsList,
	professionalOptions,
	states,
	timeZones,
	URL,
	countiesAndCities,
	californiaCounties,
	statesShort,
	farmPackages,
	schedule,
	specialties,
	languages,
	headerImages,
	ratingOptions,
	mapPolygonStyles,
	additionalFields,
	exteriorList,
	interiorList,
	property_condition_options,
	investorTypes,
};
